<template>
  <main-large-dialog
    no-paddings-x
    no-paddings-y
    dialog-width="80%"
    body-height="60vh"
    :is-visible.sync="computedIsVisible"
    :title="$i18n.t('dialog.addLayers')"
    @agree="addLayersHandler"
    agree-text="dialog.agreeAdd"
    :loading="loading"
    :is-valid="isAnyLayerChecked"
  >
    <template #body>
      <main-loader v-if="dataLoading" style="height: 448px"></main-loader>
      <template v-else>
        <grey-tabs v-model="currentLayersTab" :tabs="greyTabs" :show-arrows="false" fill-height fill-width>
          <v-row justify="end" no-gutters>
            <v-col cols="3" class="pt-4 pr-2 pb-1">
              <data-input
                v-model="searchValue"
                :data-type="{ name: 'text' }"
                prepend-inner-icon="mdi-magnify"
                :placeholder="$i18n.t('default.find')"
                hide-details
                clearable
              />
            </v-col>
          </v-row>
          <v-tab-item v-for="(tab, idx) in filteredTabs" :key="tab.text" class="pt-0">
            <v-treeview
              v-if="layersSchema[tab.name].length > 0"
              :ref="`tree-${idx}`"
              v-model="layersChecked[tab.name]"
              selectable
              :items="layersSchema[tab.name]"
              item-key="key"
              return-object
              :search="searchValue"
              :open-all="!!searchValue"
              open-on-click
              selected-color="primary"
              @input="refreshCheckedLayers"
            >
              <template #prepend="{ item }">
                <v-icon v-if="!item.children" size="20" color="rgba(0, 0, 0, 0.7)">
                  {{ getDataSourceIcon(item.geometry_type || item.service_type) }}
                </v-icon>
              </template>
              <template #append="{ item }">
                <v-tooltip
                  v-if="item.description"
                  bottom
                  max-width="500"
                  content-class="tooltip_multiline"
                  color="font"
                >
                  <template #activator="{ on }">
                    <v-icon v-on="on" small color="primary"> mdi-help-circle </v-icon>
                  </template>
                  <span>{{ item?.description }}</span>
                </v-tooltip>
              </template>
            </v-treeview>
            <v-row v-else class="fill-height justify-center align-center no-gutters">
              <v-col cols="auto">
                <v-subheader>
                  {{ $i18n.t('admin.noData') }}
                </v-subheader>
              </v-col>
            </v-row>
          </v-tab-item>
        </grey-tabs>
      </template>
    </template>
  </main-large-dialog>
</template>

<script>
import { get } from 'vuex-pathify';
import chain from 'lodash/chain';
import mixin from 'lodash/mixin';
import map from 'lodash/map';
import filter from 'lodash/filter';
import value from 'lodash/value';
import wrapperLodash from 'lodash/wrapperLodash';

mixin(wrapperLodash, {
  chain,
  map,
  filter,
});
wrapperLodash.value = value;
wrapperLodash.prototype.value = value;

import stylePreviewUtilities from '@/mixins/stylePreviewUtilities';

import MainLoader from '@/components/MainLoader';
import GreyTabs from '@/components/GreyTabs';
import MainLargeDialog from '@/components/MainLargeDialog';
import DataInput from '@/components/DataInput';

export default {
  name: 'LayersAdding',
  mixins: [stylePreviewUtilities],
  components: {
    MainLargeDialog,
    MainLoader,
    GreyTabs,
    DataInput,
  },
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    isLayersAddingVisible: {
      type: Boolean,
      required: true,
    },
    layers: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentLayersTab: 0,
    layersChecked: {},
    layersSchema: {},
    lastKey: 0,
    isLoaded: false,
    searchValue: '',
  }),
  computed: {
    layersAll() {
      return JSON.parse(JSON.stringify(this.$store.get('layers/layers')));
    },
    cacheLayersMetadata: get('layers/cacheLayersMetadata'),
    mapPanelDataFetched: get('map/mapPanelDataFetched'),
    settings: get('admin/settingsValues'),
    permissions: get('users/toolsPermissions'),
    additionalModules: get('admin/additionalModules'),
    layersSchemaByScope: get('layers/layersSchemaByScope'),
    currentUser: get('users/currentUser'),
    dataLoading() {
      return !this.isLoaded || (!this.mapPanelDataFetched && !this.admin);
    },
    tabs() {
      return [
        {
          name: 'core',
          types: [{ layerScope: 'core', schemaScope: 'core', visible: this.layersSchemaByScope?.core || false }],
          text: this.$i18n.t('dialog.layersPublic'),
        },
        {
          name: 'water',
          types: [
            {
              layerScope: 'water',
              schemaScope: 'water',
              visible: this.getIsTabVisible('WATER_DATA', 'water_data_module_enabled'),
            },
          ],
          text: this.$i18n.t('dialog.wodLayers'),
        },
        {
          name: 'sewer',
          types: [
            {
              layerScope: 'sewer',
              schemaScope: 'sewer',
              visible: this.getIsTabVisible('SEWER_DATA', 'sewer_data_module_enabled'),
            },
          ],
          text: this.$i18n.t('dialog.sewerLayers'),
        },
        {
          name: 'zdm_data',
          types: [{ layerScope: 'zdm_data', schemaScope: 'zdm_data', visible: this.getIsTabVisible('ZDM_DATA') }],
          text: this.$i18n.t('dialog.lightingData'),
        },
        {
          name: 'masterplan_squares',
          types: [
            {
              layerScope: 'masterplan_squares',
              schemaScope: 'masterplan_squares',
              visible: this.getIsTabVisible('MASTERPLAN_SQUARES', 'masterplan_squares_module_enabled'),
            },
            {
              layerScope: 'masterplan_illumination',
              schemaScope: 'masterplan_illumination',
              visible: this.getIsTabVisible('MASTERPLAN_ILLUMINATION', 'masterplan_illumination_module_enabled'),
            },
            {
              layerScope: 'masterplan_streets',
              schemaScope: 'masterplan_streets',
              visible: this.getIsTabVisible('MASTERPLAN_STREETS', 'masterplan_streets_module_enabled'),
            },
          ],
          text: this.$i18n.t('dialog.masterplan'),
        },
        {
          name: 'cache',
          types: [{ layerScope: 'cache', visible: this.getIsTabVisible('CACHE_LAYER_MODULE') }],
          text: this.$i18n.t('dialog.cacheLayers'),
        },
        {
          name: 'module',
          types: [{ layerScope: 'module', schemaScope: 'core', visible: this.layersSchemaByScope?.module || false }],
          type: 'layers',
          text: this.$i18n.t('dialog.moduleLayers'),
        },
      ];
    },
    filteredTabs() {
      return this.tabs.filter(tab => tab.types.find(type => type.visible));
    },
    greyTabs() {
      return this.filteredTabs.map(tab => {
        return { name: tab.text };
      });
    },
    isAnyLayerChecked() {
      return this.checkedLayers.length > 0;
    },
    checkedLayers() {
      return Object.keys(this.layersChecked)
        .reduce((total, current) => {
          return [...total, ...this.layersChecked[current]];
        }, [])
        .filter(l => l.type);
    },
    checkedLayersIds() {
      return (
        this.checkedLayers?.map(layer => {
          const { schema, id } = layer;
          return { schema, id };
        }) || []
      );
    },
    addedLayersIds() {
      return this.layers.map(l => l.layer_id);
    },
    computedIsVisible: {
      get() {
        return this.isLayersAddingVisible;
      },
      set(nV) {
        this.$emit('update:isLayersAddingVisible', nV);
      },
    },
  },
  watch: {
    isLayersAddingVisible(nv) {
      if (nv) {
        this.init();
      }
    },
    mapPanelDataFetched: {
      immediate: true,
      handler(nV) {
        if (nV) {
          this.init();
        }
      },
    },
    layersSchemaByScope: {
      deep: true,
      handler() {
        this.init();
      },
    },
    searchValue(nV) {
      for (const tab of Object.keys(this.$refs).filter(ref => ref.startsWith('tree'))) {
        this.$refs[tab][0].updateAll(!!nV);
      }
    },
  },
  methods: {
    getIsTabVisible(enableModuleName, enableSettingName) {
      const hasPermission = enableModuleName ? this.permissions[enableModuleName]?.main_value > 0 : true;
      const settingsValue = enableSettingName ? this.settings[enableSettingName] || false : true;
      return settingsValue && hasPermission && this.additionalModules[enableModuleName]?.enabled;
    },
    addLayersHandler() {
      const checkedWholeGroups = this.$_objectToArray(this.layersSchema)
        .flat()
        .filter(element => {
          return element.children?.every(layer => {
            return this.checkedLayersIds.find(checked => checked.id === layer.id && checked.schema === layer.schema);
          });
        });
      const checkedSoloLayers = this.checkedLayers.filter(layer => {
        return !checkedWholeGroups.find(group => group.children.find(l => l.id === layer.id));
      });
      this.$emit('addLayers', [...checkedWholeGroups, ...checkedSoloLayers]);
      this.layersChecked = this.tabs.reduce((total, current) => {
        return {
          ...total,
          [current.name]: [],
        };
      }, {});
    },
    checkLayer(layer) {
      if (!layer) {
        return false;
      } else if (this.addedLayersIds.includes(layer.id)) {
        return false;
      }
      return true;
    },
    checkCacheLayer(layerId) {
      return Boolean(this.cacheLayersMetadata[layerId.id]?.last_cache_datetime);
    },
    closeLayersAdding() {
      this.isLayersAddingVisible = false;
    },
    generateLayersGroup(layers, type, layerType) {
      let result = [];
      if (type === 'subgroups') {
        result = wrapperLodash(layers)
          .map(layer => {
            if (!layer) {
              return;
            }
            const data = {
              key: ++this.lastKey,
              id: layer.id,
              name: layer.name,
            };
            const childrenLayers = layer.layers ? this.generateLayersGroup(layer.layers, 'layers', layerType) : [];
            const childrenSubgroups = layer.subgroups
              ? this.generateLayersGroup(layer.subgroups, 'subgroups', layerType)
              : [];
            if (childrenLayers.length > 0 || childrenSubgroups.length > 0) {
              data.children = [...childrenLayers, ...childrenSubgroups];
            } else {
              return;
            }
            return data;
          })
          .filter(x => x)
          .value();
      } else if (type === 'layers') {
        result = wrapperLodash(layers)
          .map(layerId => {
            if (!layerId) {
              return;
            }
            const layer = { ...this.layersAll[layerId.id] };
            if (layerType === 'cache' && !this.checkCacheLayer(layerId)) {
              return;
            }
            if (!this.checkLayer(layer, layerType)) {
              return;
            }
            const data = layer;
            data.key = ++this.lastKey;
            data.schema = layerType;
            return data;
          })
          .filter(x => x)
          .value();
      }
      return result;
    },
    refreshCheckedLayers() {
      for (const schema of Object.keys(this.layersSchema)) {
        for (const element of this.layersSchema[schema]) {
          if (element.children) {
            for (const layer of element.children) {
              this.$set(
                layer,
                'disabled',
                this.checkedLayersIds.find(checked => checked.id === layer.id && checked.schema !== layer.schema)
                  ? true
                  : false
              );
            }
          } else {
            this.$set(
              element,
              'disabled',
              this.checkedLayersIds.find(checked => checked.id === element.id && checked.schema !== element.schema)
                ? true
                : false
            );
          }
        }
      }
    },
    async getLayersGroups() {
      this.isLoaded = false;
      this.layersSchema = this.filteredTabs.reduce((total, current) => {
        let layers = [];
        for (const type of current.types) {
          layers = layers.concat(this.layersSchemaByScope?.[type.layerScope]?.[current.type || 'groups']);
        }
        return {
          ...total,
          [current.name]: this.generateLayersGroup(layers, current.type || 'subgroups', current.name),
        };
      }, {});
      this.isLoaded = true;
    },
    init() {
      this.searchValue = '';
      if (!this.mapPanelDataFetched && !this.admin) {
        return;
      }
      this.layersSchema = this.layersChecked = this.tabs.reduce((total, current) => {
        return {
          ...total,
          [current.name]: [],
        };
      }, {});
      this.getLayersGroups();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-treeview-node__content {
    text-align: left;
  }

  .v-slide-group {
    z-index: 99;
    position: sticky;
    top: 0;
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
  }

  .v-treeview-node__checkbox {
    font-size: 18px;
    margin-left: 0px !important;
  }

  .v-treeview-node__toggle {
    color: rgba(0, 0, 0, 0.7);
  }

  .v-treeview-node__root {
    min-height: 35px;
  }

  .v-tabs-items {
    height: calc(100% - 48px);
    overflow: auto;
  }

  .v-window__container,
  .v-window-item {
    height: 100%;
  }

  .v-treeview-node__content {
    margin-left: 2px !important;
  }

  .v-treeview-node__label {
    display: flex;
    flex: 0 1 auto;
  }

  .v-treeview-node__prepend {
    min-width: 0;
  }
}
</style>
