<template>
  <v-row no-gutters class="flex-nowrap" :class="{ 'navbar-measurements-active': $attrs.isActive }">
    <v-col>
      <the-navbar-top-panel-menu
        attach="#measurementToolButton"
        :has-default-activator="false"
        v-bind="{ ...$attrs, tools: measurementTools }"
        :path="$attrs.name"
      >
        <template #[`menu.activator`]="{ on: onMenu }">
          <span id="measurementToolButton">
            <table-button
              v-on="{ ...(!$attrs.isActive && { ...onMenu }), ...$attrs.onTooltip }"
              :is-active="$attrs.isActive"
              name="saveMeasurement"
              :icon="$attrs.isActive ? 'mdi-cancel' : $attrs.icon"
              fontSize="19px"
              buttonSize="small"
              @click="measurementsClick"
              :disabled="$attrs.disabled"
            />
          </span>
        </template>
      </the-navbar-top-panel-menu>
    </v-col>
    <v-col v-if="measurementsGeometriesObject?.features?.length">
      <the-navbar-top-panel-menu
        attach="#measurementExportButton"
        :has-default-activator="false"
        @exportToGeoJSON="exportMeasurement('geojson')"
        @exportToKML="exportMeasurement('kml')"
        @exportToSystem="openGeometryCopyDialog"
        v-bind="{ ...$attrs, tools: measurementExportTools }"
        :path="$attrs.name"
      >
        <template #[`menu.activator`]="{ on: onMenu }">
          <span id="measurementExportButton">
            <table-button
              v-on="{ ...onMenu, ...$attrs.on }"
              name="saveMeasurement"
              icon="mdi-content-save"
              fontSize="19px"
              buttonSize="small"
              :tooltip="$i18n.t('navbar.saveMeasurement')"
            />
          </span>
        </template>
      </the-navbar-top-panel-menu>
    </v-col>
    <v-col
      v-if="
        measurementsGeometriesObject?.features?.length > 1 &&
        !measurementsGeometriesObject?.features?.[0]?.geometry.type.includes('LineString')
      "
    >
      <table-button
        name="intersect"
        icon="$intersect"
        fontSize="19px"
        buttonSize="small"
        :tooltip="
          intersectActive ? $i18n.t('navbar.measurementIntersectOff') : $i18n.t('navbar.measurementIntersectOn')
        "
        :is-active="intersectActive"
        @click="intersect"
      />
    </v-col>
    <table-buttons-copy-objects-dialog
      v-if="isCopyObjectsGeometryLoaded"
      :is-visible.sync="isCopyObjectsGeometryVisible"
      :is-loading.sync="isCopyObjectGeometryLoading"
      input-layer-id="measurements"
      :geojson="measurementsGeometriesObject"
      :geojson-copy-attributes="mappingAttributes"
      :disable-attributes-mapping="activeTool !== 'measurementMeasures'"
    />
  </v-row>
</template>

<script>
import { get, sync } from 'vuex-pathify';

import TableButton from '@/components/TableButton';
import TheNavbarTopPanelMenu from '@/components/TheNavbarTopPanelMenu';
import turfIntersect from '@turf/intersect';
import turfUnion from '@turf/union';
import { GeoJSON } from 'ol/format';
import { getGeodesicArea } from '@/assets/js/mapUtils';

export default {
  name: 'TheNavbarToolsMeasurements',
  components: {
    TableButton,
    TheNavbarTopPanelMenu,
    TableButtonsCopyObjectsDialog: () => import('@/components/TableButtonsCopyObjectsDialog'),
  },
  data: vm => ({
    currentMeasurementCopyObject: null,
    isCopyObjectsGeometryLoaded: false,
    isCopyObjectsGeometryVisible: false,
    isCopyObjectGeometryLoading: false,
    intersectActive: false,
    measurementTools: [
      {
        name: 'measurementArea',
        hasDivider: false,
        hasCustomAction: true,
      },
      {
        name: 'measurementLength',
        hasDivider: false,
        hasCustomAction: true,
      },
      {
        name: 'measurementRadius',
        hasDivider: false,
        hasCustomAction: true,
      },
      {
        name: 'measurementMeasures',
        verboseName: vm.$i18n.t('navbar.measurementMeasures.title'),
        hasCustomAction: true,
      },
    ],
    measurementExportTools: [
      {
        name: 'exportToGeoJSON',
        hasDivider: false,
        hasCustomAction: true,
      },
      {
        name: 'exportToKML',
        hasDivider: false,
        hasCustomAction: true,
      },
      {
        name: 'exportToSystem',
        hasDivider: false,
        hasCustomAction: true,
      },
    ],
    mappingAttributes: [
      {
        attribute: vm.$i18n.t('navbar.measurementMeasures.typeAttribute'),
        dataType: { name: 'text' },
        label: vm.$i18n.t('navbar.measurementMeasures.typeAttribute'),
      },
      {
        attribute: vm.$i18n.t('navbar.measurementMeasures.lengthAttribute'),
        dataType: { name: 'float' },
        label: vm.$i18n.t('navbar.measurementMeasures.lengthAttribute'),
      },
    ],
  }),
  computed: {
    measurementsGeometries: get('map/measurementsGeometries'),
    measurementsGeometriesObject() {
      return JSON.parse(this.measurementsGeometries || null);
    },
    measurementsIntersect: sync('map/measurementsIntersect'),
    isMeasurementsToolActive: get('tools/toolStatus@isMeasurementsToolActive'),
    activeTool: get('tools/activeTool'),
  },
  methods: {
    openGeometryCopyDialog() {
      this.currentMeasurementCopyObject = {
        ...this.measurementsGeometriesObject,
        features: [this.measurementsGeometriesObject.features[0]],
      };
      this.isCopyObjectsGeometryLoaded = true;
      this.isCopyObjectsGeometryVisible = true;
    },
    exportMeasurement(type) {
      this.$root.$emit('exportMeasurement', type);
    },
    measurementsClick() {
      if (this.isMeasurementsToolActive) {
        this.$root.$emit(`${this.activeTool}-action`, false);
      }
    },
    intersect() {
      if (this.intersectActive) {
        this.measurementsIntersect = null;
        this.intersectActive = false;
        return;
      }
      try {
        const intersect = turfIntersect(this.measurementsGeometriesObject);
        if (!intersect) {
          this.$store.set('snackbar/PUSH_ERROR!', {
            message: this.$i18n.t('errors.measurementIntersect'),
          });
          this.measurementsIntersect = null;
          return;
        }
        const featuresGeometries = this.measurementsGeometriesObject.features.map(feature => {
          return { ...feature.geometry, crs: this.measurementsGeometriesObject.crs };
        });
        const union = featuresGeometries.reduce(
          (geometryA, geometryB) => turfUnion(geometryA, geometryB),
          featuresGeometries[0]
        );
        const unionFeature = new GeoJSON().readFeature(union, {
          featureProjection: this.$_config.defaultEpsg || 'EPSG:3857',
          dataProjection: this.$_config.defaultEpsg || 'EPSG:3857',
        });
        const area = getGeodesicArea(unionFeature.getGeometry());
        intersect.properties.union_area = area;
        this.measurementsIntersect = intersect;
        this.intersectActive = true;
      } catch {
        this.measurementsIntersect = null;
      }
    },
  },
  watch: {
    measurementsIntersect: {
      handler(nV) {
        if (!nV) this.intersectActive = false;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-measurements-active {
  background-color: map-get($colors, 'primarybackground');
}
</style>
