<template>
  <v-row no-gutters class="flex-nowrap" :class="{ 'navbar-snapping-config-box': isActive }" align="center">
    <v-col cols="auto">
      <v-tooltip bottom color="font">
        <template #activator="{ on }">
          <span v-on="on">
            <v-btn
              v-on="on"
              v-bind="{ ...$props, ...$attrs, tooltip }"
              :class="{ background: isActive, divider: hasDivider }"
              :retain-focus-on-click="false"
              :color="$_colors.iconIdle"
              icon
              small
              @click="toggleSnappingConfig"
              :disabled="isModifySnappingActive"
            >
              <v-icon size="19px" :class="{ activeicon: isActive }">
                {{ icon }}
              </v-icon>
            </v-btn>
          </span>
        </template>
        {{ tooltip }}
      </v-tooltip>
    </v-col>
    <v-col cols="auto" class="pr-1" style="width: 57px" v-if="isActive">
      <data-input
        v-model="currentSnappingToleranceValue"
        @input="onInput"
        v-bind="snappingInput"
        class="navbar-snapping-config-input"
      />
    </v-col>
  </v-row>
</template>
<script>
import { get, sync } from 'vuex-pathify';
import { debounce } from 'lodash';
import DataInput from '@/components/DataInput';
export default {
  name: 'TheNavbarToolsSnappingConfigButton',
  components: { DataInput },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    hasDivider: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    isModifySnappingActive: get('edit/isModifySnappingActive'),
    isSnappingConfigToolActive: sync('tools/toolStatus@isSnappingConfigToolActive'),
    snappingConfigToolToleranceValue: sync('tools/snappingConfigToolToleranceValue'),
    snappingInput() {
      return {
        dataType: {
          name: 'integer',
        },
        dense: true,
        height: '20',
        hideDetails: true,
        suffix: 'px',
        outlined: true,
        singleLine: true,
        hideArrows: true,
        allowNegative: false,
        minValue: 1,
        maxValue: 100,
        disabled: this.isModifySnappingActive,
      };
    },
    tooltip() {
      return this.isModifySnappingActive
        ? this.$i18n.t('navbar.toSnappingConfigTooltip', {
            prefix: this.$i18n.t(`navbar.${this.isSnappingConfigToolActive ? 'toOff' : 'toOn'}`),
          })
        : this.$i18n.t('navbar.snappingConfigTooltip', {
            prefix: this.$i18n.t(`navbar.${this.isSnappingConfigToolActive ? 'off' : 'on'}`),
          });
    },
  },
  data: () => ({
    currentSnappingToleranceValue: 10,
  }),
  methods: {
    onInput(value) {
      if (value && value >= 1 && value <= 100) {
        localStorage.setItem('snapping-config-tolerance', value);
        this.snappingConfigToolToleranceValue = value;
      }
    },
    toggleSnappingConfig() {
      this.isSnappingConfigToolActive = !this.isActive;
      localStorage.setItem('snapping-config-value', this.isSnappingConfigToolActive);
    },
  },
  watch: {
    snappingConfigToolToleranceValue(nV) {
      if (nV) this.currentSnappingToleranceValue = nV;
    },
  },
  created() {
    this.onInput = debounce(this.onInput, 275);
  },
  mounted() {
    try {
      const localStorageSnappingConfigToolToleranceValue = localStorage.getItem('snapping-config-tolerance');
      const snappingConfigToolToleranceValue = localStorageSnappingConfigToolToleranceValue
        ? parseInt(localStorageSnappingConfigToolToleranceValue)
        : 10;
      this.snappingConfigToolToleranceValue = snappingConfigToolToleranceValue;
      this.currentSnappingToleranceValue = snappingConfigToolToleranceValue;

      const localStorageSnappingConfigToolValue = localStorage.getItem('snapping-config-value');
      const snappingConfigToolValue = localStorageSnappingConfigToolValue
        ? JSON.parse(localStorageSnappingConfigToolValue)
        : false;
      this.isSnappingConfigToolActive = snappingConfigToolValue;
    } catch {
      this.snappingConfigToolToleranceValue = 10;
      this.currentSnappingToleranceValue = 10;
      this.isSnappingConfigToolActive = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.navbar-snapping-config-box {
  border-radius: 5px;
  background-color: map-get($colors, 'primarybackground');
}
::v-deep {
  .activeicon {
    color: map-get($colors, 'link') !important;
  }
  .navbar-snapping-config-input {
    .v-input__slot {
      min-height: 0px !important;
      padding: 0 4px !important;
    }
    .v-input__append-inner {
      margin: 0 !important;
    }
    .v-input__prepend-inner {
      margin: 0 !important;
      .v-input__icon {
        .v-icon {
          font-size: 16px;
          padding-bottom: 3px;
        }
      }
    }
    .v-label {
      font-size: 12px;
      top: 0 !important;
    }
    .v-text-field__slot input {
      font-size: 12px !important;
      padding-bottom: 3px !important;
    }
    .v-text-field__suffix {
      color: rgba(0, 0, 0, 0.59);
      font-size: 10px;
    }
  }
}
</style>
