<template>
  <navbar-menu-tool
    v-model="isMenuOpen"
    :menu-data="{ name, position: 'left', allowOtherTop: true }"
    :button-bind="{ icon, tooltip, verboseName: tooltip }"
    has-close-header
    has-footer
  >
    <template #content>
      <div class="ma-2 text-body-2">
        <span class="text-left" style="font-size: 12px">
          <v-row no-gutters class="py-2" align="center">
            <v-col cols="5"> {{ 'Szukaj działek na obszarze' }}: </v-col>
            <v-col cols="7">
              <dots-menu
                :items="dotsItemsArea"
                @multiple="turnOnDrawing('multiple')"
                @freehand="turnOnDrawing('freehand')"
                @single="turnOnDrawing('single')"
              >
                <template #activator="{ on }">
                  <base-button
                    v-on="getButtonListeners(on)"
                    translation-path="sidebar.searchArea.selectArea"
                    :disabled="isDrawn"
                    class="mr-2"
                    :button-style="buttonStyle"
                  />
                </template>
              </dots-menu>
              <v-tooltip v-if="isDrawn || !!selectedFeatures.length" color="font" bottom>
                <template #activator="{ on }">
                  <v-icon v-on="on" color="error" :disabled="isLoading" @click="clearSelection">mdi-cancel</v-icon>
                </template>
                {{ $i18n.t('sidebar.searchArea.clearSelection') }}
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row no-gutters class="py-2" align="center">
            <v-col cols="5"> {{ $i18n.t('sidebar.searchArea.areaFromTo') }}: </v-col>
            <v-col cols="7" class="d-flex">
              <v-row no-gutters align="center">
                <v-col cols="auto" class="d-flex align-center input-area">
                  <data-input v-model="payload['area_ha_from']" v-bind="areaInput" />
                  <span class="ml-1 mr-2 italic--text">ha</span>
                </v-col>
                <v-col cols="auto" class="d-flex align-center input-area">
                  <data-input v-model="payload['area_ha_to']" v-bind="areaInput" />
                  <span class="ml-1 mr-2 italic--text">ha</span>
                </v-col>
                <popover-menu class="pl-1">
                  <template #activator="{ on }">
                    <v-icon size="18" v-on="on" color="primary">mdi-help-circle</v-icon>
                  </template>
                  <v-row no-gutters>
                    <v-col cols="12" style="width: 345px">
                      <v-row no-gutters class="pb-2 italic--text"> {{ $i18n.t('sidebar.searchArea.tooltip1') }} </v-row>
                      <v-row no-gutters class="font-weight-medium"> {{ $i18n.t('sidebar.searchArea.title2') }} </v-row>
                      <v-row no-gutters class="pb-2 italic--text"> {{ $i18n.t('sidebar.searchArea.tooltip2') }} </v-row>
                      <v-row no-gutters class="font-weight-medium"> {{ $i18n.t('sidebar.searchArea.title3') }} </v-row>
                      <v-row no-gutters class="italic--text"> {{ $i18n.t('sidebar.searchArea.tooltip3') }} </v-row>
                    </v-col>
                  </v-row>
                </popover-menu>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters class="py-2">
            <v-col cols="auto"> {{ $i18n.t('sidebar.searchArea.which') }}: </v-col>
          </v-row>
          <v-row no-gutters class="py-2" v-for="(param, idx) of paramsMapped" :key="idx" align="center">
            <v-col cols="5" class="pr-2">
              <data-input
                class="oze-area-search-select red--text"
                v-model="payload.params[param.name]"
                v-bind="paramInput"
                :style="[getColor(payload.params[param.name])]"
              />
            </v-col>
            <v-tooltip bottom color="font" :disabled="!param.tooltip">
              <template #activator="{ on }">
                <v-col v-on="on" cols="7" :class="{ 'italic--text': payload.params[param.name] === null }">
                  {{ param.text }}
                </v-col>
              </template>
              {{ param.tooltip }}
            </v-tooltip>
          </v-row>
          <v-row no-gutters class="py-2">
            <v-col cols="auto"> {{ $i18n.t('sidebar.searchArea.optionalExcludedLayersTitle') }}: </v-col>
          </v-row>
          <v-row no-gutters class="py-2">
            <v-col cols="12">
              <data-input v-model="payload.excluded_layers" v-bind="excludedLayersInput">
                <template #prepend-item>
                  <v-list-item @click="toggleSelectAllExcludedLayers">
                    <v-list-item-action>
                      <v-icon :color="payload.excluded_layers.length > 0 ? 'primary' : ''">
                        {{ getIconSelectAllExcludedLayers() }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> {{ $i18n.t('button.selectAllItems') }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </template>
                <template #selection="{ item, index }">
                  <v-tooltip color="font" bottom>
                    <template #activator="{ on }">
                      <v-chip small v-if="index < 2" v-on="on">
                        <span class="d-inline-block text-truncate">{{ item.text }}</span>
                      </v-chip>
                    </template>
                    {{ item.text }}
                  </v-tooltip>
                  <span v-if="index === 2" style="white-space: nowrap" class="text-caption grey--text">
                    {{ `(+${$i18n.tc('admin.other', payload.excluded_layers.length - 2)})` }}
                  </span>
                </template>
              </data-input>
            </v-col>
          </v-row>
          <v-row v-if="selectedFeatures?.length > 1" no-gutters class="py-2">
            <data-input v-model="payload.is_intersection" v-bind="checkboxInput">
              <template #label>
                <span class="font--text">{{ $i18n.t('sidebar.searchArea.is_intersection') }}</span>
              </template>
            </data-input>
          </v-row>
          <v-row no-gutters class="py-2">
            <data-input v-model="payload.is_within" v-bind="checkboxInput">
              <template #label>
                <span class="font--text">{{ $i18n.t('sidebar.searchArea.is_within') }}</span>
              </template>
            </data-input>
          </v-row>
        </span>
        <table-buttons-copy-objects-dialog
          v-if="isCopyLoaded"
          :is-visible.sync="isCopyVisible"
          :is-loading.sync="isCopyLoading"
          input-layer-id="investment-area-search"
          :geojson="results"
          :geojson-copy-attributes="resultAttributes"
        />
      </div>
    </template>
    <template #[`content.footer`]>
      <base-button
        translation-path="dialog.agreeSearch"
        class="ml-3"
        :disabled="!isFormValid"
        :loading="isLoading"
        @click="search"
      />
      <base-button
        class="mx-0"
        v-if="results"
        @click="reset"
        :disabled="isLoading"
        translation-path="default.clearResult"
        cancel
      />
      <v-col v-if="!!results" cols="auto">
        <dots-menu :items="dotsItemsSave" @geojson="saveFile('geojson')" @kml="saveFile('kml')" @copy="toggleCopy()">
          <template #activator="{ on }">
            <v-tooltip color="font" bottom>
              <template #activator="{ on: onTooltip }">
                <span v-on="{ ...onTooltip }">
                  <v-icon v-on="{ ...on }" color="primary" tag="button"> mdi-content-save </v-icon>
                </span>
              </template>
              {{ $i18n.t('sidebar.searchArea.saveResult') }}
            </v-tooltip>
          </template>
        </dots-menu>
      </v-col>
    </template>
  </navbar-menu-tool>
</template>
<script>
const params = [
  {
    name: 'amw',
    source: 'geoportal.gov.pl',
  },
  {
    name: 'kowr',
    source: 'geoportal.gov.pl',
  },
  {
    name: 'lasy',
    source: 'bdl.lasy.gov.pl',
  },
  {
    name: 'ock',
    source: 'sdi.gdos.gov.pl',
  },
  {
    name: 'oso',
    source: 'sdi.gdos.gov.pl',
  },
  {
    name: 'soo',
    source: 'sdi.gdos.gov.pl',
  },
  {
    name: 'pk',
    source: 'sdi.gdos.gov.pl',
  },
  {
    name: 'pn',
    source: 'sdi.gdos.gov.pl',
  },
  {
    name: 'rezerwaty',
    source: 'sdi.gdos.gov.pl',
  },
  {
    name: 'rdlp',
    source: 'geoportal.gov.pl',
  },
];

import { call, get } from 'vuex-pathify';

import DataInput from '@/components/DataInput';
import DotsMenu from '@/components/DotsMenu';
import NavbarMenuTool from '@/components/NavbarMenuTool';
import PopoverMenu from '@/components/PopoverMenu';

import { KML, GeoJSON } from 'ol/format';
import { createBox } from 'ol/interaction/Draw';
import { transformExtent } from 'ol/proj';
import { Fill, Stroke, Style, Text } from 'ol/style';

import { debounce } from 'lodash';

export default {
  name: 'TheNavbarToolsPanelOzeAreaSearch',
  components: {
    DataInput,
    DotsMenu,
    NavbarMenuTool,
    PopoverMenu,
    TableButtonsCopyObjectsDialog: () => import('@/components/TableButtonsCopyObjectsDialog'),
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
  },
  computed: {
    moduleShareableData: get('moduleSharing/moduleSharingData'),
    selectedFeaturesLayers: get('map/selectedFeatures'),
    areaInput() {
      return {
        dataType: { name: 'decimal', decimal_places: 3 },
        disabled: this.loading,
        hideDetails: true,
      };
    },
    buttonStyle() {
      return this.isDrawing
        ? {
            backgroundColor: `${this.$_colors.primarybackground} !important`,
            border: '1px solid rgba(26, 115, 232, 0.3) !important',
            color: this.$_colors.primary,
          }
        : {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
            border: '1px solid rgba(0, 0, 0, 0.2) !important',
            color: '#61646D',
          };
    },
    checkboxInput() {
      return {
        dataType: { name: 'checkbox' },
        disabled: this.loading,
        hideDetails: true,
      };
    },
    excludedLayersInput() {
      return {
        dataType: { name: 'select' },
        items: this.projectLayersMapped,
        chips: true,
        multiple: true,
        autoConvertSelect: false,
        disabled: this.loading,
        hideDetails: true,
      };
    },
    paramInput() {
      return {
        dataType: { name: 'select' },
        items: [
          { value: true, text: this.$i18n.t('sidebar.searchArea.included') },
          { value: false, text: this.$i18n.t('sidebar.searchArea.excluded') },
          { value: null, text: this.$i18n.t('sidebar.searchArea.ignore') },
        ],
        hideDetails: true,
        disabled: this.loading,
        disabledSorting: true,
      };
    },
    paramsMapped() {
      return params.map(({ name, source }) => ({
        name: name,
        text: this.$i18n.t(`sidebar.searchArea.${name}`),
        tooltip: this.$i18n.t('sidebar.searchArea.source', { value: source }),
      }));
    },

    currentProjectId: get('layers/project@id'),
    projectElements: get('layers/project@layers'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers.filter(
        layer => layer.geometry_type === 'polygon' || layer.geometry_type === 'multipolygon'
      );
    },
    projectLayersMapped() {
      return this.projectLayers.map(layer => ({ value: layer.id, text: layer.name }));
    },
  },
  data: vm => ({
    menuOpenWatch: undefined,
    dotsItemsArea: [
      { name: 'multiple' },
      { name: 'freehand' },
      { name: 'single', customName: vm.$i18n.t('menu.selectFeatures') },
    ],
    dotsItemsSave: [
      { name: 'geojson', customName: vm.$i18n.t('menu.toGeojsonFile') },
      { name: 'kml', customName: vm.$i18n.t('menu.toKmlFile') },
      { name: 'copy', customName: vm.$i18n.t('menu.copyToLayer') },
    ],
    isCopyLoaded: false,
    isCopyVisible: false,
    isCopyLoading: false,
    isDrawing: false,
    isDrawn: false,
    isFormValid: false,
    isMenuOpen: false,
    isLoading: false,
    loading: false,
    payload: {
      area_ha_from: null,
      area_ha_to: null,
      params: {},
      excluded_layers: [],
      bbox: null,
      geojson: null,
    },
    results: undefined,
    selectedFeatures: [],
    resultAttributes: [],
  }),
  methods: {
    getSearchedArea: call('externalSystems/getSearchedArea'),
    getSearchAreaMappings: call('externalSystems/getSearchAreaMappings'),
    getButtonListeners(on) {
      return this.isDrawing ? { click: this.turnOffSelecting } : on;
    },
    turnOffSelecting() {
      if (this.selectedFeatures.length) this.isDrawn = true;
      this.isDrawing = false;
      this.$root.$emit('selection-action');
    },
    turnOnDrawing(type) {
      this.isDrawing = true;
      if (type === 'single') {
        this.$root.$emit('identification-action', true, { specialType: 'addByMap' });
        this.$root.$off('assignedObjects');
        this.$root.$on('assignedObjects', identifiedFeatures => {
          const features = identifiedFeatures.map(feature => feature.features);
          for (const feature of features) {
            const { dataSource = '', layerId, id: featureId } = feature[0];
            if (!dataSource) return;
            const isFeatureSelected = this.selectedFeatures.some(el => el.layerId === layerId && el.id === featureId);
            if (isFeatureSelected) this.deleteFeature(feature[0]);
            else {
              this.selectedFeatures = this.selectedFeatures.concat(feature);
              this.$store.set('map/ADD_SELECTED_FEATURES!', {
                type: 'selectedFeatures',
                features: { [layerId]: [featureId] },
              });
              this.$root.$emit('setProjectLayerStyle', layerId);
            }
          }
        });
      } else {
        this.$root.$emit('drawSidebarGeometry', {
          drawendCallback: e => this.onDrawend(e, type),
          geometryFunction: type === 'multiple' ? createBox() : null,
          geometryType: 'polygon',
          isInitSnapping: false,
          freehand: type === 'freehand' ? true : false,
          ...(type === 'multiple' ? { drawType: 'Circle' } : {}),
        });
      }
    },
    onDrawend(e, type) {
      this.isDrawing = false;
      this.isDrawn = true;
      if (type === 'multiple') {
        this.payload.bbox = transformExtent(
          e.feature.getGeometry().getExtent(),
          this.$_config.defaultEpsg,
          'EPSG:4326'
        );
      }
      if (type === 'freehand') {
        this.payload.geojson = {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: { name: this.$_config.defaultEpsg || 'EPSG:4326' },
          },
          coordinates: e.feature.getGeometry().getCoordinates(),
        };
      }
    },
    clearSelection() {
      this.isDrawing = false;
      this.isDrawn = false;
      this.payload.bbox = null;
      this.payload.geojson = null;
      if (this.selectedFeatures.length) this.deleteFeatures();
      this.$root.$emit('clearSidebarGeometry');
      this.$root.$emit('identification-action', false);
    },
    deleteFeature({ layerId, id: featureId }) {
      if (featureId && layerId) {
        this.$store.set('map/DELETE_SELECTED_FEATURES!', {
          type: 'selectedFeatures',
          features: {
            [layerId]: [featureId],
          },
        });
      }
      const featureIndex = this.selectedFeatures.findIndex(ft => ft.id === featureId && ft.layerId === layerId);
      this.selectedFeatures.splice(featureIndex, 1);
    },
    deleteFeatures() {
      for (const layerId of Object.keys(this.selectedFeaturesLayers)) {
        this.$store.set('map/DELETE_SELECTED_FEATURES!', {
          type: 'selectedFeatures',
          features: {
            [layerId]: [],
          },
        });
      }
      this.selectedFeatures = [];
    },

    getColor(value) {
      if (value === true)
        return {
          '--current-color': this.$_colors['success'],
          '--current-color-light': this.$_getHexColorLighter(this.$_colors['success'], 90),
        };
      else if (value === false)
        return {
          '--current-color': this.$_colors['error'],
          '--current-color-light': this.$_getHexColorLighter(this.$_colors['error'], 90),
        };
      return {
        '--current-color': this.$_colors['italic'],
        '--current-color-light': this.$_getHexColorLighter(this.$_colors['italic'], 90),
      };
    },
    getIconSelectAllExcludedLayers() {
      if (this.payload.excluded_layers.length === this.excludedLayersInput.items.length) return 'mdi-checkbox-marked';
      else if (this.payload.excluded_layers.length) return 'mdi-minus-box';
      else return 'mdi-checkbox-blank-outline';
    },
    toggleSelectAllExcludedLayers() {
      if (this.payload.excluded_layers.length === this.excludedLayersInput.items.length)
        this.payload.excluded_layers = [];
      else this.payload.excluded_layers = this.excludedLayersInput.items.map(item => item.value);
    },

    setLocalStorage(value = this.payload) {
      localStorage.setItem(
        'oze-area-search-params',
        JSON.stringify(this.$_filterObjectKeys(value, { notAllowedKeys: ['bbox', 'geojson', 'layers'] }))
      );
    },
    setInitParams() {
      params.forEach(param => {
        this.$set(this.payload.params, param.name, null);
      });
      const paramsFromLocalStorage = JSON.parse(localStorage.getItem('oze-area-search-params') || '{}');
      paramsFromLocalStorage.excluded_layers = (paramsFromLocalStorage.excluded_layers || []).filter(layerId => {
        return this.projectLayersMapped.some(layer => layer.value === layerId);
      });
      this.payload = { ...this.payload, ...paramsFromLocalStorage };
    },
    reset() {
      this.$root.$emit('toggleCustomVectorLayer', { id: 'searchArea' });
      this.payload = {
        area_ha_from: null,
        area_ha_to: null,
        params: {},
        excluded_layers: [],
        bbox: null,
        geojson: null,
      };
      this.setInitParams();
      this.results = undefined;
    },
    async search() {
      this.isLoading = true;
      try {
        let payload = JSON.parse(JSON.stringify(this.payload));
        payload = {
          ...payload,
          ...Object.entries(payload.params).reduce((total, [key, value]) => {
            total[`area_ha_${key}_included`] = value;
            return total;
          }, {}),
        };
        delete payload.params;
        if (this.selectedFeatures.length) {
          payload.layers = [];
          this.selectedFeatures.forEach(feature => {
            if (payload.layers.some(layer => layer.layer_id === feature.layerId))
              payload.layers.find(layer => layer.layer_id === feature.layerId).ids.push(feature.id);
            else payload.layers.push({ layer_id: feature.layerId, ids: [feature.id] });
          });
        } else payload.is_intersection = false;
        this.results = await this.getSearchedArea(payload);
        const geojson = { ...this.results };
        this.$root.$emit('toggleCustomVectorLayer', {
          id: 'searchArea',
          geometry: geojson,
          fitView: true,
          layerParams: {
            style: f => {
              return [
                new Style({
                  fill: new Fill({
                    color: 'rgba(200, 0, 0, 0.6)',
                  }),
                  stroke: new Stroke({
                    color: 'rgba(255, 0, 0, 1)',
                    width: 2,
                  }),
                  text: new Text({
                    text: `${f.get('area_ha')?.toFixed(2) || 0} ha`,
                    font: 'normal 14px sans-serif',
                    fill: new Fill({ color: 'rgba(191, 54, 12, 1)' }),
                    stroke: new Stroke({
                      color: '#FFFFFF',
                      opacity: 1,
                      width: 3,
                      dash: [],
                    }),
                    overflow: true,
                  }),
                }),
              ];
            },
          },
        });
        this.clearSelection();
      } finally {
        this.isLoading = false;
      }
    },
    saveFile(type) {
      const contentString =
        type === 'kml'
          ? `<?xml version="1.0" encoding="utf-8" ?>${new KML().writeFeatures(
              new GeoJSON().readFeatures(this.results),
              {
                featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
                dataProjection: 'EPSG:4326',
              }
            )}`
          : JSON.stringify(this.results);
      const file = new Blob([contentString], {
        type: `${type === 'kml' ? 'text/xml' : 'application/geo+json'};charset=utf-8;`,
      });
      this.$_saveFile(URL.createObjectURL(file), `${this.$i18n.t('sidebar.searchArea.areas')}.${type}`);
    },
    toggleCopy() {
      this.isCopyLoaded = true;
      this.isCopyVisible = true;
    },

    async init() {
      this.menuOpenWatch();
      this.setInitParams();
      this.resultAttributes = ((await this.getSearchAreaMappings()) || []).map(attribute => {
        return {
          attribute: attribute.name,
          dataType: { name: attribute.data_type },
          label: attribute.verbose_name,
        };
      });
    },
  },
  watch: {
    'payload.bbox': {
      handler(nV) {
        this.isFormValid = !!nV;
      },
    },
    'payload.geojson': {
      handler(nV) {
        this.isFormValid = !!nV;
      },
    },
    payload: {
      handler(nV) {
        this.setLocalStorage(nV);
      },
      deep: true,
    },
    selectedFeatures(nV) {
      this.isFormValid = !!nV.length;
    },
    currentProjectId: {
      handler(nV, oV) {
        if (nV !== oV)
          this.payload.excluded_layers = this.payload.excluded_layers.filter(layerId => {
            return this.projectLayersMapped.some(layer => layer.value === layerId);
          });
      },
    },
    isMenuOpen(nV) {
      if (!nV) {
        this.isLoading = false;
        this.clearSelection();
      }
    },
    moduleShareableData: {
      handler(nV) {
        if (nV?.destinationTool === 'searchArea') {
          this.payload.geojson = JSON.parse(this.moduleShareableData.geometry);
          this.isDrawn = true;
          this.$store.set('moduleSharing/CLEAR_SHAREABLE_DATA!');
          this.isMenuOpen = true;
        }
      },

      deep: true,
    },
  },
  mounted() {
    this.setLocalStorage = debounce(this.setLocalStorage, 3000);
    this.menuOpenWatch = this.$watch(
      vm => vm.isMenuOpen,
      nV => {
        if (nV) this.init();
      },
      { immediate: true }
    );
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .input-area .v-input__control {
    width: 75px;
  }
  .oze-area-search-select {
    .v-input__slot {
      min-height: 22px !important;
      background-color: var(--current-color-light);
      fieldset {
        border: unset;
      }
    }
    .v-input__icon {
      padding-bottom: 7px;
    }
    .v-select__selection {
      color: var(--current-color) !important;
    }
    .v-select__selections input::placeholder {
      color: var(--current-color) !important;
    }
    .v-input__icon i {
      color: var(--current-color) !important;
      font-size: 20px;
    }
  }
}
</style>
