<template>
  <div
    class="sider-menu"
    :class="isExpanded ? 'sider-menu__expanded' : 'sider-menu__collapsed'"
    style="position: relative"
  >
    <div id="sider-menu__dummy-wrapper">
      <div
        @mouseleave="toggleHovered(false)"
        :class="{
          'sider-menu__expanded sider-menu__opened': isOpened,
          'sider-menu__collapsed sider-menu__closed': !isOpened,
        }"
        class="sider-menu__content elevation-4 sider-animation"
      >
        <div style="height: 100%; width: 100%" @mouseenter="toggleHovered(true)" class="pt-4 pb-0 d-flex flex-column">
          <div
            class="d-flex align-center sider-animation"
            :class="{ 'px-4': isOpened, 'px-2': !isOpened }"
            style="gap: 0.7rem"
          >
            <user-avatar id="current" />
            <span class="text-body-2 text-truncate font-weight-medium">{{ currentUser.name }}</span>
          </div>
          <div class="mt-2">
            <v-list dense style="text-align: left">
              <v-list-item-group :value="currentSubpage" color="primary">
                <v-list-item
                  v-for="(item, i) in subpages"
                  :key="i"
                  class="sider-list-item"
                  active-class="list-active"
                  @change="handleChangeTab(item.value)"
                  :input-value="item.value === currentSubpage"
                  :disabled="item.value === currentSubpage"
                  :value="item.value"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title class="text-truncate">{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <v-divider class="mt-2" />
          <div class="flex-grow-1 py-4 pb-4" style="min-height: 0" :style="{ overflowY: isOpened ? 'auto' : 'hidden' }">
            <component v-if="subpageComponent" :is="subpageComponent" :is-sider-opened="isOpened" />
          </div>
          <v-divider class="mb-2" />
          <div>
            <v-list dense style="text-align: left">
              <v-list-item
                v-for="(item, i) in settings"
                :key="i"
                class="sider-list-item"
                @click="handleSettingAction(item.value)"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-2">
                  <v-list-item-title class="text-truncate">{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </div>
        <span class="hide-sider-button">
          <v-tooltip bottom color="font">
            <template #activator="{ on }">
              <v-btn v-on="on" icon outlined small elevation="1" @click="toggleExpanded">
                <v-icon dark small>
                  {{ isExpanded ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
                </v-icon>
              </v-btn>
            </template>
            {{ isExpanded ? $i18n.t('sidebar.collapse') : $i18n.t('sidebar.expand') }}
          </v-tooltip>
        </span>
      </div>
    </div>
    <user-settings-dialog :is-visible.sync="isUserSettingsDialogVisible" v-if="isUserSettingsDialogVisible !== null" />
  </div>
</template>

<script>
import { sync, get, call } from 'vuex-pathify';
import { debounce } from 'lodash';
import SiderAdminSection from '@/components/SiderAdminSection';
import SiderDashboardSection from '@/components/SiderDashboardSection';

export default {
  name: 'SiderMenu',
  components: {
    UserSettingsDialog: () => import('@/components/UserSettingsDialog'),
    UserAvatar: () => import('@/components/UserAvatar'),
  },
  data: () => ({
    isHovered: false,
    isExpanded: false,
    isUserSettingsDialogVisible: null,
  }),
  computed: {
    dashboardModuleEnabled: get('admin/additionalModules@PAGE_TABLE_MODULE.enabled'),
    dashboardModulePermission: get('users/toolsPermissions@PAGE_TABLE_MODULE.main_value'),
    dashboardModuleVerboseName: get('admin/additionalModules@PAGE_TABLE_MODULE.verbose_name'),
    tokenUke: get('authentication/tokenUke'),
    redirectLogoutUrl: get('authentication/redirectLogoutUrl'),
    isExpandedState: sync('sider/isExpanded'),
    currentUser: get('users/currentUser'),
    currentTab: sync('sidebar/currentTab'),
    isOpened() {
      return this.isHovered || this.isExpanded;
    },
    currentSubpage() {
      return this.$route.path?.split('/')[1];
    },
    subpageComponent() {
      switch (this.currentSubpage) {
        case 'dashboard':
          return SiderDashboardSection;
        case 'map':
          return null;
        case 'admin':
          return SiderAdminSection;
        default:
          return null;
      }
    },
    subpages() {
      return [
        ...(this.dashboardModuleEnabled && this.dashboardModulePermission
          ? [{ value: 'dashboard', text: this.dashboardModuleVerboseName, icon: 'mdi-home' }]
          : []),
        { value: 'map', text: this.$i18n.t('sider.map'), icon: 'mdi-map-outline' },
        ...(this.currentUser.is_admin
          ? [{ value: 'admin', text: this.$i18n.t('sider.admin'), icon: 'mdi-account-cog' }]
          : []),
      ];
    },
    settings() {
      return [
        { value: 'settings', text: this.$i18n.t('sider.settings'), icon: 'mdi-cog' },
        { value: 'logout', text: this.$i18n.t('sider.logout'), icon: 'mdi-logout' },
      ];
    },
  },
  methods: {
    createGtagEvent: call('gtag/createEvent'),
    logout: call('authentication/logout'),
    handleChangeTab(tab) {
      switch (tab) {
        case 'dashboard': {
          this.$router.push({ name: 'dashboard' });
          break;
        }
        case 'map': {
          this.currentTab = 0;
          this.$router.push({ name: 'map' });
          break;
        }
        case 'admin': {
          this.$root.$emit('deactivateAllTools');
          this.$router.push({ name: this.$_config.defaultAdminTab });
          this.createGtagEvent({
            action: 'open_admin_view',
            category: 'map_navbar',
          });
          break;
        }
        default:
          break;
      }
    },
    handleSettingAction(setting) {
      switch (setting) {
        case 'settings': {
          this.isUserSettingsDialogVisible = true;
          break;
        }
        case 'logout': {
          this.logoutHandler();
          break;
        }
      }
    },
    async logoutHandler() {
      this.$root.$emit('socket.close', { name: 'default' });
      this.createGtagEvent({
        action: 'logout',
        extra_params: {
          method: 'private',
          user: this.currentUser.name,
        },
      });
      await this.logout();
      if (this.tokenUke && this.redirectLogoutUrl) {
        window.location.href = this.redirectLogoutUrl;
      } else {
        this.$router.push({ name: 'login', query: { refresh_app_data: true } });
      }
    },
    toggleHovered(expand) {
      this.isHovered = expand;
    },
    toggleExpanded() {
      const wrapper = document.querySelector('#sider-menu__dummy-wrapper');
      wrapper?.classList.add('disable-animation');
      this.isExpanded = !this.isExpanded;
      if (this.currentSubpage !== 'admin') this.isExpandedState = this.isExpanded;
      if (!this.isExpanded) this.toggleHovered(false);
      setTimeout(() => wrapper?.classList.remove('disable-animation'), 0);
    },
  },
  watch: {
    currentSubpage: {
      handler(nV) {
        this.isExpanded = nV === 'admin' || this.isExpandedState;
      },
      immediate: true,
    },
  },
  created() {
    this.toggleHovered = debounce(this.toggleHovered, 350);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

:deep(.sider-list-item .v-list-item__icon:first-of-type) {
  display: flex;
  justify-content: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 35px !important;
  min-width: 35px !important;
}

.sider-menu__opened :deep(.sider-list-item) {
  padding: 0 1rem !important;
}

.sider-menu__closed :deep(.sider-list-item) {
  padding: 0 0.5rem !important;
}

:deep(.sider-list-item),
:deep(.sider-list-item *) {
  transition: all 0.2s ease;
}

:deep(.sider-list-item *:not(.v-icon)) {
  font-size: 0.8125rem !important;
  font-weight: 500 !important;
}

:deep(.sider-list-item:not(.v-list-item--active) *:not(.v-icon)) {
  color: map-get($map: $colors, $key: 'iconIdle') !important;
}

:deep(.sider-list-item:not(.v-list-item--active) .v-icon) {
  color: map-get($map: $colors, $key: 'iconIdle') !important;
}

.list-active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-left: 2px solid map-get($colors, 'primary');
}

.sider-menu {
  z-index: 3;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  background-color: #fff;
  min-width: 0;
}
:deep(.sider-animation) {
  transition: all 0.2s ease;
}
:deep(.disable-animation),
:deep(.disable-animation *) {
  transition: none !important;
}
.sider-menu__content {
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.sider-menu__expanded {
  width: $sider-menu__expanded !important;
}
.sider-menu__collapsed {
  width: $sider-menu__collapsed !important;
}
.hide-sider-button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.hide-sider-button :deep(.v-btn) {
  border: 0.8px solid rgba(0, 0, 0, 0.2) !important;
  background-color: #fff;
  z-index: 4 !important;
}
</style>
