import { compose, create, toString as toTransformString } from 'ol/transform.js';

const prefetchOffscreenLayerData = async style =>
  new Promise(resolve => {
    const externalGraphics = new Set();
    if (style.externalGraphic) externalGraphics.add(style.externalGraphic);
    [...(style.ranges?.values ?? []), ...Object.values(style.uniques?.values ?? {})].forEach(value => {
      if (value.externalGraphic) externalGraphics.add(value.externalGraphic);
    });
    if (externalGraphics.size) {
      Promise.all([...externalGraphics].map(graphic => loadExternalGraphic(graphic))).then(result => {
        let parsedResult = {};
        result.forEach(([graphicName, bitmap]) => (parsedResult[graphicName] = bitmap));
        resolve(parsedResult);
      });
    } else {
      resolve(null);
    }
  });

const loadExternalGraphic = fileName =>
  new Promise(resolve => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.addEventListener('load', function () {
      createImageBitmap(image, 0, 0, image.width, image.height).then(imageBitmap => resolve([fileName, imageBitmap]));
    });
    image.src = `${import.meta.env.VUE_APP_API_URL}/icons/${fileName}`;
  });

const updateContainerTransform = (transformContainer, workerFrameState, mainThreadFrameState) => {
  if (workerFrameState) {
    const viewState = mainThreadFrameState.viewState;
    const renderedViewState = workerFrameState.viewState;
    const newCenter = viewState.center;
    const newResolution = viewState.resolution;
    const newRotation = viewState.rotation;
    const previousCenter = renderedViewState.center;
    const previousResolution = renderedViewState.resolution;
    const previousRotation = renderedViewState.rotation;
    const transform = create();
    const tInitX = !newRotation
      ? previousCenter[0] - newCenter[0]
      : (previousCenter[0] - newCenter[0]) * Math.cos(newRotation) +
        (previousCenter[1] - newCenter[1]) * Math.sin(newRotation);
    const tInitY = !newRotation
      ? newCenter[1] - previousCenter[1]
      : (previousCenter[0] - newCenter[0]) * Math.sin(newRotation) -
        (previousCenter[1] - newCenter[1]) * Math.cos(newRotation);
    compose(
      transform,
      tInitX / newResolution,
      tInitY / newResolution,
      previousResolution / newResolution,
      previousResolution / newResolution,
      newRotation - previousRotation,
      0,
      0
    );
    transformContainer.style.transform = toTransformString(transform);
  }
};

const offscreenCanvasLayerRenderer = (frameState, offscreenLayer, layerId, zoom, workerCallback) => {
  if (!offscreenLayer.container) {
    offscreenLayer.container = document.createElement('div');
    offscreenLayer.container.style.position = 'absolute';
    offscreenLayer.container.style.width = '100%';
    offscreenLayer.container.style.height = '100%';
    offscreenLayer.transformContainer = document.createElement('div');
    offscreenLayer.transformContainer.style.position = 'absolute';
    offscreenLayer.transformContainer.style.width = '100%';
    offscreenLayer.transformContainer.style.height = '100%';
    offscreenLayer.container.appendChild(offscreenLayer.transformContainer);
    offscreenLayer.canvas = document.createElement('canvas');
    offscreenLayer.canvas.style.position = 'absolute';
    offscreenLayer.canvas.style.left = '0';
    offscreenLayer.canvas.style.transformOrigin = 'top left';
    offscreenLayer.transformContainer.appendChild(offscreenLayer.canvas);
    offscreenLayer.context = offscreenLayer.canvas.getContext('2d', { willReadFrequently: true });
  }
  offscreenLayer.mainThreadFrameState = frameState;
  updateContainerTransform(
    offscreenLayer.transformContainer,
    offscreenLayer.workerFrameState,
    offscreenLayer.mainThreadFrameState
  );
  if (!offscreenLayer.rendering) {
    offscreenLayer.rendering = true;
    workerCallback({
      action: 'render',
      zoom: zoom,
      layerId,
      frameState: {
        layerIndex: 0,
        wantedTiles: {},
        usedTiles: {},
        viewHints: frameState.viewHints.slice(0),
        postRenderFunctions: [],
        viewState: {
          center: frameState.viewState.center.slice(0),
          resolution: frameState.viewState.resolution,
          rotation: frameState.viewState.rotation,
          zoom: frameState.viewState.zoom,
        },
        pixelRatio: frameState.pixelRatio,
        size: frameState.size.slice(0),
        extent: frameState.extent.slice(0),
        coordinateToPixelTransform: frameState.coordinateToPixelTransform.slice(0),
        pixelToCoordinateTransform: frameState.pixelToCoordinateTransform.slice(0),
        layerStatesArray: frameState.layerStatesArray.map(l => ({
          zIndex: l.zIndex,
          visible: l.visible,
          extent: l.extent,
          maxResolution: l.maxResolution,
          minResolution: l.minResolution,
          sourceState: l.sourceState,
          managed: l.managed,
        })),
      },
    });
  } else {
    frameState.animate = true;
  }
  return offscreenLayer.container;
};

export { updateContainerTransform, offscreenCanvasLayerRenderer, prefetchOffscreenLayerData };
