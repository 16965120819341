import i18n from '@/plugins/i18n';
import { transform, isObject, isString } from 'lodash';
/**
 * Utils are stored in JS file, because there might be some cases where they
 * will be required outside .vue file or before Vue instance had been
 * initialized (no `this` access).
 */

const capitalize = string => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const loweralize = string => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toLowerCase() + string.slice(1);
};

/**
 * Cast object values to given type.
 * @param {Object} object - Object which values has to be casted.
 * @param {Constructor} type - Type to which values will be casted.
 * @returns {Object} Return object with casted values.
 */
const castObjectValuesTypes = (object, type) =>
  Object.keys(object).reduce((total, current) => ({ ...total, ...{ [current]: type(object[current]) } }), {});

const validateUsername = (username, minLength) => {
  const re = new RegExp('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]{' + minLength + '}[\\w\\d .]*');
  return re.test(username);
};
const groupByProperty = (objectArray, property, subproperty, onlyCount = false) => {
  // Example:
  // const arr = [{ name: 'Name1', type: 'dog' }, { name: 'Name2', type: 'cat' }, { name: 'Name3', type: 'dog' }]
  // groupByProperty(arr, 'type')
  // output: {dog: [{ name: 'Name1', type: 'dog' }, { name: 'Name3', type: 'dog' }], cat: [{ name: 'Name2', type: 'cat' }]}
  return objectArray.reduce((acc, obj) => {
    let key = subproperty ? obj[property][subproperty] : obj[property];
    if (!acc[key]) {
      acc[key] = onlyCount ? 0 : [];
    }
    onlyCount ? (acc[key] += 1) : acc[key].push(obj);
    return acc;
  }, {});
};

const validateEmail = email => {
  //eslint-disable-next-line
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validatePhone = phoneNumber => {
  if (!phoneNumber) return false;
  const ph = phoneNumber.replace(' ', '').match(/^\d+$/);
  return ph ? ph[0].length != 9 : true;
};

const replaceSubstringInNestedObject = (obj, oldSubstring, newSubstring, omittedKey) => {
  return transform(obj, (result, value, key) => {
    let val = value;
    if (isString(val) && val.includes(oldSubstring) && key !== omittedKey) {
      val = val.replace(oldSubstring, newSubstring);
    }
    result[isString(key) ? key.replace(oldSubstring, newSubstring) : key] = isObject(val)
      ? replaceSubstringInNestedObject(val, oldSubstring, newSubstring)
      : val;
  });
};

const makeDeeps = (groupsDeeps, arr, deepLevel) => {
  const notElse = [];
  groupsDeeps[`deep ${deepLevel}`] = [];
  for (let g of arr) {
    if (g.parent === null) {
      groupsDeeps[`deep ${deepLevel}`].push(g);
    } else {
      if (deepLevel === 0) {
        notElse.push(g);
      } else if (groupsDeeps[`deep ${deepLevel - 1}`].find(gg => gg.id === g.parent)) {
        groupsDeeps[`deep ${deepLevel}`].push(g);
      } else {
        notElse.push(g);
      }
    }
  }
  return { groupsDeeps, notElse };
};

const getGroups = arr => {
  let deepLevel = 0;
  let groupsDeeps = {};
  let notElse = arr;
  while (notElse.length > 0) {
    const a = makeDeeps(groupsDeeps, notElse, deepLevel);
    groupsDeeps = a.groupsDeeps;
    notElse = a.notElse;
    deepLevel = deepLevel + 1;
  }
};

const getUrlQuery = queryName => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(queryName);
};

const arrayToObject = (array, key = 'id', value, mapAttributes) => {
  return array.reduce((total, current) => {
    if (mapAttributes)
      Object.entries(mapAttributes).forEach(([key1, key2]) => {
        current[key2] = current[key1];
        delete current[key1];
      });
    return { ...total, [current[key]]: value ? current[value] : current };
  }, {});
};

const objectToArray = (object, withKeyName = false) => {
  return Object.keys(object).map(key => {
    if (withKeyName) return { ...object[key], [withKeyName]: key };
    else return object[key];
  });
};

const isInt = value => {
  let x;
  return isNaN(value) ? !1 : ((x = parseFloat(value)), (0 | x) === x);
};

const getDecodedData = response => {
  const enc = new TextDecoder('utf-8');
  return enc.decode(response);
};

const getResponseData = (type, data) => {
  return type === 'arraybuffer' ? JSON.parse(getDecodedData(data)) : data;
};

/*
If error code is 500 return message with error code, otherwise return:
 - error_message - translated message if present,
 - error - message (not translated) if present,
 - i18n.t('errors.backend.requestGenerateError'), - fallback.
*/
const getErrorObject = error => {
  const object = {
    isShowSnackbar:
      (error && error.response && error.response.config && !error.response.config.skipDefaultErrorHandler) ||
      !error.response ||
      !error.request,
    returnErrorData:
      error && error.response && error.response.config && (error.response.config.returnErrorData ?? false),
    skipDefaultErrorHandler:
      error && error.response && error.response.config && error.response.config.skipDefaultErrorHandler,
  };
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const data = getResponseData(error.config.responseType, error.response.data);
    const { error_code, error_message, error: notTranslatedError } = data;
    const isUnhandled = error.response.status === 500;
    const errorObject = {
      ...(object.returnErrorData && { data }),
      status: error.response.status,
      rawMessage: notTranslatedError,
      message: isUnhandled
        ? i18n.t('errors.errorCode', { code: error_code })
        : error_message || notTranslatedError || i18n.t('errors.backend.requestGenerateError'),
      timeout: isUnhandled ? -1 : 5000,
    };
    const isLogout = notTranslatedError === 'AccessError.InvalidToken"';
    return { ...object, ...errorObject, isLogout, type: 'response' };
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return { ...object, message: i18n.t('errors.backend.requestError'), type: 'request' };
  }
  if (error.error_message) {
    return { ...object, message: error.error_message, type: 'error' };
  }
  // Something happened in setting up the request that triggered an Error
  return { ...object, message: i18n.t('errors.backend.requestGenerateError'), type: 'unidentified' };
};

const isValidUrl = (str, allowRelativePath = false) => {
  if (allowRelativePath && str?.length > 1 && str.startsWith('/')) return true;
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_:.~+{}#,]*)*' + // port and path
      '(\\?[;&a-z\\d%_:.~+=-@{}#,/]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

const isValidXYZ = str => {
  return ['{x}', '{y}', '{z}'].every(e => (str || '')?.toLocaleLowerCase().includes(e));
};

const getUrlHostname = url => {
  try {
    const { hostname } = new URL(url);
    return hostname;
  } catch (e) {
    // Handle invalid URLs
    return null;
  }
};

const saveFile = (blobUrl, name) => {
  const link = document.createElement('a');
  link.setAttribute('href', blobUrl);
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

const extractFilename = headers => {
  if (!headers['content-disposition']) return '';
  const diacriticMatch = headers['content-disposition'].match(/filename\*=(.+)''(.+)/i);
  const basicMatch =
    headers['content-disposition'].match(/filename="\s*(.+)"/i) ||
    headers['content-disposition'].match(/filename=\s*(.+)/i);
  return diacriticMatch ? decodeURI(diacriticMatch.slice(-1)[0]) : basicMatch.slice(-1)[0];
};

const saveFileFromBackend = (data, headers, customFilename) => {
  const headerFilename = extractFilename(headers).replace(/"/g, '');
  const headerFilenameExtension = headerFilename.split('.').pop();
  const name = customFilename
    ? [customFilename, ...(headerFilenameExtension ? [headerFilenameExtension] : [])].join('.')
    : headerFilename;
  const [type] = headers['content-type'].split(';');
  const blob = new Blob([data], { type });
  saveFile(URL.createObjectURL(blob), name);
};

const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const base64ToFile = (base64 = '', { name = 'file', autoExtention = false }) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) u8arr[n] = bstr.charCodeAt(n);
  let fileName = name;
  if (autoExtention) fileName = `${fileName}.${mime.split('/')[1]}`;
  return new File([u8arr], fileName, { type: mime });
};

const base64ToUrl = (base64 = '', { name = 'file', autoExtention = false }) => {
  const file = base64ToFile(base64, { name, autoExtention });
  return URL.createObjectURL(file);
};

const createImageFromArrayBuffer = (data, headers) => {
  const [type] = headers['content-type'].split(';');
  const blob = new Blob([data], { type });
  return window.URL.createObjectURL(blob);
};

const isHexColorLight = color => {
  return getHexColorBrightness(color) > 155;
};

const getHexColorBrightness = color => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  return (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
};
const getHexColorLighter = (hex, percent) => {
  hex = hex.replace(/^#/, '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  r = Math.min(255, Math.floor(r + ((255 - r) * percent) / 100));
  g = Math.min(255, Math.floor(g + ((255 - g) * percent) / 100));
  b = Math.min(255, Math.floor(b + ((255 - b) * percent) / 100));
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};

const formatTime = seconds => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  const parsedM = s > 30 ? m + 1 : m;
  if (h > 0) {
    return `${h} h ${parsedM} min`;
  } else {
    return `${parsedM} min`;
  }
};

const getRandomColor = () => {
  return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
};

const getHash = string => {
  let hash = 0,
    i,
    chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const sortObjectsByAttribute = (array, attribute, exclude, number = false) => {
  return array.sort((a, b) => {
    if (exclude && a[exclude.name] === exclude.value) {
      return -1;
    } else if (exclude && b[exclude.name] === exclude.value) {
      return 1;
    } else {
      return number
        ? b[attribute] - a[attribute]
        : a[attribute]?.toString().toLowerCase().localeCompare(b[attribute]?.toString().toLowerCase());
    }
  });
};

const moveElementInArray = (array, oldIndex, newIndex) => {
  const element = array[oldIndex];
  array.splice(oldIndex, 1);
  array.splice(newIndex, 0, element);
  return array;
};

const defaultHashGenerator = object => {
  return getHash(
    JSON.stringify(object)
    // objectToArray(object)
    //   .map(value => {
    //     if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
    //       return objectToArray(value);
    //     }
    //     return value;
    //   })
    //   .join('')
  );
};

/**
 * HOF to cache heavy computing results using closures concept.
 * https://scotch.io/tutorials/understanding-memoization-in-javascript#toc-a-functional-approach
 * @param {Function} f - Function which returned results will be cached.
 * @param {Function} hashGenerator - Function that generates hash used as key in cache object.
 * @param {Object} cacheObject - Object where to save cached function results, e.g Vuex object.
 * @returns {Any} Returns function f with ability to cache results.
 */
const memoizer = (f, { hashGenerator = defaultHashGenerator, cacheObject } = {}) => {
  const cache = cacheObject || {};
  return (argumentsObject = {}) => {
    const hashedArguments = hashGenerator(argumentsObject);
    if (cache[hashedArguments]) {
      return cache[hashedArguments];
    } else {
      const result = f(argumentsObject);
      cache[hashedArguments] = result;
      return result;
    }
  };
};

const vuexMemoizer = (f, { hashGenerator = defaultHashGenerator, getter, setter } = {}) => {
  return async (argumentsObject = {}) => {
    const hashedArguments = hashGenerator(argumentsObject);
    if (getter[hashedArguments]) {
      return getter[hashedArguments];
    } else {
      const result = await f(argumentsObject);
      setter({ value: result, key: hashedArguments });
      return result;
    }
  };
};

const importAll = (r, cacheObject = {}) => {
  return Object.entries(r).reduce((total, [key, value]) => {
    return { ...total, [key]: value.default };
  }, cacheObject);
};

const getIconByFileType = filename => {
  const extension = filename.split('.').pop().toLowerCase();
  if (extension === 'pdf') {
    return 'mdi-file-pdf-box';
  } else if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
    return 'mdi-image-size-select-actual';
  } else return 'mdi-file-document';
};

const getIconColorByFileType = filename => {
  const extension = filename.split('.').pop().toLowerCase();
  if (extension === 'pdf') {
    return '#dc1d1d';
  } else if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
    return 'var(--tw-color-iconIdle)';
  } else return 'primary';
};

const hasAnyValue = value => {
  return value !== null && value !== undefined && value !== '';
};

const filterNestedArray = (data, predicate, childrenKey) => {
  // if no data is sent in, return null, otherwise transform the data
  return data
    ? data.reduce((list, entry) => {
        let clone = null;
        if (entry[childrenKey]) {
          // if the object has childrens, filter the list of children
          const children = filterNestedArray(entry[childrenKey], predicate, childrenKey);
          if (children.length > 0) {
            // if any of the children matches, clone the parent object, overwrite
            // the children list with the filtered list
            clone = Object.assign({}, entry, { [childrenKey]: children });
          }
        } else if (predicate(entry)) {
          // if the object matches the filter, clone it as it is
          clone = Object.assign({}, entry);
        }
        // if there's a cloned object, push it to the output list
        clone && list.push(clone);
        return list;
      }, [])
    : null;
};

const flattenObject = obj => {
  const flattened = {};
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

const flattenStructure = (elements, childKey = 'elements', allLevels = false) => {
  return elements.reduce((total, current) => {
    if (current[childKey]) {
      return [...total, ...flattenStructure(current[childKey]), ...(allLevels ? [current] : [])];
    } else {
      return [...total, { ...current }];
    }
  }, []);
};

const getPolygonFromBbox = (bbox, bufferX, bufferY) => {
  const [x1, y1, x2, y2] = bbox;
  const SW = [x1 - bufferX, y1 - bufferY];
  const NW = [x1 - bufferX, y2 + bufferY];
  const NE = [x2 + bufferX, y2 + bufferY];
  const SE = [x2 + bufferX, y1 - bufferY];
  return [SW, NW, NE, SE, SW];
};

const getRelationalKey = ({ attribute, datasource, representation }) => `${attribute}@${datasource}@${representation}`;

const getFormattedMeasurementUnits = units => {
  let formattedMeasurementUnits = [];
  for (let measurementUnitGroup of Object.keys(units)) {
    formattedMeasurementUnits.push({ header: measurementUnitGroup });
    formattedMeasurementUnits = [
      ...formattedMeasurementUnits,
      ...units[measurementUnitGroup].map(mU => {
        return { id: mU.id, text: `${mU.name} [${mU.symbol}]` };
      }),
    ];
    formattedMeasurementUnits.push({ divider: true });
  }
  return formattedMeasurementUnits;
};

const findLayerByLayerId = (layers, layerId) => {
  let layer = layers.find(layer => layer.layer_id === layerId);
  if (!layer) {
    for (const group of layers) {
      if (group.layers) {
        layer = group.layers.find(layer => layer.layer_id === layerId);
        if (layer) {
          break;
        }
      }
    }
  }
  return layer;
};

const findLayerIndexByLayerId = (layers, layerId) => {
  let parentIndex = undefined;
  let index = layers.findIndex(layer => layer.layer_id === layerId);
  if (index < 0) {
    for (const [idx, group] of layers.entries()) {
      if (group.layers) {
        index = group.layers.findIndex(layer => layer.layer_id === layerId);
        if (index >= 0) {
          parentIndex = idx;
          break;
        }
      }
    }
  }
  return { parentIndex, index };
};

const findLayersByGroupIdx = (layers, groupIdx) => {
  return layers[groupIdx].layers;
};

const getFlatGroupsLayers = (elements = []) => {
  const groups = [];
  const layers = [];
  for (const element of elements) {
    if (element.layers) {
      groups.push(element);
      for (const layer of element.layers) {
        layers.push(layer);
      }
    } else {
      layers.push(element);
    }
  }
  return { groups, layers };
};

const getUniqueArrayValues = array => {
  return array.filter((value, index, values) => values.indexOf(value) === index);
};

const getDxfPointLayerByLineLayer = (value, layers = [], by = 'id') => {
  const lineLayerName = by === 'name' ? value : layers.find(layer => layer[by] === value)?.name;
  if (lineLayerName) {
    const pointLayerName = lineLayerName.replace(new RegExp('_dxf' + '$'), '_point_dxf');
    const pointLayer = layers.find(layer => layer.name === pointLayerName);
    if (pointLayer) {
      return pointLayer;
    }
  }
  return undefined;
};

const getArraySummarize = (array, key) => {
  return array.reduce((sum, value) => sum + (key ? value[key] || 0 : value), 0);
};

const hasStringWhitespaces = text => {
  const reg = new RegExp('[\\s]');
  return reg.test(text);
};

const getArrayOfNumbers = ({ start = 0, end = 100, step = 1 } = {}) => {
  const returnArray = [];
  let currentValue = start;
  while (currentValue <= end) {
    returnArray.push(currentValue);
    currentValue = currentValue + step;
  }
  return returnArray;
};

const filterObjectKeys = (obj = {}, { allowedKeys, notAllowedKeys } = {}) => {
  return Object.keys(obj)
    .filter(key => {
      return (!allowedKeys || allowedKeys.includes(key)) && !(notAllowedKeys && notAllowedKeys.includes(key));
    })
    .reduce((rObj, key) => {
      rObj[key] = obj[key];
      return rObj;
    }, {});
};

const filterArrayObjectsKeys = (arrayOfObj = [], { allowedKeys, notAllowedKeys } = {}) => {
  return arrayOfObj.map(obj => filterObjectKeys(obj, { allowedKeys, notAllowedKeys }));
};

const mapObjectKeys = (obj = {}, mapFunction = o => o) => {
  return Object.keys(obj).reduce((rObj, key) => {
    rObj[mapFunction(key)] = obj[key];
    return rObj;
  }, {});
};

const mapArrayObjectsKeys = (arrayOfObj = [], mapFunction = o => o) => {
  return arrayOfObj.map(obj => mapObjectKeys(obj, mapFunction));
};

const getBearingFromAzimuth = azimuth => {
  if (90 > azimuth >= 0) return azimuth;
  if (180 > azimuth >= 90) return 180 - azimuth;
  if (270 > azimuth >= 180) return azimuth - 180;
  return 360 - azimuth;
};

const getQueryValue = (queryValue = '', values = {}, { isFeature = false } = {}) => {
  const queryVars = [];
  const rxp = /{([^}]+)}/g;
  let curMatch;
  while ((curMatch = rxp.exec(queryValue))) {
    queryVars.push(curMatch[1]);
  }
  for (const queryVar of queryVars) {
    const elementValue = isFeature ? values.get(queryVar) : values[queryVar];
    queryValue = queryValue.replace(
      new RegExp('{' + queryVar + '}', 'gi'),
      elementValue || elementValue === 0 ? elementValue : ''
    );
  }
  return queryValue;
};

const getNewUniqueName = (arr, value) => {
  let uniqueStr = value;
  let counter = 1;
  while (arr.includes(uniqueStr)) {
    uniqueStr = `${value} (${counter})`;
    counter++;
  }
  return uniqueStr;
};

export {
  arrayToObject,
  capitalize,
  loweralize,
  castObjectValuesTypes,
  createImageFromArrayBuffer,
  extractFilename,
  filterNestedArray,
  flattenObject,
  flattenStructure,
  getErrorObject,
  getGroups,
  getHash,
  getHexColorBrightness,
  getHexColorLighter,
  getIconByFileType,
  getIconColorByFileType,
  getPolygonFromBbox,
  getRandomColor,
  getRelationalKey,
  getUrlQuery,
  groupByProperty,
  hasAnyValue,
  hasStringWhitespaces,
  importAll,
  isHexColorLight,
  isInt,
  isValidUrl,
  isValidXYZ,
  memoizer,
  objectToArray,
  formatTime,
  saveFile,
  saveFileFromBackend,
  fileToBase64,
  base64ToFile,
  base64ToUrl,
  sortObjectsByAttribute,
  validateEmail,
  validatePhone,
  validateUsername,
  vuexMemoizer,
  getFormattedMeasurementUnits,
  findLayerByLayerId,
  findLayerIndexByLayerId,
  findLayersByGroupIdx,
  getFlatGroupsLayers,
  getUniqueArrayValues,
  getDxfPointLayerByLineLayer,
  getArraySummarize,
  getArrayOfNumbers,
  getBearingFromAzimuth,
  filterObjectKeys,
  filterArrayObjectsKeys,
  mapObjectKeys,
  mapArrayObjectsKeys,
  replaceSubstringInNestedObject,
  moveElementInArray,
  getQueryValue,
  getUrlHostname,
  getNewUniqueName,
};
