export default {
  methods: {
    getIsRelationValueInvalid(params) {
      return params.colDef.isRelational && !params.colDef.relationalValues.find(v => v.text === params.value);
    },
    getRelationalValue(value, relationalValues) {
      if (Array.isArray(relationalValues)) {
        return relationalValues.find(rV => rV.value == value)?.text;
      }
      return relationalValues[value];
    },
    cellStyle(params) {
      if ([undefined, null].includes(params.value) || this.getIsRelationValueInvalid(params)) {
        return { opacity: 0.59, fontStyle: 'italic' };
      }
    },
    dateDifferenceColor(value) {
      const currentDateDifference = this.$dayjs(value).diff(this.$dayjs(), 'day');
      if (currentDateDifference > 0) {
        return this.$_colors.success;
      } else if (currentDateDifference < 0) {
        return this.$_colors.error;
      } else {
        return null;
      }
    },
    dateDifference(value) {
      const currentDateDifference = this.$dayjs(value).diff(this.$dayjs(), 'day');
      if (currentDateDifference > 0) {
        return `(${this.$i18n.t('sidebar.for')} ${currentDateDifference} ${this.$i18n.tc('sidebar.days', currentDateDifference)})`;
      } else if (currentDateDifference < 0) {
        const days = Math.abs(currentDateDifference);
        return `(${days} ${this.$i18n.tc('sidebar.days', days)} ${this.$i18n.t('default.ago')})`;
      } else {
        return null;
      }
    },
    valueFormatter(params, emptyValue = 'NULL') {
      const { value, colDef } = params;
      if (value === null || value === undefined) {
        return emptyValue;
      }
      if (this.getIsRelationValueInvalid(params)) {
        return `${this.$i18n.t('default.notInDict')} (${value})`;
      }
      const { name: dataTypeName } = params.colDef.dataType;
      if (dataTypeName === 'datetime') {
        return this.$dayjs(value).format('L, LT');
      } else if (dataTypeName === 'date') {
        return this.$dayjs(value).format('L');
      } else if (dataTypeName === 'time') {
        return this.$dayjs(value, 'HH:mm:ss').format('LTS');
      } else if (dataTypeName === 'boolean') {
        return value
          ? colDef.dataType.true || this.$i18n.t('inputs.true')
          : colDef.dataType.false || this.$i18n.t('inputs.false');
      }
      return value;
    },
    valueGetter(params) {
      if (!this.attributesMetadata) {
        return;
      }
      const { field, isRelational, relationalValues } = params.colDef;
      const value = params.data?.[field];
      const computedValue = isRelational ? this.getRelationalValue(value, relationalValues) || value : value;
      return computedValue;
    },
    getColumnHeader() {
      const self = this;
      function ColumnHeader() {}
      ColumnHeader.prototype.init = function (params) {
        this.eGui = document.createElement('div');
        this.eGui.className = 'ag-cell-label-container';
        this.eGui.setAttribute('role', 'presentation');
        if (params.enableSorting) {
          this.eGui.addEventListener('click', e => {
            if (!params.column.sort) {
              params.setSort('asc', e.shiftKey);
            } else if (params.column.sort === 'asc') {
              params.setSort('desc', e.shiftKey);
            } else if (params.column.sort === 'desc') {
              params.setSort('', e.shiftKey);
            }
          });
        }
        this.eGui.innerHTML = `
          <div ref="eLabel" class="ag-header-cell-label" role="presentation">
            <span ref="eText" class="ag-header-cell-text" style="padding-left: 16px">${params.displayName}</span>
            <span style="width:16px; min-width: 16px; max-width: 16px" class="header-information">
              <i class="v-icon mdi mdi-information header-information-i" style="font-size: 16px; display: none; color: ${self.$_colors.primary}"></i>
            </span>
            <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true" style="display: none">
              <i class="v-icon mdi mdi-arrow-up theme--light" style="font-size: 18px"></i>
            </span>
            <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true" style="display: none">
              <i class="v-icon mdi mdi-arrow-down theme-light" style="font-size: 18px;"></i>
            </span>
            </span>
          </div>
        `;
        if (!params.column.sort) {
          this.eGui.querySelector('.ag-sort-ascending-icon').style.display = 'none';
          this.eGui.querySelector('.ag-sort-descending-icon').style.display = 'none';
        } else if (params.column.sort === 'asc') {
          this.eGui.querySelector('.ag-sort-ascending-icon').style.display = 'block';
          this.eGui.querySelector('.ag-sort-descending-icon').style.display = 'none';
        } else if (params.column.sort === 'desc') {
          this.eGui.querySelector('.ag-sort-ascending-icon').style.display = 'none';
          this.eGui.querySelector('.ag-sort-descending-icon').style.display = 'block';
        }
        this.eGui.querySelector('.header-information-i').addEventListener('click', e => {
          e.stopPropagation();
          self.showStatsDialog(params.column.colId);
        });
      };

      ColumnHeader.prototype.getGui = function () {
        return this.eGui;
      };

      return ColumnHeader;
    },
    getColorSelectCell() {
      const self = this;
      function ColorSelectCell() {}
      ColorSelectCell.prototype.init = function (params) {
        const { value, valueFormatted } = params;
        const { dictValues, dictColors } = params.colDef;

        const index = dictValues.indexOf(value);
        const color = dictColors[index];
        const textColor = self.$_isHexColorLight(color || '#ffffff') ? '#202124' : 'white';

        this.eGui = document.createElement('div');
        this.eGui.style.cssText = `background-color: ${color || 'rgba(0,0,0,0)'};
          display: inline-flex; padding: 3px 8px; color: ${textColor}; font-weight: 500; border-radius: calc(infinity * 1px)`;
        // valueFormatter returns formatted value under valueFormatted key ('value' key value remains unformatted)
        this.eGui.innerHTML = valueFormatted;
      };

      ColorSelectCell.prototype.getGui = function () {
        return this.eGui;
      };

      return ColorSelectCell;
    },
    getStatusCell() {
      const self = this;
      function StatusCell() {}

      StatusCell.prototype.init = function (params) {
        const statusItem = self.statusesItems.find(status => status.value === params.valueFormatted);
        this.eGui = document.createElement('div');
        this.eGui.style.cssText = `background-color: ${statusItem ? statusItem.color : 'white'};
          display: inline-flex; padding: 3px 8px; border-radius: 4px; color: white; font-weight: 500`;
        // valueFormatter returns formatted value under valueFormatted key ('value' key value remains unformatted)
        this.eGui.innerHTML = params.valueFormatted;
      };

      StatusCell.prototype.getGui = function () {
        return this.eGui;
      };

      return StatusCell;
    },
    getCellRendererHandler(params) {
      const { field, dictColors, dictValues } = params.colDef;
      // if more renderers would appear in futrue create dict instead of multiplying if statements
      if (this.isCustomLayer && (field === 'status' || field === 'ZgloszenieStatus')) {
        return {
          component: 'StatusCell',
        };
      } else if (
        dictColors &&
        dictValues &&
        dictColors.length === dictValues.length &&
        dictColors.every(color => Boolean(color))
      ) {
        return {
          component: 'ColorSelectCell',
        };
      }
      return null;
    },
  },
};
