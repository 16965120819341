export default {
  data: () => ({
    documentation: {
      areaReport: 'knowledge-base/podstawowe-narzedzia/narzedzie-raport-o-terenie',
      communeGeneralPlan: 'knowledge-base/podstawowe-narzedzia/plan-ogolny-gminy',
      databoxData: 'knowledge-base/podstawowe-narzedzia/pobierz-dane-wektorowe-beta',
      ozeAreaSearch: 'knowledge-base/podstawowe-narzedzia/wyszukiwarka-terenow-inwestycyjnych', //?
      routingService: 'knowledge-base/podstawowe-narzedzia/narzedzie-wyznaczanie-tras',
      usableArea: 'knowledge-base/podstawowe-narzedzia/narzedzie-powierzchnia-uzytkowa',
    },
  }),
};
