import api from '@/services/api';
import { make } from 'vuex-pathify';
import { sortObjectsByAttribute } from '@/assets/js/utility';

const state = {
  filters: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getFilters({ commit, rootState }) {
    const r = await api.get('v2/layers-filters');
    const reducedFilters = Object.entries(
      (r.data.data ?? []).reduce((acc, item) => {
        const isOwner = rootState.users.currentUser.id === item.owner_id;
        const attr = item.predefined ? 'predefined' : isOwner ? 'owner' : 'shared';
        return { ...acc, [attr]: [...(acc[attr] ?? []), item] };
      }, {})
    ).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: sortObjectsByAttribute(value, 'name'),
      }),
      {}
    );
    commit('SET_FILTERS', reducedFilters);
  },
  async createFilter({ dispatch }, payload) {
    const r = await api.post('v2/layers-filters', payload);
    await dispatch('getFilters');
    return r.data.data;
  },
  async editFilter({ dispatch }, data) {
    const { filter_id, payload } = data;
    await api.put(`v2/layers-filters/${filter_id}`, payload);
    await dispatch('getFilters');
  },
  async deleteFilter({ dispatch }, filterId) {
    await api.delete(`v2/layers-filters/${filterId}`);
    await dispatch('getFilters');
  },
  async getSharedUsers(store, filter_id) {
    const r = await api.get(`v2/layers-filters/${filter_id}/shared_to`);
    return r.data.data.map(data => data.id);
  },
  async shareFilter(store, data) {
    const { filter_id, users } = data;
    await api.post(`v2/layers-filters/${filter_id}/share`, { user_ids: users });
  },
  async unshareFilter(store, data) {
    const { filter_id, users } = data;
    await api.post(`v2/layers-filters/${filter_id}/unshare`, { user_ids: users });
  },
  async togglePredefinedFilter({ dispatch }, data) {
    const { filter_id, predefined } = data;
    await api.post(`v2/layers-filters/${filter_id}/predefined/${predefined ? 'unset' : 'set'}`);
    await dispatch('getFilters');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
