<template>
  <v-menu
    ref="menu"
    v-model="isMenuVisible"
    :close-on-content-click="false"
    max-width="290px"
    min-width="290px"
    offset-y
    :disabled="disabled || $attrs.readonly"
    v-bind="menuProps"
  >
    <template #activator="{ on }">
      <div class="d-flex align-baseline" style="gap: 0.5rem">
        <slot v-if="!hideActivator" name="activator" :on="on">
          <data-input-text
            v-bind="{ prependInnerIcon: 'mdi-calendar', ...$props, ...$attrs }"
            v-model="formattedDate"
            v-on="{
              ...on,
              ...$_filterObjectKeys($listeners, { notAllowedKeys: ['input', 'change'] }),
              input: setInputValue,
            }"
            @blur="setValue"
            :rules="computedRules"
            class="included data-input-date"
          />
          <div
            v-if="showDifference && value && dateDifference(value)"
            cols="auto"
            :style="{ color: dateDifferenceColor(value) }"
            class="text-caption"
          >
            {{ dateDifference(value) }}
          </div>
        </slot>
      </div>
    </template>
    <input-card
      @save="setLoading => save(setLoading, customCallback)"
      @cancel="cancel"
      :is-save-disabled="isSaveDisabled"
      v-click-outside="{
        handler: onClickOutside,
        include,
        closeConditional,
      }"
    >
      <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        :class="{
          'custom-year-picker': isDatePickerYear,
          'custom-year-picker__novalue': isDatePickerYear && !value,
        }"
        v-bind="computedDatePickerProps"
        @click:year="saveDate"
        full-width
      ></v-date-picker>
    </input-card>
  </v-menu>
</template>

<script>
import inputCard from '@/mixins/inputCard';
import cells from '@/mixins/cells';

import DataInputText from '@/components/DataInputText';
import InputCard from '@/components/InputCard';

export default {
  name: 'DataInputDate',
  mixins: [inputCard, cells],
  components: {
    DataInputText,
    InputCard,
  },
  props: {
    value: {
      type: [Date, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    /* https://day.js.org/docs/en/display/format#list-of-localized-formats */
    localeDateFormat: {
      validatior: value => {
        return ['ll', 'l', 'LL', 'L'].indexOf(value) !== -1;
      },
      default: 'L',
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    showDifference: {
      type: Boolean,
      default: false,
    },
    timestamp: {
      type: Boolean,
      default: false,
    },
    customCallback: {
      type: Function,
      default: null,
    },
    hideActivator: {
      type: Boolean,
      default: false,
    },
    menuProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isMenuVisible: false,
    activeTab: 0,
    activePicker: null,
    formattedDate: undefined,
  }),
  computed: {
    isValidRule() {
      return (
        this.isValid ||
        this.$i18n.t('rules.requiredFormat', {
          format: this.dateFormat,
        })
      );
    },
    isDatePickerYear() {
      return this.datePickerProps?.type === 'year';
    },
    computedDatePickerProps() {
      if (!this.datePickerProps) return;
      let datePickerPropsProxy = JSON.parse(JSON.stringify(this.datePickerProps));
      if (datePickerPropsProxy.type === 'year') datePickerPropsProxy.type = 'date';
      return datePickerPropsProxy;
    },
    isValid() {
      const dayjsDate = this.$dayjs(this.formattedDate, this.dateFormat, import.meta.env.VUE_APP_I18N_LOCALE, true);
      return dayjsDate.isValid();
    },
    computedRules() {
      return [...(this.$attrs.rules || []), ...(this.formattedDate ? [this.isValidRule] : [])];
    },
    dateFormat() {
      return this.$dayjs.Ls[import.meta.env.VUE_APP_I18N_LOCALE].formats[this.localeDateFormat];
    },
    computedFormattedDate() {
      if (!this.value) {
        return;
      }
      const value = this.timestamp ? this.$dayjs.unix(this.value) : this.$dayjs(this.value);
      return value.format(this.localeDateFormat);
    },
    date: {
      get() {
        if (!this.value) {
          return this.$dayjs().toISOString().split('T')[0];
        }
        const value = this.timestamp ? this.$dayjs.unix(this.value).format('YYYY-MM-DD') : this.value;
        return value.split('T')[0];
      },
      set(nV) {
        this.$emit(
          'input',
          this.timestamp
            ? this.$dayjs(nV).unix()
            : this.datePickerProps?.type === 'month'
              ? this.$dayjs.utc(nV).toISOString().split('T')[0]
              : nV
        );
      },
    },
  },
  watch: {
    isMenuVisible(val) {
      if (this.isDatePickerYear) {
        val && setTimeout(() => (this.activePicker = 'YEAR'));
      }
    },
    computedFormattedDate: {
      immediate: true,
      handler(nV) {
        this.formattedDate = JSON.parse(JSON.stringify(nV || null));
      },
    },
  },
  methods: {
    saveDate(year) {
      this.date = `${year}-01-01`;
      this.$nextTick(() => {
        this.activePicker = 'YEAR';
      });
    },
    setValue() {
      if (this.isValid || !this.value) {
        this.$emit('input', this.getValue());
      }
    },
    setInputValue(v) {
      if (this.isValid || !v) {
        this.$emit('input', this.getValue());
      }
    },
    getValue() {
      if (!this.formattedDate) {
        return this.timestamp ? 0 : null;
      }
      const dayjsDate = this.$dayjs.utc(this.formattedDate, this.dateFormat, import.meta.env.VUE_APP_I18N_LOCALE, true);
      return this.timestamp
        ? this.$dayjs(this.formattedDate, this.dateFormat).unix()
        : dayjsDate.toISOString().split('T')[0];
    },
  },
};
</script>

<style scoped lang="scss">
.v-input {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.v-picker {
  border-radius: 0px;
}
::v-deep {
  .custom-year-picker {
    .v-date-picker-title__date {
      display: none !important;
    }
  }
  .custom-year-picker__novalue {
    .v-date-picker-years .active.primary--text {
      color: #000 !important;
    }
    .v-date-picker-years .active {
      font-size: unset !important;
    }
  }
  .v-input__slot {
    margin-bottom: 3px;
    input {
      padding: 0px;
    }
    label {
      top: 0px;
    }
  }
  .zdmInput {
    .v-input__slot {
      padding: 0 12px !important;
    }
  }
}
.data-input-date {
  ::v-deep {
    .v-text-field__slot {
      padding-left: 5px;
    }
    .v-input__icon--prepend-inner {
      .v-icon {
        color: map-get($colors, 'italic') !important;
      }
    }
    .v-text-field__slot label {
      padding-left: 3px;
    }
  }
}
</style>
