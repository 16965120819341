import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import Main from '@/views/MainView';

const TableHandler = () => import('@/components/TableHandler');

import { beforeModuleRouteEnter, beforeRouteEnter, beforeMainPathEnter } from '@/assets/js/routerUtils';
import { defaultAdminTab } from '@/assets/js/variables';

Vue.use(VueRouter);

const routes = [
  {
    path: '/dashboard',
    meta: {
      requiresAuth: true,
      view: true,
    },
    component: () => import('@/views/DashboardView'),
    beforeEnter: (to, from, next) => {
      beforeRouteEnter(to, from, next, () => next(), {
        notPermittedNext: 'map',
        additionalModulesNames: ['PAGE_TABLE_MODULE'],
      });
    },
    children: [
      {
        path: '',
        meta: {
          view: true,
        },
        name: 'dashboard',
        component: () => import('@/components/DashboardPanel'),
      },
      {
        path: ':lid',
        name: 'dashboardLayer',
        component: () => import('@/components/DashboardPanel'),
        children: [
          {
            path: ':fid',
            name: 'dashboardFeature',
            meta: { parent: 'dashboardLayer' },
            components: {
              card: () => import('@/components/DashboardPanelFeatureCard'),
            },
          },
        ],
      },
    ],
  },
  {
    path: '/map',
    name: 'map',
    meta: {
      requiresAuth: true,
      view: true,
    },
    component: Main,
    beforeEnter: (to, from, next) => {
      beforeRouteEnter(to, from, next, () => next());
    },
    children: [
      {
        path: 'layers/:lid',
        name: 'layerTable',
        meta: {
          child: 'layerFeature',
        },
        components: {
          table: TableHandler,
        },
      },
      {
        path: 'layers/:lid/:fid',
        name: 'layerFeature',
        meta: {
          parent: 'layerTable',
        },
        components: {
          table: TableHandler,
          sidebar: () => import('@/components/FeatureCardHandler'),
        },
      },
      {
        path: 'field_calculator/:lid',
        name: 'fieldCalculator',
        meta: {
          parent: 'layerTable',
        },
        components: {
          table: TableHandler,
          sidebar: () => import('@/components/FieldCalculatorWrapper'),
        },
      },
      {
        path: 'attributes_painter_features/:lid',
        name: 'attributesPainterFeatures',
        meta: {
          parent: 'layerTable',
        },
        components: {
          table: TableHandler,
          sidebar: () => import('@/components/AttributesPainterFeatures'),
        },
      },
      {
        path: 'attributes_painter/:lid',
        name: 'attributesPainter',
        meta: {
          parent: 'layerTable',
        },
        components: {
          table: TableHandler,
          sidebar: () => import('@/components/AttributesPainter'),
        },
      },
      {
        path: 'modeling_results/:netType/:scenarioId/:type?/:elementId?/profile',
        name: 'sewerProfile',
        props: {
          sidebar: true,
        },
        components: {
          sidebar: () => import('@/components/SewerProfile'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'modelingResults',
            additionalModulesNames: ['SEWER_MODELING'],
            settingsNames: ['sewer_modeling_module_enabled'],
          }),
      },
      {
        path: 'modeling_results/:netType',
        name: 'modelingResults',
        props: {
          sidebar: true,
        },
        children: [
          {
            path: ':scenarioId',
            name: 'modelingResultsScenario',
            children: [
              {
                path: ':type/:elementId',
                name: 'modelingResultsFeature',
                components: {
                  sidebar: () => import('@/components/ModelingResults'),
                },
              },
            ],
            components: {
              sidebar: () => import('@/components/ModelingResults'),
            },
          },
        ],
        components: {
          sidebar: () => import('@/components/ModelingResults'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SEWER_MODELING', 'WATER_MODELING'],
            settingsNames: ['sewer_modeling_module_enabled', 'water_modeling_module_enabled'],
            oneModuleRequired: true,
            oneSettingRequired: true,
          }),
      },
      {
        path: 'identification/:x/:y/:z',
        name: 'identification',
        components: {
          sidebar: () => import('@/components/Identification'),
        },
      },
      {
        path: 'coordinates',
        name: 'coordinates',
        components: {
          sidebar: () => import('@/components/Coordinates'),
        },
      },
      {
        path: 'parcel_report',
        name: 'parcelReport',
        components: {
          sidebar: () => import('@/components/ParcelReport'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['PARCELS'],
            settingsNames: ['parcels_module_enabled'],
          }),
      },
      {
        path: 'dxf',
        name: 'dxf',
        components: {
          sidebar: () => import('@/components/ImportDxf'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['DXF_IMPORT', 'ZDM_DATA'],
            oneModuleRequired: true,
            oneSettingRequired: true,
          }),
      },
      {
        path: ':lid/adding_lamp',
        name: 'addingLamp',
        meta: {
          parent: 'layerTable',
        },
        components: {
          table: TableHandler,
          sidebar: () => import('@/components/AddingLamp'),
        },
      },
      {
        path: 'nmt',
        name: 'nmtMap',
        components: {
          sidebar: () => import('@/components/NmtSidebar'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['DEM'],
            settingsNames: ['dem_module_enabled'],
          }),
      },
      {
        path: 'selection_report',
        name: 'zdmSelectionReport',
        components: {
          sidebar: () => import('@/components/ZDMSelectionReportSidebar'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['ZDM_REPORTS'],
            settingsNames: ['zdm_reports_module_enabled'],
          }),
      },
      {
        path: 'editor',
        name: 'zdmEditor',
        components: {
          sidebar: () => import('@/components/ZDMEditor'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['ZDM_SESSIONS', 'ZDM_DATA'],
            settingsNames: ['zdm_sessions_module_enabled'],
          }),
      },
      {
        path: 'zdm_custom_reports',
        name: 'zdmCustomReports',
        components: {
          sidebar: () => import('@/components/ZDMCustomReportsSidebar'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'zdmCustomReportsLayer',
            components: {
              sidebar: () => import('@/components/ZDMCustomReportsSidebar'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModuleName: ['ZDM_REPORTS'],
            settingName: ['zdm_reports_module_enabled'],
          }),
      },
      {
        path: 'water_hammer',
        name: 'waterHammer',
        components: {
          sidebar: () => import('@/components/WaterHammer'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_HAMMER'],
            settingsNames: ['water_hammer_module_enabled'],
          }),
      },
      {
        path: 'sewer_data_profile',
        name: 'sewerDataProfile',
        components: {
          sidebar: () => import('@/components/SewerDataProfile'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SEWER_ANALYSIS'],
            settingsNames: ['sewer_analysis_module_enabled'],
          }),
      },
      {
        path: 'sewer_data_inverted_gradient',
        name: 'sewerDataInvertedGradient',
        components: {
          sidebar: () => import('@/components/SewerDataInvertedGradient'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'sewerDataInvertedGradientLayer',
            components: {
              sidebar: () => import('@/components/SewerDataInvertedGradient'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SEWER_ANALYSIS'],
            settingsNames: ['sewer_analysis_module_enabled'],
          }),
      },
      {
        path: 'sewer_data_downstream',
        name: 'sewerDataDownstream',
        components: {
          sidebar: () => import('@/components/SewerDataDownstream'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'sewerDataDownstreamLayer',
            components: {
              sidebar: () => import('@/components/SewerDataDownstream'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SEWER_ANALYSIS'],
            settingsNames: ['sewer_analysis_module_enabled'],
          }),
      },
      {
        path: 'sewer_data_upstream',
        name: 'sewerDataUpstream',
        components: {
          sidebar: () => import('@/components/SewerDataUpstream'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'sewerDataUpstreamLayer',
            components: {
              sidebar: () => import('@/components/SewerDataUpstream'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SEWER_ANALYSIS'],
            settingsNames: ['sewer_analysis_module_enabled'],
          }),
      },
      {
        path: 'congestion_analysis',
        name: 'congestionAnalysis',
        components: {
          sidebar: () => import('@/components/CongestionAnalysis'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SEWER_ANALYSIS'],
            settingsNames: ['sewer_analysis_module_enabled', 'sewer_congestion_enabled'],
          }),
      },
      {
        path: 'transmission_easements',
        name: 'transmission_easements',
        components: {
          sidebar: () => import('@/components/TransmissionEasements'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['TRANSMISSION_EASEMENT'],
            settingsNames: ['transmission_easement_module_enabled'],
          }),
      },
      {
        path: 'transmission_easement/:fid',
        name: 'transmission_easement',
        meta: {
          parent: 'transmission_easements',
        },
        components: {
          sidebar: () => import('@/components/TransmissionEasement'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['TRANSMISSION_EASEMENT'],
            settingsNames: ['transmission_easement_module_enabled'],
          }),
      },
      {
        path: 'transmission_easements2',
        name: 'transmission_easements2',
        components: {
          sidebar: () => import('@/components/TransmissionEasementsSummary'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['TRANSMISSION_EASEMENT_2_MODULE'],
            settingsNames: ['transmission_easement_2_module_enabled'],
          }),
      },
      {
        path: 'failures',
        name: 'failures',
        components: {
          sidebar: () => import('@/components/Failures'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['FAILURES'],
            settingsNames: ['failures_module_enabled', 'failures_dispatcher_enabled'],
          }),
      },
      {
        path: 'failures_summary',
        name: 'failuresTwo',
        components: {
          sidebar: () => import('@/components/FailuresSummary'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['FAILURES_2'],
            settingsNames: ['failures_2_module_enabled'],
          }),
      },
      {
        path: 'locks_analysis',
        name: 'locksAnalysis',
        components: {
          sidebar: () => import('@/components/LocksAnalysisInfo'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'locksAnalysisLayer',
            components: {
              sidebar: () => import('@/components/LocksAnalysisInfo'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['FAILURES'],
            settingsNames: ['failures_module_enabled', 'failures_cutoff_enabled'],
          }),
      },
      {
        path: 'failures/:fid',
        name: 'failure',
        meta: {
          parent: 'failures',
        },
        components: {
          sidebar: () => import('@/components/Failure'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['FAILURES'],
            settingsNames: ['failures_module_enabled', 'failures_dispatcher_enabled'],
          }),
      },
      {
        path: 'tasks',
        name: 'tasks',
        components: {
          sidebar: () => import('@/components/Tasks'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['TASKS'],
            settingsNames: ['tasks_module_enabled'],
          }),
      },
      {
        path: 'tasks/:fid',
        name: 'task',
        meta: {
          parent: 'tasks',
        },
        components: {
          sidebar: () => import('@/components/Task'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['TASKS'],
            settingsNames: ['tasks_module_enabled'],
          }),
      },
      {
        path: 'inspection/:fid',
        name: 'hydrantInspection',
        components: {
          sidebar: () => import('@/components/HydrantInspection'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['HYDRANTS_INSPECTIONS'],
            settingsNames: ['hydrants_inspections_module_enabled'],
          }),
      },
      {
        path: 'graph_generation',
        name: 'graphGeneration',
        components: {
          sidebar: () => import('@/components/GraphGeneration'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_MODELING'],
            settingsNames: ['water_modeling_module_enabled'],
          }),
      },
      {
        path: 'sewer_graph_generation',
        name: 'sewerGraphGeneration',
        components: {
          sidebar: () => import('@/components/SewerGraphGeneration'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SEWER_MODELING'],
            settingsNames: ['swmm_inp_export_enabled'],
          }),
      },
      {
        path: 'modeling_network_check',
        name: 'modelingNetworkCheck',
        components: {
          sidebar: () => import('@/components/ModelingNetworkCheck'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_MODELING'],
            settingsNames: ['water_modeling_module_enabled'],
          }),
      },
      {
        path: 'modeling_patterns',
        name: 'modelingPatterns',
        components: {
          sidebar: () => import('@/components/ModelingPatterns'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_MODELING'],
            settingsNames: ['water_modeling_module_enabled'],
          }),
      },
      {
        path: 'modeling_curves',
        name: 'modelingCurves',
        components: {
          sidebar: () => import('@/components/ModelingCurves'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_MODELING'],
            settingsNames: ['water_modeling_module_enabled'],
          }),
      },
      {
        path: 'modeling_rules',
        name: 'modelingRules',
        components: {
          sidebar: () => import('@/components/ModelingRules'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_MODELING'],
            settingsNames: ['water_modeling_module_enabled'],
          }),
      },
      {
        path: 'calibration_files_generation',
        name: 'calibrationFilesGeneration',
        components: {
          sidebar: () => import('@/components/CalibrationFilesGeneration'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_MODELING'],
            settingsNames: ['water_modeling_module_enabled', 'epanet_calibration_data_enabled'],
          }),
      },
      {
        path: 'fire_simulation',
        name: 'fireSimulation',
        components: {
          sidebar: () => import('@/components/FireSimulation'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'fireSimulationLayer',
            components: {
              sidebar: () => import('@/components/FireSimulation'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_MODELING'],
            settingsNames: ['water_modeling_module_enabled', 'epanet_fire_fighting_enabled'],
          }),
      },
      {
        path: 'quick_check',
        name: 'quickCheck',
        components: {
          sidebar: () => import('@/components/QuickCheck'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_NETWORK_DEVELOPMENT_MODULE'],
            settingsNames: ['water_network_development_module_enabled', 'epanet_junctions_demand_enabled'],
          }),
      },
      {
        path: 'net_development_planning',
        name: 'developmentPlanning',
        components: {
          sidebar: () => import('@/components/NetDevelopmentPlanning'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_NETWORK_DEVELOPMENT_MODULE'],
            settingsNames: ['water_network_development_module_enabled', 'epanet_development_planning_enabled'],
          }),
      },
      {
        path: 'folders',
        name: 'folders',
        components: {
          sidebar: () => import('@/components/Folders'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['FOLDERS'],
            settingsNames: ['folders_module_enabled'],
          }),
      },
      {
        path: 'folders/:fid',
        name: 'folder',
        meta: {
          parent: 'folders',
        },
        components: {
          sidebar: () => import('@/components/Folder'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['FOLDERS'],
            settingsNames: ['folders_module_enabled'],
          }),
      },
      {
        path: 'legalization',
        name: 'legalization',
        components: {
          sidebar: () => import('@/components/Legalization'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['BILLINGS'],
            settingsNames: ['billings_module_enabled', 'billings_legalization_enabled'],
          }),
      },
      {
        path: 'legalization/:fid',
        name: 'legalizationInfo',
        components: {
          sidebar: () => import('@/components/FeatureCardHandler'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['BILLINGS'],
            settingsNames: ['billings_module_enabled', 'billings_legalization_enabled'],
          }),
      },
      {
        path: 'water_dissolution_dma',
        name: 'waterDissolutionDma',
        components: {
          sidebar: () => import('@/components/WaterDissolutionDma'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['BILLINGS'],
            settingsNames: ['billings_module_enabled', 'billings_water_dissolution_dma_zone_enabled'],
          }),
      },
      {
        path: 'water_dissolution_dma/:fid',
        name: 'waterDissolutionDmaInfo',
        components: {
          sidebar: () => import('@/components/FeatureCardHandler'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['BILLINGS'],
            settingsNames: ['billings_module_enabled', 'billings_water_dissolution_dma_zone_enabled'],
          }),
      },
      {
        path: 'water_dissolution_watermeters',
        name: 'waterDissolutionWatermeters',
        components: {
          sidebar: () => import('@/components/WaterDissolutionWatermeters'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['BILLINGS'],
            settingsNames: ['billings_module_enabled', 'billings_water_dissolution_watermeters_enabled'],
          }),
      },
      {
        path: 'water_dissolution_watermeters/:fid',
        name: 'waterDissolutionWatermetersInfo',
        components: {
          sidebar: () => import('@/components/FeatureCardHandler'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['BILLINGS'],
            settingsNames: ['billings_module_enabled', 'billings_water_dissolution_watermeters_enabled'],
          }),
      },
      {
        path: 'billing_anomaly_detection',
        name: 'billingAnomalyDetection',
        components: {
          sidebar: () => import('@/components/BillingAnomalyDetection'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['BILLINGS'],
            settingsNames: ['billings_module_enabled', 'billings_anomaly_detection_enabled'],
          }),
      },
      {
        path: 'billing_anomaly_detection/:fid',
        name: 'billingAnomalyDetectionInfo',
        components: {
          sidebar: () => import('@/components/FeatureCardHandler'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['BILLINGS'],
            settingsNames: ['billings_module_enabled', 'billings_anomaly_detection_enabled'],
          }),
      },
      {
        path: 'investments',
        name: 'investments',
        components: {
          sidebar: () => import('@/components/Investments'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['INVESTMENTS'],
            settingsNames: ['investments_module_enabled'],
          }),
      },
      {
        path: 'investments/:fid',
        name: 'investment',
        meta: {
          parent: 'investments',
        },
        components: {
          sidebar: () => import('@/components/Investment'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['INVESTMENTS'],
            settingsNames: ['investments_module_enabled'],
          }),
      },
      {
        path: 'failure_report',
        name: 'zdmFailureReport',
        components: {
          sidebar: () => import('@/components/ZDMFailureReport'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['ZDM_FAILURES_REPORT'],
            settingsNames: ['zdm_failures_report_module_enabled'],
          }),
      },
      {
        path: 'info',
        name: 'information',
        components: {
          sidebar: () => import('@/components/InformationMap'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['INFO'],
          }),
      },
      {
        path: 'zdm_failures',
        name: 'zdm_failures',
        components: {
          sidebar: () => import('@/components/ZDMFailures'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['ZDM_FAILURES'],
            settingsNames: ['zdm_failures_module_enabled'],
          }),
      },
      {
        path: 'zdm_failures/:fid',
        name: 'zdm_failure',
        components: {
          sidebar: () => import('@/components/ZDMFailure'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['ZDM_FAILURES'],
            settingsNames: ['zdm_failures_module_enabled'],
          }),
      },
      {
        path: 'uldk',
        name: 'uldk',
        components: {
          sidebar: () => import('@/components/UldkInfo'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['PARCELS'],
            settingsNames: ['parcels_module_enabled'],
          }),
      },
      {
        path: 'parcels',
        name: 'parcels',
        components: {
          sidebar: () => import('@/components/ParcelsSearch'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['PARCELS_SEARCH'],
          }),
      },
      {
        path: 'prints',
        name: 'prints',
        components: {
          sidebar: () => import('@/components/Prints'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['MAP_PRINT'],
          }),
      },
      {
        path: 'printing',
        name: 'printing',
        components: {
          sidebar: () => import('@/components/Prints'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['PRINTING_2_MODULE'],
            settingsNames: ['printing_2_module_enabled'],
          }),
      },
      {
        path: 'failure_announcements',
        name: 'failureAnnouncements',
        components: {
          sidebar: () => import('@/components/FailureAnnouncements'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['FAILURE_ANNOUNCEMENTS'],
          }),
      },
      {
        path: 'measurements',
        name: 'measurementsModule',
        components: {
          sidebar: () => import('@/components/Measurements'),
        },
      },
      {
        path: 'modernization/:lid',
        name: 'modernization',
        components: {
          sidebar: () => import('@/components/Modernization'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['ZDM_DATA'],
          }),
      },
      {
        path: 'location_lab_reports',
        name: 'locationLabReports',
        components: {
          sidebar: () => import('@/components/LocationLabReports'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'locationLabReportsLayer',
            components: {
              sidebar: () => import('@/components/LocationLabReports'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['LOCATION_LAB'],
            settingsNames: ['location_lab_module_enabled'],
          }),
      },
      {
        path: 'alarms',
        name: 'alarms',
        components: {
          sidebar: () => import('@/components/Alarms'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SCADA_ALARMS'],
            settingsNames: ['scada_alarms_module_enabled'],
          }),
      },
      {
        path: 'location_selection',
        name: 'locationSelection',
        components: {
          sidebar: () => import('@/components/LocationSelection'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'locationSelectionLayer',
            components: {
              sidebar: () => import('@/components/LocationSelection'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SELECT_BY_LOCATION_MODULE'],
          }),
      },
      {
        path: 'custom_reports',
        name: 'CUSTOM_REPORTS',
        components: {
          sidebar: () => import('@/components/CustomReports'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['CUSTOM_REPORTS'],
            settingsNames: ['custom_reports_module_enabled'],
          }),
      },
      {
        path: 'water_balance_dma',
        name: 'waterBalanceDma',
        components: {
          sidebar: () => import('@/components/WaterBalanceDma'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_BALANCE_MODULE'],
            settingsNames: ['water_balance_module_enabled', 'water_balance_dma_enabled'],
          }),
      },
      {
        path: 'water_balance_iwa',
        name: 'waterBalanceIwa',
        components: {
          sidebar: () => import('@/components/WaterBalanceIwa'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_BALANCE_MODULE'],
            settingsNames: ['water_balance_module_enabled', 'water_balance_dma_enabled'],
          }),
      },
      {
        path: 'water_balance_balance',
        name: 'waterBalanceZone',
        components: {
          sidebar: () => import('@/components/WaterBalanceZone'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_BALANCE_MODULE'],
            settingsNames: ['water_balance_module_enabled', 'water_balance_balance_enabled'],
          }),
      },
      {
        path: 'upstream_sewage_volume',
        name: 'upstreamSewageVolume',
        components: {
          sidebar: () => import('@/components/UpstreamSewageVolume'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'upstreamSewageVolumeLayer',
            components: {
              sidebar: () => import('@/components/UpstreamSewageVolume'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SEWER_ANALYSIS'],
            settingsNames: ['sewer_analysis_module_enabled', 'sewer_upstream_sewage_volume_enabled'],
          }),
      },
      {
        path: 'upstream_sewage_outpouring',
        name: 'upstreamSewageOutpouring',
        components: {
          sidebar: () => import('@/components/UpstreamSewageOutpouring'),
          table: TableHandler,
        },
        children: [
          {
            path: ':lid',
            name: 'upstreamSewageOutpouringLayer',
            components: {
              sidebar: () => import('@/components/UpstreamSewageOutpouring'),
            },
          },
        ],
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['SEWER_ANALYSIS'],
            settingsNames: ['sewer_analysis_module_enabled', 'sewer_upstream_sewage_outpouring_enabled'],
          }),
      },
      {
        path: 'buffer/:lid',
        name: 'buffer',
        components: {
          sidebar: () => import('@/components/Buffer'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['BUFFER'],
            settingsNames: ['buffer_module_enabled'],
          }),
      },
      {
        path: 'circular_sector_selection/:lid',
        name: 'circularSectorSelection',
        components: {
          sidebar: () => import('@/components/CircularSectorSelection'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['CIRCULAR_SECTOR_SELECT'],
          }),
      },
      {
        path: 'geonotes',
        name: 'geonotes',
        components: {
          sidebar: () => import('@/components/GeonotesSummary'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['GEONOTES_MODULE'],
            settingsNames: ['geonotes_module_enabled'],
          }),
      },
      {
        path: 'unassigned_watermeters/:lid',
        name: 'unassignedWatermeters',
        components: {
          sidebar: () => import('@/components/UnassignedWatermeters'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['UNASSIGNED_WATERMETERS_MODULE'],
            settingsNames: ['unassigned_watermeters_module_enabled'],
          }),
      },
      {
        path: 'water_modeling_quality/:netType',
        name: 'waterModelingQuality',
        components: {
          sidebar: () => import('@/components/WaterModelingQuality'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            settingsNames: ['epanet_water_quality_enabled'],
          }),
      },
      {
        path: 'water_network_profile',
        name: 'waterNetworkProfile',
        components: {
          sidebar: () => import('@/components/WaterDataProfile'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['WATER_NETWORK_PROFILE'],
            settingsNames: ['water_network_profile_module_enabled'],
          }),
      },
      {
        path: 'infrastructure_collisions',
        name: 'infrastructureCollisions',
        components: {
          sidebar: () => import('@/components/InfrastructureCollisions'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['INFRASTRUCTURE_COLLISIONS'],
            settingsNames: ['infrastructure_collisions_module_enabled'],
          }),
      },
      {
        path: 'fresnel_zone',
        name: 'fresnelZone',
        components: {
          sidebar: () => import('@/components/FresnelZone'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['FRESNEL_AREA'],
            settingsNames: ['fresnel_area_module_enabled'],
          }),
      },
      {
        path: 'antenna_signal_determination/:lid',
        name: 'antennaSignalDetermination',
        components: {
          sidebar: () => import('@/components/AntennaSignalDetermination'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['ANTENNA_SIGNAL_BEARING_MODULE'],
            settingsNames: ['antenna_signal_bearing_enabled'],
          }),
      },
      {
        path: 'precise_digitizing/:lid',
        name: 'preciseDigitizing',
        meta: {
          parent: 'layerTable',
        },
        components: {
          sidebar: () => import('@/components/PreciseDigitizing'),
          table: TableHandler,
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['ADVANCED_DIGITIZING'],
            settingsNames: ['precise_digitizing_enabled'],
          }),
      },
      {
        path: 'visibility_analysis',
        name: 'visibilityAnalysis',
        components: {
          sidebar: () => import('@/components/VisibilityAnalysis'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['VIEWSHED'],
            settingsNames: ['viewshed_area_module_enabled'],
          }),
      },
      {
        path: 'data_trees/:id?',
        name: 'dataTrees',
        components: {
          sidebar: () => import('@/components/DataTrees'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['DATA_TREE_MODULE'],
            settingsNames: ['data_tree_module_enabled'],
          }),
      },
      {
        path: 'location_analysis',
        name: 'locationAnalysis',
        components: {
          sidebar: () => import('@/components/LocationAnalysis'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['LOCATION_ANALYSIS_MODULE'],
          }),
      },
      {
        path: 'street_lamp_inventory',
        name: 'streetLampInventory',
        components: {
          sidebar: () => import('@/components/StreetLampInventory'),
        },
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            notPermittedNext: 'map',
            additionalModulesNames: ['ILS_SESSIONS_MODULE'],
            settingsNames: ['street_lamp_inventory_module_enabled'],
          }),
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/AdminView'),
    meta: {
      requiresAuth: true,
      view: true,
    },
    beforeEnter: (to, from, next) => {
      beforeRouteEnter(to, from, next, r => {
        if (r.is_admin) {
          next();
        } else {
          next({ name: 'map' });
        }
      });
    },
    children: [
      {
        path: 'app',
        name: 'appSettings',
        component: () => import('@/components/AppSettings'),
      },
      {
        path: 'login',
        name: 'loginSettings',
        component: () => import('@/components/LoginSettings'),
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/components/Users'),
      },
      {
        path: 'vehicles',
        name: 'VEHICLES',
        component: () => import('@/components/Vehicles'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['VEHICLES'],
            configRoute: true,
          }),
      },
      {
        path: 'automatic_digitization',
        name: 'automaticDigitizationSettings',
        component: () => import('@/components/AutomaticDigitizationSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['AUTOMATIC_DIGITIZATION'],
            configRoute: true,
          }),
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('@/components/NotificationsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['NOTIFICATIONS_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'nmt',
        name: 'NMT',
        component: () => import('@/components/NmtSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['DEM'],
            configRoute: true,
          }),
      },
      {
        path: 'scada',
        name: 'SCADA',
        component: () => import('@/components/ScadaAdmin'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['SCADA'],
            configRoute: true,
          }),
      },
      {
        path: 'failures',
        name: 'FAILURES',
        component: () => import('@/components/FailuresSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['FAILURES'],
            configRoute: true,
          }),
      },
      {
        path: 'failures_two',
        name: 'FAILURES2',
        component: () => import('@/components/FailuresTwoSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['FAILURES_2'],
            configRoute: true,
          }),
      },
      {
        path: 'transmission_easement',
        name: 'TRANSMISSION_EASEMENT',
        component: () => import('@/components/TransmissionEasementSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['TRANSMISSION_EASEMENT'],
            configRoute: true,
          }),
      },
      {
        path: 'transmission_easement2',
        name: 'TRANSMISSION_EASEMENT2',
        component: () => import('@/components/TransmissionEasementTwoSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['TRANSMISSION_EASEMENT_2_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'folders',
        name: 'FOLDERS',
        component: () => import('@/components/FoldersSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['FOLDERS'],
            configRoute: true,
          }),
      },
      {
        path: 'investments',
        name: 'INVESTMENTS',
        component: () => import('@/components/InvestmentsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['INVESTMENTS'],
            configRoute: true,
          }),
      },
      {
        path: 'integrations',
        name: 'integrations',
        component: () => import('@/components/IntegrationsSettings'),
      },
      {
        path: 'superadmin',
        name: 'superadmin',
        component: () => import('@/components/SuperAdmin'),
        beforeEnter: (to, from, next) => {
          if (store.get('users/currentUser@is_superadmin')) {
            next();
          } else {
            next({ name: defaultAdminTab });
          }
        },
      },
      {
        path: 'dictionaries',
        name: 'dictionaries',
        component: () => import('@/components/DictionariesSettings'),
      },
      {
        path: 'data_sources',
        name: 'dataSources',
        props: true,
        component: () => import('@/components/AdminDataDatasources'),
      },
      {
        path: 'layers',
        name: 'layers',
        props: true,
        component: () => import('@/components/AdminDataLayers'),
      },
      {
        path: 'masterplan_streets',
        name: 'MASTERPLAN_STREETS',
        props: true,
        component: () => import('@/components/MasterplanStreetsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MASTERPLAN_STREETS'],
            configRoute: true,
          }),
      },
      {
        path: 'masterplan_squares',
        name: 'MASTERPLAN_SQUARES',
        props: true,
        component: () => import('@/components/MasterplanSquaresSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MASTERPLAN_SQUARES'],
            configRoute: true,
          }),
      },
      {
        path: 'masterplan_illumination',
        name: 'MASTERPLAN_ILLUMINATION',
        props: true,
        component: () => import('@/components/MasterplanIlluminationSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MASTERPLAN_ILLUMINATION'],
            configRoute: true,
          }),
      },
      {
        path: 'masterplan_rp',
        name: 'MASTERPLAN_RP',
        props: true,
        component: () => import('@/components/MasterplanRpSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MASTERPLAN_RP'],
            configRoute: true,
          }),
      },
      {
        path: 'water',
        name: 'WATER',
        props: true,
        component: () => import('@/components/WaterSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['WATER_DATA'],
            configRoute: true,
          }),
      },
      {
        path: 'water_modeling',
        name: 'waterModeling',
        component: () => import('@/components/WaterModelingSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['WATER_MODELING'],
            configRoute: true,
          }),
      },
      {
        path: 'sewer',
        name: 'SEWER',
        props: true,
        component: () => import('@/components/SewerSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['SEWER_DATA'],
            configRoute: true,
          }),
      },
      {
        path: 'sewer_modeling',
        name: 'sewerModeling',
        component: () => import('@/components/SewerModelingSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['SEWER_MODELING'],
            configRoute: true,
          }),
      },
      {
        path: 'mobile',
        name: 'mobileApp',
        meta: {
          app_kind: 'aquagis',
        },
        component: () => import('@/components/MobileAppOnline'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['AQUAGIS_MOBILE'],
            configRoute: true,
          }),
      },
      {
        path: 'mobile/offline',
        name: 'mobileAppOffline',
        component: () => import('@/components/MobileAppOffline'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['AQUAGIS_OFFLINE_MOBILE'],
            configRoute: true,
          }),
      },
      {
        path: 'parcel_report',
        name: 'parcelReportSettings',
        component: () => import('@/components/ParcelReportSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['PARCELS_REPORT'],
            configRoute: true,
          }),
      },
      {
        path: 'rzi_documentation',
        name: 'rziDocumentation',
        component: () => import('@/components/RziDocumentationSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['RZI_DOCUMENTATION'],
            configRoute: true,
          }),
      },
      {
        path: 'rzi_monitoring',
        name: 'rziMonitoring',
        component: () => import('@/components/RziMonitoringSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['RZI_MONITORING'],
            configRoute: true,
          }),
      },
      {
        path: 'zdm',
        component: {
          render: c => c('router-view'),
        },
        children: [
          {
            path: 'mobile',
            name: 'mobileAppZDM',
            meta: {
              app_kind: 'zdm',
            },
            component: () => import('@/components/MobileAppOnline'),
            beforeEnter: (to, from, next) =>
              beforeModuleRouteEnter(to, from, next, {
                additionalModulesNames: ['ZDM_MOBILE'],
                configRoute: true,
              }),
          },
          {
            path: 'failure_report',
            name: 'zdmFailureReportAdmin',
            component: () => import('@/components/ZDMFailureReportAdmin'),
            beforeEnter: (to, from, next) =>
              beforeModuleRouteEnter(to, from, next, {
                additionalModulesNames: ['ZDM_FAILURES_REPORT'],
                configRoute: true,
              }),
          },
          {
            path: 'failure_status',
            name: 'zdmFailureStatusAdmin',
            component: () => import('@/components/ZDMFailureStatusAdmin'),
            beforeEnter: (to, from, next) =>
              beforeModuleRouteEnter(to, from, next, {
                additionalModulesNames: ['ZDM_FAILURES_STATUS'],
                configRoute: true,
              }),
          },
          {
            path: 'street_lights',
            name: 'zdmStreetLights',
            props: true,
            component: () => import('@/components/ZDMStreetLightsAdmin'),
            beforeEnter: (to, from, next) =>
              beforeModuleRouteEnter(to, from, next, {
                additionalModulesNames: ['ZDM_DATA'],
                configRoute: true,
              }),
          },
          {
            path: 'failures',
            name: 'zdmFailuresAdmin',
            component: () => import('@/components/ZDMFailuresAdmin'),
            beforeEnter: (to, from, next) =>
              beforeModuleRouteEnter(to, from, next, {
                additionalModulesNames: ['ZDM_FAILURES'],
                configRoute: true,
              }),
          },
          {
            path: 'inspectors',
            name: 'zdmInspectors',
            component: () => import('@/components/ZDMInspectors'),
            beforeEnter: (to, from, next) =>
              beforeModuleRouteEnter(to, from, next, {
                additionalModulesNames: ['ZDM_INSPECTORS'],
                configRoute: true,
              }),
          },
          {
            path: 'reports',
            name: 'zdmReports',
            component: () => import('@/components/ZDMReports'),
            beforeEnter: (to, from, next) =>
              beforeModuleRouteEnter(to, from, next, {
                additionalModulesNames: ['ZDM_REPORTS'],
                configRoute: true,
              }),
          },
          {
            path: 'editor',
            name: 'zdmEditorSettings',
            component: () => import('@/components/ZDMEditorSettings'),
            beforeEnter: (to, from, next) =>
              beforeModuleRouteEnter(to, from, next, {
                additionalModulesNames: ['ZDM_SESSIONS'],
                configRoute: true,
              }),
          },
          {
            path: 'lighting_control',
            name: 'zdmLightingControl',
            component: () => import('@/components/ZDMLightingControl'),
            beforeEnter: (to, from, next) =>
              beforeModuleRouteEnter(to, from, next, {
                additionalModulesNames: ['LIGHTING_CONTROL'],
                configRoute: true,
              }),
          },
        ],
      },
      {
        path: 'dev',
        name: 'devTab',
        component: () => import('@/components/DevTab'),
      },
      {
        path: 'tasks',
        name: 'TASKS',
        component: () => import('@/components/TasksSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['TASKS'],
            configRoute: true,
          }),
      },
      {
        path: 'hydrants',
        name: 'hydrants',
        component: () => import('@/components/HydrantsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['HYDRANTS_INSPECTIONS'],
            configRoute: true,
          }),
      },
      {
        path: 'buffer',
        name: 'BUFFER',
        component: () => import('@/components/BufferSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['BUFFER'],
            configRoute: true,
          }),
      },
      {
        path: 'oze',
        name: 'OZE',
        component: () => import('@/components/OzeSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['OZE_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'billings',
        name: 'BILLINGS',
        component: () => import('@/components/BillingsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['BILLINGS'],
            configRoute: true,
          }),
      },
      {
        path: 'prints',
        name: 'printsSettings',
        component: () => import('@/components/PrintsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MAP_PRINT'],
            configRoute: true,
          }),
      },
      {
        path: 'masterplan_pdf_configuration',
        name: 'masterplanPdfSettings',
        component: () => import('@/components/MasterplanPdfSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: [
              'MASTERPLAN_STREETS',
              'MASTERPLAN_ILLUMINATION',
              'MASTERPLAN_SQUARES',
              'MASTERPLAN_RP',
            ],
            configRoute: true,
            oneModuleRequired: true,
            oneSettingRequired: true,
          }),
      },
      {
        path: 'uldk_tools',
        name: 'ULDK_TOOLS',
        component: () => import('@/components/UldkToolsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['ULDK_TOOLS_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'parcels',
        name: 'PARCELS',
        component: () => import('@/components/ParcelsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['PARCELS'],
            configRoute: true,
          }),
      },
      {
        path: 'addresses',
        name: 'ADDRESSES',
        component: () => import('@/components/AddressesSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['ADDRESSES'],
            configRoute: true,
          }),
      },
      {
        path: 'data_calculator',
        name: 'DATA_CALCULATOR',
        component: () => import('@/components/DataCalculatorSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['DATA_CALCULATOR_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'search_objects',
        name: 'SEARCH_OBJECTS',
        component: () => import('@/components/SearchObjectsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['SEARCH_OBJECTS_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'external_systems',
        name: 'EXTERNAL_SYSTEMS',
        component: () => import('@/components/ExternalSystemsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['EXTERNAL_SYSTEMS'],
            configRoute: true,
          }),
      },
      {
        path: 'db2gis',
        name: 'DB2GIS',
        component: () => import('@/components/DbGisSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['DB2GIS'],
            configRoute: true,
          }),
      },
      {
        path: 'db2gisbox',
        name: 'DB2GISBOX',
        component: () => import('@/components/DbGisBoxSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['EXTERNAL_DB'],
            configRoute: true,
          }),
      },
      {
        path: 'activity_log',
        name: 'ACTIVITY_LOG',
        component: () => import('@/components/ActivityLogSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['ACTIVITY_LOG_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'location_lab',
        name: 'LOCATION_LAB',
        component: () => import('@/components/LocationLabSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['LOCATION_LAB'],
            configRoute: true,
          }),
      },
      {
        path: 'events_log',
        name: 'eventsLog',
        component: () => import('@/components/EventsLogSettings'),
      },
      {
        path: 'documentation',
        name: 'DOCUMENTATION',
        component: () => import('@/components/DocumentationSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['DOCUMENTATION'],
            configRoute: true,
          }),
      },
      {
        path: 'sewer_analysis',
        name: 'SEWER_ANALYSIS',
        component: () => import('@/components/SewerAnalysisSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['SEWER_ANALYSIS'],
            configRoute: true,
          }),
      },
      {
        path: 'scada_alarms',
        name: 'SCADA_ALARMS',
        component: () => import('@/components/ScadaAlarmsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['SCADA_ALARMS'],
            configRoute: true,
          }),
      },
      {
        path: 'video',
        name: 'VIDEO_MODULE',
        props: true,
        component: () => import('@/components/VideoSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['VIDEO_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'cache_layer_module',
        name: 'CACHE_LAYER_MODULE',
        component: () => import('@/components/LayersCacheSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModuleName: 'CACHE_LAYER_MODULE',
          }),
      },
      {
        path: 'geonotes',
        name: 'GEONOTES_MODULE',
        props: true,
        component: () => import('@/components/GeonotesSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['GEONOTES_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'quick_print',
        name: 'QUICK_PRINT_MODULE',
        props: true,
        component: () => import('@/components/QuickPrintSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['QUICK_PRINT_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'komadres',
        name: 'KOMADRES_ORDERS',
        props: true,
        component: () => import('@/components/KomadresSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModuleName: ['KOMADRES_ORDERS'],
            configRoute: true,
          }),
      },
      {
        path: 'send_message',
        name: 'SEND_MESSAGE_MODULE',
        props: true,
        component: () => import('@/components/SendMessageSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModuleName: ['SEND_MESSAGE_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'scada_junctions',
        name: 'SCADA_JUNCTIONS',
        props: true,
        component: () => import('@/components/ScadaJunctionsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['SCADA_JUNCTIONS'],
            configRoute: true,
          }),
      },
      {
        path: 'scada_3',
        name: 'SCADA3',
        props: true,
        component: () => import('@/components/ScadaThreeSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['SCADA3'],
            configRoute: true,
          }),
      },
      {
        path: 'water_balance',
        name: 'WATER_BALANCE_MODULE',
        props: true,
        component: () => import('@/components/WaterBalanceSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['WATER_BALANCE_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'custom_reports',
        name: 'CUSTOM_REPORTS_SETTINGS',
        component: () => import('@/components/CustomReportsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['CUSTOM_REPORTS'],
            configRoute: true,
          }),
      },
      {
        path: 'water_network_development',
        name: 'WATER_NETWORK_DEVELOPMENT_MODULE',
        component: () => import('@/components/WaterNetworkDevelopmentSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['WATER_NETWORK_DEVELOPMENT_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'water_hammer',
        name: 'WATER_HAMMER',
        component: () => import('@/components/WaterHammerSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['WATER_HAMMER'],
            configRoute: true,
          }),
      },
      {
        path: 'measurements',
        name: 'MEASUREMENTS_MODULE',
        component: () => import('@/components/MeasurementsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MEASUREMENTS_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'fixed_assets',
        name: 'FIXED_ASSETS_MODULE',
        component: () => import('@/components/FixedAssetsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['FIXED_ASSETS_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'unassigned_watermeters',
        name: 'UNASSIGNED_WATERMETERS_MODULE',
        component: () => import('@/components/UnassignedWatermetersSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['UNASSIGNED_WATERMETERS_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'water_network_profile',
        name: 'WATER_NETWORK_PROFILE',
        component: () => import('@/components/WaterNetworkProfileSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['WATER_NETWORK_PROFILE'],
            configRoute: true,
          }),
      },
      {
        path: 'infrastructure_collisions',
        name: 'INFRASTRUCTURE_COLLISIONS',
        component: () => import('@/components/InfrastructureCollisionsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['INFRASTRUCTURE_COLLISIONS'],
            configRoute: true,
          }),
      },
      {
        path: 'external_attachments',
        name: 'EXTERNAL_ATTACHMENTS',
        component: () => import('@/components/ExternalAttachmentsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['EXTERNAL_ATTACHMENTS'],
            configRoute: true,
          }),
      },
      {
        path: 'mobile_device_registry',
        name: 'MOBILE_DEVICES',
        component: () => import('@/components/MobileDeviceRegistrySettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MOBILE_DEVICES'],
            configRoute: true,
          }),
      },
      {
        path: 'fresnel_zone',
        name: 'FRESNEL_ZONE',
        component: () => import('@/components/FresnelZoneSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['FRESNEL_AREA'],
            configRoute: true,
          }),
      },
      {
        path: 'antenna_signal_determination',
        name: 'ANTENNA_SIGNAL_DETERMINATION',
        component: () => import('@/components/AntennaSignalDeterminationSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['ANTENNA_SIGNAL_BEARING_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'visibility_analysis',
        name: 'VISIBILITY_ANALYSIS',
        component: () => import('@/components/VisibilityAnalysisSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['VIEWSHED'],
            configRoute: true,
          }),
      },
      {
        path: 'realtime_stations',
        name: 'REALTIME_STATIONS_MEASUREMENTS',
        component: () => import('@/components/RealtimeStationsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['REALTIME_STATIONS_MEASUREMENTS'],
            configRoute: true,
          }),
      },
      {
        path: 'antenna_bearing_history',
        name: 'ANTENNA_BEARING_HISTORY_MODULE',
        component: () => import('@/components/AntennaBearingHistorySettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['ANTENNA_BEARING_HISTORY_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'smart_match',
        name: 'SMART_MATCH',
        component: () => import('@/components/SmartMatchSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['SMART_MATCH_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'printing',
        name: 'PRINTING_2_MODULE',
        component: () => import('@/components/PrintsTwoSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['PRINTING_2_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'search_area',
        name: 'OZE_SEARCH_MODULE',
        component: () => import('@/components/SearchAreaSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['OZE_SEARCH_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'form_print',
        name: 'FORM_PRINT',
        component: () => import('@/components/FormPrintSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['FORM_PRINT'],
            configRoute: true,
          }),
      },
      {
        path: 'data_sources_sql',
        name: 'DATA_SOURCES_SQL',
        component: () => import('@/components/DataSourcesSqlSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['DATASOURCES_SQL'],
            configRoute: true,
          }),
      },
      {
        path: 'inspections',
        name: 'INSPECTIONS_MODULE',
        component: () => import('@/components/InspectionsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['INSPECTIONS_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'joins',
        name: 'JOINS',
        component: () => import('@/components/JoinsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['JOINS'],
            configRoute: true,
          }),
      },
      {
        path: 'data_tree',
        name: 'DATA_TREE_MODULE',
        component: () => import('@/components/DataTreeSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['DATA_TREE_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'data_access_restrictions',
        name: 'DATA_ACCESS_RESTRICTIONS',
        component: () => import('@/components/DataAccessRestrictionsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['DATASOURCES_BY_GROUP_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'masterplan_squares_2',
        name: 'MASTERPLAN_SQUARES_2',
        component: () => import('@/components/MasterplanSquaresTwoSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MASTERPLAN_2_SQUARES_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'masterplan_illumination_2',
        name: 'MASTERPLAN_ILLUMINATION_2',
        component: () => import('@/components/MasterplanIlluminationTwoSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MASTERPLAN_2_ILLUMINATION_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'masterplan_streets_2',
        name: 'MASTERPLAN_STREETS_2',
        props: true,
        component: () => import('@/components/MasterplanStreetsTwoSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['MASTERPLAN_2_STREETS'],
            configRoute: true,
          }),
      },
      {
        path: 'paih_migrator',
        name: 'PAIH_MIGRATOR',
        component: () => import('@/components/PaihMigratorSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['PAIH_MIGRATOR_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'ils_easy',
        name: 'ILS_EASY',
        props: true,
        component: () => import('@/components/IlsEasySettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['ILS_EASY_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'treeviews',
        name: 'TREEVIEWS',
        props: true,
        component: () => import('@/components/TreeviewsSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['TREE_VIEW'],
            configRoute: true,
          }),
      },
      {
        path: 'uldk_search',
        name: 'ULDK_SEARCH',
        props: true,
        component: () => import('@/components/UldkSearchSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['ULDK_SEARCH'],
            configRoute: true,
          }),
      },
      {
        path: 'street_lamp_inventory',
        name: 'STREET_LAMP_INVENTORY',
        props: true,
        component: () => import('@/components/StreetLampInventorySettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['ILS_SESSIONS_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'orders',
        name: 'ORDERS',
        props: true,
        component: () => import('@/components/OrdersSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['ORDERS_MODULE'],
            configRoute: true,
          }),
      },
      {
        path: 'area_report',
        name: 'AREA_REPORT',
        props: true,
        component: () => import('@/components/AreaReportSettings'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['AREA_REPORT_MODULE'],
            configRoute: true,
          }),
      },
    ],
  },
  {
    path: '/auth',
    name: 'loginView',
    meta: {
      guest: true,
    },
    component: () => import('@/views/LoginView'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/components/Login'),
      },
      {
        path: 'recovery/email',
        name: 'email',
        component: () => import('@/components/RecoveryEmail'),
      },
      {
        path: 'recovery/password/:hash',
        name: 'password',
        props: true,
        component: () => import('@/components/RecoveryPassword'),
      },
      {
        path: 'login_uke',
        name: 'loginUke',
        component: () => import('@/components/LoginUke'),
        beforeEnter: (to, from, next) =>
          beforeModuleRouteEnter(to, from, next, {
            additionalModulesNames: ['EXTERNAL_LOGIN'],
            notPermittedNext: 'login',
            isPublic: true,
          }),
      },
    ],
  },
  {
    path: '/',
    beforeEnter: (to, from, next) => beforeMainPathEnter(to, from, next),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
});

const originalPush = router.push;
router.push = async function push(location) {
  try {
    return await originalPush.call(this, location);
  } catch (err) {
    if (/NavigationDuplicated/.test(err)) {
      console.warn('NavigationDuplicated');
      return err;
    }
    console.error(err);
    return err;
  }
};

router.beforeEach((to, from, next) => {
  if (store.get('sidebar/hasUnsavedChanges')) {
    store.set('sidebar/UNSAVED_CHANGES_NEXT', next);
    return false;
  }
  const queryParams = {};
  if (to.path && to.path !== '/' && !to.matched.find(m => m.name === 'loginView')) {
    const toPathViewName = to.matched.find(m => m.meta.view)?.path.slice(1);
    const fromPathViewName = from.matched.find(m => m.meta.view)?.path.slice(1);
    if (fromPathViewName === 'map' && toPathViewName === 'map' && from.query.project && !to.query.project) {
      queryParams.project = from.query.project;
    }
    const skipLastPathSave =
      to.meta.view && (!from.name || to.name === from.name || toPathViewName !== fromPathViewName);
    if (!skipLastPathSave) {
      localStorage.setItem('last_path', to.fullPath);
      const toPathViewName = to.matched.find(m => m.meta.view)?.path.slice(1);
      if (toPathViewName) {
        localStorage.setItem(`last_path_${toPathViewName}`, to.fullPath);
      }
    }
  }
  store.set(
    'map/SET_IS_NEW_CARD!',
    from.name &&
      !from.matched.find(m => m.name === 'loginView') &&
      (from.path !== to.path ||
        (JSON.stringify(from.query) !== JSON.stringify(to.query) && from.query.project === to.query.project))
      ? false
      : true
  );
  if (Object.keys(queryParams).length) router.push({ ...to, query: { ...to.query, ...queryParams } });
  else next();
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
