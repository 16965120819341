<template>
  <v-menu
    v-model="isMenuVisible"
    top
    nudge-bottom="105"
    nudge-left="16"
    :close-on-content-click="false"
    :disabled="!hasColorPicker || colorPickerDisabled"
  >
    <template #activator="{ on }">
      <v-row no-gutters :class="{ 'box-draw-icon': isIcon }" class="box-draw included" align="center" justify="center">
        <v-col cols="auto" v-on="getListeners(on)" :style="interactive ? 'cursor:pointer' : ''">
          <template v-if="layerType && layerType.includes('linestring')">
            <svg
              v-bind="{ width: `${customSize || isIcon ? 20 : 200}px`, ...(isIcon ? { height: '18px' } : {}) }"
              class="line-draw"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <line
                x1="0"
                y1="50%"
                :x2="customSize || isIcon ? 20 : 200"
                y2="50%"
                :opacity="layerStyle['fill-opacity']"
                :stroke="layerStyle['line-color']"
                :stroke-width="isIcon ? 2 : layerStyle['line-width']"
                stroke-linecap="round"
                :stroke-dasharray="isIcon ? linestringIconDashMapped : linestringDashArray"
              />
            </svg>
          </template>
          <template v-else-if="layerType && layerType.includes('point')">
            <template v-if="layerStyle.externalGraphic">
              <img
                :width="`${customSize || (isIcon ? 1 : layerStyle['icon-size']) * 20}px`"
                :height="`${customSize || (isIcon ? 1 : layerStyle['icon-size']) * 20}px`"
                :style="iconDrawStyle"
                :src="getStyleExternalGraphicIcon(layerStyle.externalGraphic)"
                class="icon-draw"
              />
            </template>
            <template v-else-if="layerStyle.svgSource">
              <div :style="iconDrawStyle" class="icon-draw" ref="customIcon" v-html="layerStyle.svgSource" />
            </template>
            <template v-else>
              <div :style="pointDrawStyle" class="point-draw" />
            </template>
          </template>
          <template v-else-if="layerType && layerType.includes('polygon')">
            <div :class="{ 'polygon-draw-box': !isIcon, 'polygon-draw-box-icon': isIcon }">
              <template v-if="layerStyle.externalGraphic">
                <img
                  v-for="(iconStyle, idx) of fillPatternIconsStyles"
                  :key="idx"
                  :width="`${customSize || (isIcon ? 10 : 20)}px`"
                  :height="`${customSize || (isIcon ? 10 : 20)}px`"
                  :style="{ ...iconDrawStyle, ...iconStyle, position: 'absolute' }"
                  style="position: absolute"
                  :src="getStyleExternalGraphicIcon(layerStyle.externalGraphic)"
                  class="icon-draw"
                />
              </template>
              <template v-else>
                <div :style="polygonDrawStyle" class="polygon-draw" />
              </template>
              <div class="polygon-draw-stroke">
                <svg
                  v-bind="{
                    width: `${customSize || (isIcon ? 20 : 40)}px`,
                    height: `${customSize || (isIcon ? 20 : 40)}px`,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <polyline
                    :points="polygonDrawStrokePoints"
                    :stroke="layerStyle['fill-outline-color']"
                    :stroke-width="isIcon ? 2 : layerStyle['line-width'] * 2"
                    stroke-linecap="round"
                    :stroke-opacity="layerStyle['fill-outline-opacity']"
                    :stroke-dasharray="isIcon ? polygonIconDashMapped : linestringDashArray"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </template>
          <template v-else>
            <v-icon :color="getStyleIconColor(layerStyle, false)" :size="customSize || 24" class="pb-1">
              {{ getLayerIcon(layerType) }}
            </v-icon>
          </template>
        </v-col>
      </v-row>
    </template>
    <input-card
      @save="save"
      @cancel="cancel"
      :is-save-disabled="isSaveDisabled"
      v-click-outside="{
        handler: onClickOutside,
        include,
        closeConditional,
      }"
    >
      <v-color-picker v-model="value" v-bind="pickerBind" />
    </input-card>
  </v-menu>
</template>
<script>
import InputCard from '@/components/InputCard';

import colorPickerSwatches from '@/mixins/colorPickerSwatches';
import inputCard from '@/mixins/inputCard';
import stylePreviewUtilities from '@/mixins/stylePreviewUtilities';

export default {
  name: 'LayerStylePreviewIcon',
  mixins: [colorPickerSwatches, inputCard, stylePreviewUtilities],
  components: { InputCard },
  props: {
    layerId: {
      // Need if hasColorPicker
      type: Number,
    },
    layerType: {
      type: String,
    },
    layerStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    layerStyleIndex: {
      // Need if hasColorPicker for range symbolization
      type: Number,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    interactive: {
      type: Boolean,
      default: false,
    },
    hasColorPicker: {
      type: Boolean,
      default: false,
    },
    customSize: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    fillPatternIconsStyles() {
      return [{}, { right: 0 }, { bottom: 0 }, { right: 0, bottom: 0 }];
    },
    iconDrawStyle() {
      const style = {};
      style['opacity'] = `${this.layerStyle['fill-opacity'] * 100}%`;
      return style;
    },
    linestringDashArray() {
      let dashArray = this.layerStyle['line-dash'] ? [...this.layerStyle['line-dash']] : [];
      if (dashArray.length > 0) {
        const lastElement = dashArray[dashArray.length - 1];
        if (dashArray[dashArray.length - 2] === 1) {
          if (dashArray.length === 3) {
            dashArray[dashArray.length - 1] =
              dashArray[dashArray.length - 2] * this.layerStyle['line-width'] + lastElement;
            dashArray[0] = dashArray[dashArray.length - 2] * this.layerStyle['line-width'] + lastElement;
          } else {
            dashArray[dashArray.length - 1] =
              dashArray[dashArray.length - 2] * this.layerStyle['line-width'] + dashArray[dashArray.length - 1];
          }
        } else {
          dashArray[dashArray.length - 1] = dashArray[dashArray.length - 1] + this.layerStyle['line-width'];
        }
      }
      return dashArray;
    },
    linestringIconDashMapped() {
      const dict = {
        '': [],
        '10,40': [5, 10],
        '10,10': [4, 4],
        '10,1,10': [3, 1, 3],
        '1,5': [1, 5],
      };
      return dict[(this.layerStyle['line-dash'] || []).join()] || [];
    },
    polygonIconDashMapped() {
      const dict = {
        '': [],
        '10,40': [3, 8],
        '10,10': [3, 3],
        '10,1,10': [3, 1, 3],
        '1,5': [1, 3],
      };
      return dict[(this.layerStyle['line-dash'] || []).join()] || [];
    },
    pointDrawStyle() {
      const style = {};
      style['background-color'] = `${this.layerStyle['circle-color']}`;
      style['opacity'] = `${this.layerStyle['fill-opacity'] * 100}%`;
      style['width'] = `${this.customSize || (this.isIcon ? 4 : this.layerStyle['circle-radius']) * 2}px`;
      style['height'] = `${this.customSize || (this.isIcon ? 4 : this.layerStyle['circle-radius']) * 2}px`;
      return style;
    },
    polygonDrawStyle() {
      const style = {};
      style['background-color'] = `${this.layerStyle['fill-color']}`;
      style['opacity'] = `${this.layerStyle['fill-opacity'] * 100}%`;
      return style;
    },
    polygonDrawStrokePoints() {
      const maxValue = this.customSize || (this.isIcon ? 20 : 40);
      return `0 0 ${maxValue} 0 ${maxValue} ${maxValue} 0 ${maxValue} 0 0`;
    },
    polygonDrawStrokeStyle() {
      const style = {};
      style['background-color'] = `rgba(0,0,0,0)`;
      style['outline'] = `${this.isIcon ? 1 : this.layerStyle['line-width']}px solid ${
        this.layerStyle['fill-outline-color']
      }`;
      style['opacity'] = `${this.layerStyle['fill-outline-opacity'] * 100}%`;
      return style;
    },
    colorPickerDisabled() {
      return !!(this.layerStyle?.['externalGraphic'] || this.layerStyle?.['svgSource']);
    },
    layerMainColorProp() {
      const layerTypeKey = Object.keys(this.layerTypesMainColorProps).find(key => this.layerType.includes(key));
      return this.layerTypesMainColorProps[layerTypeKey];
    },
  },
  data: () => ({
    isMenuVisible: false,
    value: '',
    layerTypesMainColorProps: {
      linestring: 'line-color',
      point: 'circle-color',
      polygon: 'fill-color',
    },
  }),
  methods: {
    getListeners(on) {
      return {
        ...on,
        ...(this.interactive
          ? {
              click: () => {
                if (on.click) on.click();
                this.$emit('click');
              },
            }
          : {}),
      };
    },
    getValue() {
      const value = this.layerStyle[this.layerMainColorProp];
      this.value = value;
      return value;
    },
    setValue(value = this.value, style = this.layerStyle) {
      this.addSwatch(value);
      if (
        Object.prototype.hasOwnProperty.call(style, 'min-value') &&
        Object.prototype.hasOwnProperty.call(style, 'max-value')
      ) {
        this.$store.set('layers/SET_LAYER_STYLE_CLASS_KEY_VALUE!', {
          layerId: this.layerId,
          classIndex: this.layerStyleIndex,
          prop: this.layerMainColorProp,
          propValue: value,
        });
      } else if (Object.prototype.hasOwnProperty.call(style, 'value')) {
        this.$store.set('layers/SET_LAYER_STYLE_CLASS_KEY_VALUE!', {
          layerId: this.layerId,
          classValue: style.value,
          prop: this.layerMainColorProp,
          propValue: value,
        });
        if (+this.$route.params.lid === this.layerId) this.$root.$emit('updateFeatureCardCharts');
      } else {
        this.$store.set('layers/SET_LAYER_STYLE_KEY_VALUE!', {
          layerId: this.layerId,
          key: this.layerMainColorProp,
          keyValue: value,
        });
        if (+this.$route.params.lid === this.layerId) this.$root.$emit('updateFeatureCardCharts');
      }
      this.$root.$emit('setProjectLayerStyle', this.layerId);
      this.$root.$emit('hasUnsavedChanges', true);
    },
  },
};
</script>
<style lang="scss" scoped>
.box-draw {
  z-index: 3;
}
.box-draw-icon {
  height: 25px;
  width: 25px;
}
.polygon-draw {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.polygon-draw-box {
  width: 40px;
  height: 40px;
  position: relative;
}
.polygon-draw-box-icon {
  width: 20px;
  height: 20px;
  position: relative;
}
.polygon-draw-stroke {
  position: absolute;
  width: 100%;
  height: 100%;
}
.point-draw {
  border-radius: 50%;
}
.icon-draw {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
