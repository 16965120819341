import Vue from 'vue';
import api from '@/services/api';
import { make } from 'vuex-pathify';
import { saveFileFromBackend } from '@/assets/js/utility';
const state = {
  attachments: [],
  relations: {},
  attachmentsForFeature: {},
  externalAttachments: [],
};
const mutations = {
  ...make.mutations(state),
  SET_ATTACHMENTS: (state, data) => {
    const features = data.features.features;
    const mappedFeatures = features.map(feature => {
      return { id: feature.id, ...feature.properties };
    });
    state.attachments = mappedFeatures;
  },
  SET_RELATIONS: (state, data) => {
    const { dataSourceName, featureId, attachmentsRelations, othersRelations } = data;
    Vue.set(state.relations, dataSourceName, { ...state.relations[dataSourceName], [featureId]: othersRelations });
    if (!state.attachmentsForFeature.dataSourceName) {
      Vue.set(state.attachmentsForFeature, dataSourceName, {});
    }
    Vue.set(state.attachmentsForFeature[dataSourceName], featureId, attachmentsRelations);
  },
};
const actions = {
  async getRelations({ commit, rootState }, payload) {
    const documentsDataSourceName = rootState.admin.modulesMapping.documentation?.attachment_datasource_name;
    const { data_source_name, feature_id } = payload;
    const r = await api.post(`/dataio/data_sources/feature_assignment/${data_source_name}/${feature_id}`, {});

    const relations = r.data.data;
    let attachmentsRelations = relations.filter(relation => relation.data_source_name === documentsDataSourceName);
    const othersRelations = relations.filter(relation => relation.data_source_name !== documentsDataSourceName);
    if (payload.returnSimpleRelations) return othersRelations;

    const attachmentsRelationsFeaturesIds = attachmentsRelations.map(aRelation => aRelation.feature_id);
    const filters = {
      $IN: {
        [`${documentsDataSourceName}.id`]: attachmentsRelationsFeaturesIds,
      },
    };
    const params = {
      with_features: true,
      with_count: false,
      with_features_bbox: false,
      with_collection_bbox: false,
      with_geometry: false,
    };
    const rr = await api.post(
      `/dataio/data_sources/${documentsDataSourceName}/read`,
      { features_filter: filters },
      { params }
    );
    const features = rr.data.data.features.features;
    attachmentsRelations = attachmentsRelations.map(aRelation => {
      const feature = features.find(feature => feature.id === aRelation.feature_id);
      const properties = { ...feature.properties, id: feature.id };
      aRelation.properties = properties;
      return aRelation;
    });

    commit('SET_RELATIONS', {
      dataSourceName: data_source_name,
      featureId: feature_id,
      attachmentsRelations,
      othersRelations,
    });
  },
  async getAttachment({ rootState }, attachmentId) {
    const documentsDataSourceName = rootState.admin.modulesMapping.documentation?.attachment_datasource_name;
    const params = {
      with_features: true,
      with_count: false,
      with_features_bbox: false,
      with_collection_bbox: false,
      with_geometry: false,
    };
    const r = await api.post(`/dataio/data_sources/${documentsDataSourceName}/read/${attachmentId}`, {}, { params });
    return r.data.data;
  },
  async addRelation({ dispatch }, payload) {
    await api.post('/dataio/data_sources/feature_assignment/attach', payload);
    await dispatch('getRelations', payload);
  },
  async deleteRelation({ dispatch }, payload) {
    await api.post('/dataio/data_sources/feature_assignment/detach', payload);
    await dispatch('getRelations', payload);
  },
  async deleteAttachment(store, payload) {
    const { id, datasource_name, feature_id } = payload;
    await api.delete(`/attachments/${id}/${datasource_name}/${feature_id}`);
  },
  async getAttachments({ commit, rootState }) {
    const dataSourceName = rootState.admin.modulesMapping.documentation?.attachment_datasource_name;
    const r = await api.post(`/dataio/data_sources/${dataSourceName}/read`, {});
    commit('SET_ATTACHMENTS', r.data.data);
  },
  async downloadAttachment(store, payload) {
    const r = await api.get(`/attachments/${payload}/file`, { responseType: 'arraybuffer' });
    saveFileFromBackend(r.data, r.headers);
  },
  async assignAttachments(store, payload) {
    await api.post(`/attachments/assign`, payload);
  },
  async addAttachment(store, formData) {
    const r = await api.post('/attachments', formData, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    });
    return r;
  },
  async getExternalAttachments({ commit }) {
    const r = await api.get('external_attachments');
    commit(
      'SET_EXTERNAL_ATTACHMENTS',
      r.data.data.map(el => {
        return {
          id: el.id,
          size: el.size,
          filename: el.name,
          added_at: el.created,
        };
      })
    );
  },
  async assignExternalAttachments(store, payload) {
    await api.post('external_attachments', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
