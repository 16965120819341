<template>
  <v-tooltip v-bind="tooltipProps" color="font" :key="key" :disabled="!tooltip">
    <template #activator="{ on: onTooltip }">
      <span :class="$attrs.classes" v-on="(disabled || isLoading) && onTooltip">
        <slot v-bind="{ ...$props, ...$attrs, ...{ onTooltip } }">
          <v-btn
            :[computedButtonSize]="true"
            icon
            :elevation="isContext ? 3 : null"
            v-on="{ ...onTooltip, ...computedListeners }"
            :disabled="disabled"
            :loading="isLoading"
            :class="{
              divider: $attrs.hasDivider,
              'context-button': isContext,
              'divider-left': $attrs.hasLeftDivider,
            }"
            :style="{ ...(disableCursor ? { cursor: 'default !important' } : {}) }"
          >
            <v-icon :color="computedColor" :class="{ background: isActive }" :size="computedFontSize">
              {{ icon }}
            </v-icon>
          </v-btn>
        </slot>
      </span>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'TableButton',
  props: {
    hasState: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isContext: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    tooltipProps: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
    fontSize: {
      type: String,
    },
    color: {
      type: String,
      default: null,
    },
    customColor: {
      type: String,
      default: '',
    },
    buttonSize: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disableCursor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    key() {
      return `${this.name}-tooltip`;
    },
    computedColor() {
      if (this.disabled) {
        return;
      }
      if (this.customColor) {
        return this.customColor;
      }
      return this.isActive ? 'link' : (this.color ?? this.$_colors.iconIdle);
    },
    computedButtonSize() {
      if (this.buttonSize) return this.buttonSize;
      return this.isContext ? 'small' : 'default';
    },
    computedFontSize() {
      if (this.fontSize) return this.fontSize;
      return this.isContext ? '18px' : '24px';
    },
    computedListeners() {
      return {
        ...this.$listeners,
        ...(this.$listeners[this.name] && {
          click: () => {
            this.$emit(this.name);
            this.$emit('click');
          },
        }),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  @include activeToolButtonStyle();
}
.divider {
  position: relative;
  margin-right: 18px;
  &::after {
    content: '';
    position: absolute;
    @include border(right);
    height: 100%;
    right: -9px;
    top: 0;
  }
}
.divider-left {
  position: relative;
  margin-left: 5px;
  padding-left: 5px;
  &::after {
    content: '';
    position: absolute;
    @include border(right);
    height: 70%;
    left: 0px;
    top: 15%;
  }
}
</style>

<style scoped>
@reference '@/tailwind.css';
.context-button {
  @apply tw:rounded-base tw:bg-white;
}
</style>
