import { get, sync } from 'vuex-pathify';
import { unByKey } from 'ol/Observable';
import { Feature } from 'ol';

export default {
  data: () => ({
    mvtVisibleLayersCounter: 0,
    mvtVisibleLayersLoaded: 0,
    specialType: false,
    moduleRoute: '',
    inputName: '',
  }),
  computed: {
    isActiveToolNotReplaceable: sync('tools/isActiveToolNotReplaceable'),
    isIdentificationToolActive: sync('tools/toolStatus@isIdentificationToolActive'),
    isAssigningMultipleParcelsLoading: sync('edit/isAssigningMultipleParcelsLoading'),
    identifiedFeatures: sync('map/identifiedFeatures'),
    idPropertyName: get('layers/metadata@:currentLayerDataSourceName.attributes_schema.id_name'),
    metadata: get('layers/metadata'),
    polesLayerGeomId: get('admin/modulesMapping@zdm_data.layers.pole'),
    pickArmsLayerGeomId: get('admin/modulesMapping@zdm_data.layers.pick_arm'),
    lightHeadsLayerGeomId: get('admin/modulesMapping@zdm_data.layers.head'),
    zdmDatasourcesMapping: get('admin/modulesMapping@zdm_data.datasources'),
    zdmDataSources() {
      return this.zdmDatasourcesMapping || {};
    },
    lampSubfeaturesLayersIds() {
      return [this.polesLayerGeomId, this.pickArmsLayerGeomId, this.lightHeadsLayerGeomId];
    },
    isIdentification() {
      return this.routeName === 'identification';
    },
  },
  methods: {
    specialLayerFilter(layer) {
      return !layer.get('isSpecial') && !layer.get('isEditingLayer');
    },
    isFeatureAlreadyIdentified(layerId, featureId, featuresArray) {
      if (!featuresArray) {
        return false;
      }
      return featuresArray.find(feature => feature.id === featureId && feature.layerId === layerId);
    },
    groupFeaturesByLayer(features) {
      return features.reduce((total, current) => {
        return { ...total, [current.layerId]: [...(total[current.layerId] || []), current] };
      }, {});
    },
    getGroupedFeaturesAsArray(layersObject) {
      return Object.keys(layersObject).reduce((total, key) => {
        return [
          ...total,
          {
            id: key,
            name: layersObject[key][0].name,
            features: layersObject[key],
          },
        ];
      }, []);
    },
    attachCrosshairMoveHandler() {
      this.map.getViewport().style.cursor = 'crosshair';
    },
    dettachCursorMoveHandler() {
      unByKey(this.pointerMoveKey);
      this.map.getViewport().style.cursor = '';
    },
    toggleIdentification(
      value,
      {
        specialType,
        name,
        layersFilter,
        plotName,
        isTurnOffOnFail,
        geometryType,
        additionalAttributes = [],
        identifyCallback,
        forcedProvider,
        onMapClick,
        onError,
      } = {}
    ) {
      if (!specialType && this.activeTool && this.activeTool.startsWith('identification-'))
        specialType = this.activeTool.replace('identification-', '');
      const toolName = `identification${specialType ? `-${specialType}` : ''}`;
      if (value && this.activeTool !== toolName) {
        this.$root.$emit('deactivateAllTools');
      } else if (!value && this.activeTool === toolName) {
        this.deactivateToolHandler(toolName);
      }
      this.$nextTick(() => {
        if (value) {
          this.isModuleIdentificationActive = this.activeTool === 'moduleIdentification';
        }
        this.isIdentificationToolActive = value && !specialType && plotName !== 'newPolygonFeatureGeometry';
        this.dettachCursorMoveHandler();
        if (value) {
          this.inputName = name;
          this.attachListener(
            specialType === 'assignMultiplePlots' ? 'click' : 'singleclick',
            this.getIdentificationCallback({
              specialType,
              layersFilter,
              identifyCallback,
              plotName,
              isTurnOffOnFail,
              geometryType,
              additionalAttributes,
              forcedProvider,
              onMapClick,
              onError,
            }),
            {
              type: 'on',
              cursor: null,
            }
          );
          this.activeTool = toolName;
          if (specialType && ['assignPlot', 'assignMultiplePlots'].includes(specialType)) {
            this.attachCrosshairMoveHandler();
          } else {
            this.attachMoveCursorHandler('pointer', layersFilter);
          }
        } else {
          if (this.isModuleIdentificationActive) {
            this.setModuleIdentification({ isActive: true, moduleRoute: this.moduleRoute });
            this.isModuleIdentificationActive = false;
          }
        }
      });
    },
    async identifyGroupedFeatures(e, additionalAttributes = []) {
      const features = await this.identifyCoordinates(e.coordinate, undefined, additionalAttributes);
      const featuresGroupedByLayer = this.groupFeaturesByLayer(features);
      return this.getGroupedFeaturesAsArray(featuresGroupedByLayer);
    },
    addByMapIdentificationCallback(additionalAttributes = []) {
      return async e => {
        const features = await this.identifyGroupedFeatures(e, additionalAttributes);
        this.$root.$emit('assignedObjects', features);
      };
    },
    excludeObjectIdentificationCallback() {
      return async e => {
        const features = await this.identifyGroupedFeatures(e);
        this.$root.$emit('excludedObjects', features);
      };
    },
    moduleIdentificationCallback(layersFilter, identifyCallback) {
      return async e => {
        const { coordinate } = e;
        const features = await this.identifyCoordinates(coordinate, layersFilter);
        if (features.length > 0) {
          const { id } = features[0];
          if (identifyCallback) {
            identifyCallback(id);
            return;
          }
          if (this.moduleRoute) {
            this.$router.push({
              name: this.moduleRoute,
              params: { fid: id },
            });
          } else if (this.$route.name === 'layerTable' || this.$route.name === 'layerFeature') {
            const { params } = this.$route;
            this.$router.push({
              name: 'layerFeature',
              params: { ...params, fid: id },
            });
          }
        }
      };
    },
    modelingResultsIdentificationCallback() {
      return async e => {
        const features = await this.identifyCoordinates(e.coordinate);
        if (features.length > 0) {
          this.$router.push({
            name: 'modelingResultsFeature',
            params: {
              ...this.$route.params,
              type: features[0].layerId,
              elementId: features[0].id,
            },
          });
        } else {
          const { scenarioId, netType } = this.$route.params;
          this.$router.push({
            name: 'modelingResultsScenario',
            params: {
              netType,
              scenarioId,
            },
          });
        }
      };
    },
    getIdentificationCallback({
      specialType,
      layersFilter,
      identifyCallback,
      plotName,
      isTurnOffOnFail,
      geometryType,
      additionalAttributes = [],
      forcedProvider,
      onMapClick,
      onError,
    }) {
      const dict = {
        assignPlot: () =>
          this.identifyPlot(plotName, isTurnOffOnFail, identifyCallback, forcedProvider, onMapClick, onError),
        assignMultiplePlots: () => this.identifyPlots(plotName, isTurnOffOnFail, geometryType),
        addByMap: () => this.addByMapIdentificationCallback(additionalAttributes),
        excludeObject: () => this.excludeObjectIdentificationCallback(),
        module: () => this.moduleIdentificationCallback(layersFilter, identifyCallback),
        modelingResults: () => this.modelingResultsIdentificationCallback(),
      };
      if (dict[specialType]) {
        return dict[specialType]();
      }
      return this.getSimpleIdentificationHandler(layersFilter);
    },
    setIdentifiedFeaturesZdm(features) {
      this.identifiedFeatures = features;
    },
    getSimpleIdentificationHandler(layersFilter) {
      return this.simpleIdentifyCoordinates(layersFilter, this.setIdentifiedFeaturesZdm);
    },
    simpleIdentifyCoordinates(layersFilter, identifiedFeaturesSetter) {
      return e => {
        const { coordinate } = e;
        this.identifyCoordinatesHandler(coordinate, layersFilter, false, identifiedFeaturesSetter);
      };
    },
    async identifyCoordinatesHandler(
      coordinate,
      layersFilter,
      isOnInit = false,
      identifiedFeaturesSetter = this.setGroupedIdentifiedFeatures,
      additionalAttributes = []
    ) {
      const features = await this.identifyCoordinates(coordinate, layersFilter, additionalAttributes);
      if (features.length === 0) {
        if (this.$route.name === 'layerFeature') {
          this.$router.push({ name: 'layerTable', params: { lid: this.$route.params.lid }, query: this.$route.query });
        }
      } else {
        if (features.length === 1) {
          const { layerId: lid, id: fid } = features[0];
          if (this.lampSubfeaturesLayersIds.includes(lid)) {
            this.pushFeatureCardRoute(lid, fid, {
              lamp_id: features[0].parentFeatureId,
              id: fid,
              type: features[0].dataSource.startsWith('zdm_data') ? features[0].dataSource.split('zdm_data_')[1] : null,
            });
          } else {
            this.pushFeatureCardRoute(lid, fid);
          }
        } else {
          if (!isOnInit) {
            this.pushIdentificatonRoute(coordinate);
          }
          identifiedFeaturesSetter(features);
        }
      }
      return features;
    },
    pushFeatureCardRoute(lid, fid, query) {
      this.$router.push({
        name: 'layerFeature',
        params: {
          lid,
          fid,
        },
        query,
      });
    },
    pushIdentificatonRoute(coordinates) {
      const z = this.map.getView().getZoom();
      const [x, y] = coordinates;
      this.$router.push({
        name: 'identification',
        params: {
          x,
          y,
          z,
        },
        query: this.$route.query,
      });
    },
    setGroupedIdentifiedFeatures(features) {
      const featuresGroupedByLayer = this.groupFeaturesByLayer(features);
      const featuresGroupedByLayerInArray = this.getGroupedFeaturesAsArray(featuresGroupedByLayer);
      this.identifiedFeatures = featuresGroupedByLayerInArray;
    },
    async identifyCoordinates(coordinate, layersFilter, additionalAttributes = []) {
      const pixel = this.map.getPixelFromCoordinate(coordinate);
      return await this.getFeaturesAtPixel(pixel, layersFilter, additionalAttributes);
    },
    getZdmLampParentFeatureId(properties) {
      const key = Object.keys(properties).find(key => /street_lamp_id/.test(key));
      return properties[key];
    },
    async getFeaturesAtPixel(pixel, layerFilter, additionalAttributes = []) {
      const features = (await this.getWorkerFeaturesAtPixel(pixel, layerFilter)).map(ft => [
        new Feature(ft),
        this.getLayerById(ft.layerId, 'layers'),
      ]);
      this.map.forEachFeatureAtPixel(pixel, (feature, layer) => features.push([feature, layer]), {
        hitTolerance: 5,
        layerFilter,
      });
      return features.reduce((acc, [feature, layer]) => {
        const { data_source_name: dataSource, type: layerType, id: layerId, name } = layer?.getProperties() ?? {};
        const display_attribute_name = this.$_objectToArray(this.zdmDataSources).find(
          d => d.datasource === dataSource
        )?.display_attribute_name;
        const street_attribute_name = this.$_objectToArray(this.zdmDataSources).find(
          d => d.datasource === dataSource
        )?.street_attribute_name;
        const district_attribute_name = this.$_objectToArray(this.zdmDataSources).find(
          d => d.datasource === dataSource
        )?.district_attribute_name;
        const number = feature.get(display_attribute_name);
        const street = feature.get(street_attribute_name);
        const district = feature.get(district_attribute_name);
        const idPropertyName = this.metadata[dataSource]?.attributes_schema.id_name;
        const featureId = feature.get(idPropertyName) || feature.getId() || feature.get('id');
        const descAttributeName = this.metadata[dataSource]?.attributes_schema.desc_attribute_name;
        const descAttributeValue = feature.get(descAttributeName);
        if (this.isFeatureAlreadyIdentified(layerId, featureId, features)) {
          return acc;
        }
        return [
          ...acc,
          {
            id: featureId,
            layerId,
            layerType,
            dataSource,
            name,
            descAttributeValue,
            ...(number === undefined ? {} : { number }),
            ...(street === undefined ? {} : { street }),
            ...(district === undefined ? {} : { district }),
            ...(this.lampSubfeaturesLayersIds.includes(layerId)
              ? { parentFeatureId: this.getZdmLampParentFeatureId(feature.getProperties()) }
              : {}),
            ...additionalAttributes.reduce((total, current) => {
              return { ...total, [current]: feature.get(current) };
            }, {}),
          },
        ];
      }, []);
    },
    identifyPlot(
      name = 'geometry',
      isTurnOffOnFail,
      identifyPlotCallback,
      forcedProvider,
      onMapClick = () => {},
      onError
    ) {
      return e => {
        onMapClick();
        const { coordinate } = e;
        this.$root.$emit(
          'assignedCoordinates',
          coordinate,
          name,
          isTurnOffOnFail,
          false,
          null,
          identifyPlotCallback,
          forcedProvider,
          onError
        );
      };
    },
    identifyPlots(name = 'geometry', isTurnOffOnFail, geometryType) {
      return e => {
        const { coordinate } = e;
        if (this.isAssigningMultipleParcelsLoading) return;
        this.isAssigningMultipleParcelsLoading = true;
        this.$root.$emit('assignedCoordinates', coordinate, name, isTurnOffOnFail, true, geometryType);
      };
    },
    identifyCoordinatesOnInit() {
      if (!this.isIdentification) {
        return;
      }
      if (this.mvtVisibleLayersLoaded !== this.mvtVisibleLayersCounter) {
        return;
      }
      this.map.once('rendercomplete', () => {
        this.identifyCoordinatesHandler(this.center, undefined, true, this.setIdentifiedFeaturesZdm);
      });
    },
    setModuleIdentification(value) {
      const { isActive, moduleRoute, identifyCallback } = value;
      isActive ? this.$root.$emit('deactivateAllTools') : this.deactivateToolHandler('moduleIdentification');
      this.$nextTick(() => {
        if (isActive) {
          this.isIdentificationToolActive = false;
          this.moduleRoute = moduleRoute;
          this.activeTool = 'moduleIdentification';
          this.isActiveToolNotReplaceable = true;
          this.attachListener(
            'singleclick',
            this.getIdentificationCallback({
              identifyCallback,
              specialType: 'module',
              layersFilter: layer => {
                return layer.get('isSpecial') && !layer.get('isCutoff') && layer.get('id') !== 'markersLayer';
              },
            }),
            {
              type: 'on',
              cursor: null,
            }
          );
          this.attachMoveCursorHandler('help', layer => {
            return layer.get('isSpecial') && !layer.get('isCutoff') && layer.get('id') !== 'markersLayer';
          });
        } else {
          this.moduleRoute = undefined;
          this.isActiveToolNotReplaceable = false;
        }
      });
      this.dettachCursorMoveHandler();
    },
  },
  mounted() {
    this.$root.$on('identification-action', this.toggleIdentification);
    this.$root.$on('moduleIdentification-action', this.setModuleIdentification);
  },
};
