import { get, call, sync } from 'vuex-pathify';

export default {
  computed: {
    uldkAttributesMappingsFetchStatus: sync('search/uldkAttributesMappingsFetchStatus'),
    isUldkToolsEnabled: get('admin/settingsValues@uldk_tools_module_enabled'),
    uldkToolsMetadata: get('admin/additionalModules@ULDK_TOOLS_MODULE'),
    isUldkToolsModuleConfigured() {
      return this.uldkToolsMetadata?.enabled && this.uldkToolsMetadata?.configured && this.isUldkToolsEnabled;
    },
    uldkAttributesMappings: get('search/uldkAttributesMappings'),
    uldkToolsAttributesMappings: get('search/uldkToolsAttributesMappings'),
    isAssignParcelButtonActive: sync('edit/isAssignParcelButtonActive'),
    isAssignMultipleParcelButtonActive: sync('edit/isAssignMultipleParcelButtonActive'),
    isAssigningMultipleParcelsLoading: get('edit/isAssigningMultipleParcelsLoading'),
    attributesMappings() {
      return this.isUldkToolsModuleConfigured ? this.uldkToolsAttributesMappings : this.uldkAttributesMappings;
    },
    newFeatureRerunAction: sync('edit/newFeatureRerunAction'),
  },
  watch: {
    activeTool(nV) {
      if (
        (this.isAssignParcelButtonActive && nV !== 'identification-assignPlot') ||
        (this.isActive && nV !== 'identification-assignMultiplePlots')
      )
        this.turnOffAssignParcel(false);
    },
  },
  methods: {
    getUldkAttributesMappings: call('search/getUldkAttributesMappings'),
    getUldkToolsAttributesMappings: call('search/getUldkToolsAttributesMappings'),
    async fetchUldkMapping() {
      if (this.uldkAttributesMappingsFetchStatus === 0 && !Object.keys(this.attributesMappings)?.length) {
        this.uldkAttributesMappingsFetchStatus = 1;
        this.isUldkToolsModuleConfigured
          ? await this.getUldkToolsAttributesMappings()
          : await this.getUldkAttributesMappings();
        this.uldkAttributesMappingsFetchStatus = 2;
      }
    },
    getAttributesMapping({ attributesValues, area }) {
      if (this.attributesMappings[this.parcelTargetDatasource] && attributesValues) {
        const values = {};
        const uldkAttrsMapping = {
          community: 'commune',
          county: 'county',
          district: 'region',
          plot: 'parcel',
          teryt: 'teryt',
          voivodeship: 'voivodeship',
        };
        Object.keys(uldkAttrsMapping).forEach(key => {
          if (this.attributesMappings[this.parcelTargetDatasource][key] && attributesValues[uldkAttrsMapping[key]]) {
            values[this.attributesMappings[this.parcelTargetDatasource][key]] = attributesValues[uldkAttrsMapping[key]];
            if (key === 'teryt') {
              const territValue = attributesValues[uldkAttrsMapping[key]];
              const dotsIndexes = [];
              for (let i = 0; i < territValue.length; i++) {
                if (territValue[i] === '.') {
                  dotsIndexes.push(i);
                }
              }
              if (dotsIndexes.length > 2) {
                values[this.attributesMappings[this.parcelTargetDatasource]['sheet']] = territValue.substring(
                  dotsIndexes[1] + 1,
                  dotsIndexes[2]
                );
              }
            }
          }
        });
        if (this.attributesMappings[this.parcelTargetDatasource].area) {
          values[this.attributesMappings[this.parcelTargetDatasource].area] = area.toFixed(2);
        }
        return values;
      }
    },
    turnOffAssignParcel(withToolDisabling = true) {
      this.isActive = false;
      this.isAssignParcelButtonActive = false;
      this.isAssignMultipleParcelButtonActive = false;
      this.$root.$emit('deleteSidebarGeometry');
      if (this.layer && this.$route.fid === 'new') this.pushToParentRoute(this.layer);
      if (withToolDisabling) this.$root.$emit('identification-action', false);
      this.$root.$emit('turnOffEditing', false);
    },
    toggleAssignParcel(type = 'single', customCallback, onMapClick, onError) {
      if (type === 'single' ? this.isAssignParcelButtonActive : this.isAssignMultipleParcelButtonActive) {
        this.turnOffAssignParcel();
      } else {
        this.turnOnAssignParcel({ type, onSuccess: customCallback, onMapClick, onError });
      }
    },
    turnOnAssignParcel({ type = 'single', onSuccess, onMapClick, onError }) {
      const isSingle = type === 'single';
      this.$root.$emit('deactivateAllTools');
      this.$nextTick(() => {
        if (isSingle) {
          this.isAssignParcelButtonActive = true;
        } else {
          this.isAssignMultipleParcelButtonActive = true;
        }
        this.$root.$emit('identification-action', true, {
          specialType: isSingle ? 'assignPlot' : 'assignMultiplePlots',
          geometryType: this.geometryType,
          plotName: `newPlotFeatureGeometry_${this.layer}`,
          isTurnOffOnFail: false,
          onMapClick,
          identifyCallback: data => this.onNewPlotFeatureGeometry(data, onSuccess),
          forcedProvider: this.forcedProvider,
          onError,
        });
        setTimeout(() => {
          this.isActive = true;
        });
      });
    },
    async onNewPlotFeatureGeometry({ geometry, attributesValues, area }, customCallback) {
      if (customCallback) {
        customCallback({
          values: this.getAttributesMapping({ attributesValues, area }),
          geometry,
          layerId: this.layer,
          datasource: this.parcelTargetDatasource,
        });
      } else {
        this.onNewPolygonFeatureGeometry({
          inputName: 'newPolygonFeatureGeometry',
          geometry,
          layerId: this.layer,
          attributesValues,
          area,
          postRouteCallback: () => {
            const values = this.getAttributesMapping({ attributesValues, area });
            this.additionalAttributesValues = values;
            this.newFeatureRerunAction = 'assignParcelSingleAction';
          },
        });
      }
      this.isActive = false;
      this.multiple ? (this.isAssignMultipleParcelButtonActive = false) : (this.isAssignParcelButtonActive = false);
    },
    assignParcelAction(customCallback, onMapClick) {
      this.toggleAssignParcel(this.multiple ? 'multiple' : 'single', customCallback, onMapClick);
    },
  },
};
