import { call, get, sync } from 'vuex-pathify';

import { unByKey } from 'ol/Observable';
import { GeoJSON } from 'ol/format';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import LayerGroup from 'ol/layer/Group';
import { Tile as TileLayer, Vector as VectorLayer, VectorTile as VectorTileLayer, Layer } from 'ol/layer';
import { Vector as VectorSource, TileWMS, XYZ, WMTS, Source } from 'ol/source';
import { isEqual } from 'lodash';
import {
  defaultMvtSourceLoader,
  defaultMvtLayerSource,
  databoxMvtLayerSource,
  getServiceLayerEmbedCredentials,
  serviceLayerWithCredentialsLoader,
} from '@/assets/js/mapUtils';

import {
  updateContainerTransform,
  prefetchOffscreenLayerData,
  offscreenCanvasLayerRenderer,
} from '@/assets/js/workerUtils.js';

import OffscreenWorker from '@/assets/js/offscreenWorker.js?worker';

const offscreenWorker = new OffscreenWorker();
const offscreenLayers = {};

export default {
  computed: {
    projects: sync('layers/projects'),
    tableSelectionType: sync('edit/tableSelectionType'),
    isProjectLayersFetched: sync('sidebar/isProjectLayersFetched'),
    currentLayerDataSourceName: get('layers/currentLayer@data_source_name'),
    idPropertyName: get('layers/metadata@:currentLayerDataSourceName.attributes_schema.id_name'),
    project: get('layers/project'),
    projectElements: get('layers/project@layers'),
    zdmLayersMapping: get('admin/modulesMapping@zdm_data.layers'),
    zdmDatasourcesMapping: get('admin/modulesMapping@zdm_data.datasources'),
    zdmLampFields: get('admin/modulesMapping@zdm_data.street_lamp_fields'),
    projectZoom: sync('layers/project@zoom'),
    layersToBeChanged: sync('map/layersToBeChanged'),
    isSelectionActive: sync('tools/toolStatus@isSelectionActive'),
    token: get('authentication/token'),
    highlitedFeatures: get('map/highlitedFeatures'),
    basemapLayer: get('layers/basemapLayer'),
    zdmDatasources() {
      return this.zdmDatasourcesMapping || {};
    },
    zdmLayers() {
      return this.zdmLayersMapping || {};
    },
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers;
    },
    lampSubfeaturesLayersIds() {
      return Object.keys(this.zdmLayers).length > 0
        ? [this.zdmLayers.pole, this.zdmLayers.pick_arm, this.zdmLayers.head]
        : [];
    },
    zdmLayersIds() {
      return Object.values(this.zdmLayers);
    },
    customFeaturesLoaders() {
      return {
        vehicles: this.fillVehiclesLayerSource,
      };
    },
    mvtLayersRefreshDataSources() {
      const dict = [];
      if (this.isScadaEnabled) {
        dict[this.scadaDataSource] = { action: this.refreshScada, interval: this.scadaInterval * 60 * 1000 };
      }
      return dict;
    },
    layers: get('layers/layers'),
    metadata: get('layers/metadata'),
  },
  data: () => ({
    customStyleHandlers: undefined,
  }),
  watch: {
    layersToBeChanged(nV) {
      if (nV.size > 0) {
        nV.forEach(layer => {
          this.refreshLayerSource(layer);
        });
        this.layersToBeChanged = new Set();
      }
    },
  },
  methods: {
    getCacheLayerIds: call('map/getCacheLayerIds'),
    clearMvtLayerCache: call('layers/clearMvtLayerCache'),
    getMvtTile: call('layers/getTile'),
    registerProjectLayersListeners() {
      this.$root.$on('isLayerVisibleWithCallback', this.isLayerVisibleWithCallback);
      this.$root.$on('deleteProjectLayer', this.deleteProjectLayer);
      this.$root.$on('discardProjectLayers', this.discardProjectLayers);
      this.$root.$on('filterProjectLayer', this.filterProjectLayer);
      this.$root.$on('projectLayersLoaded', this.projectLayersLoaded);
      this.$root.$on('pushProjectLayers', this.pushProjectLayers);
      this.$root.$on('setProjectLayersOrder', this.setProjectLayersOrder);
      this.$root.$on('setProjectLayerOpacity', this.setProjectLayerOpacity);
      this.$root.$on('setProjectLayerStyle', this.setProjectLayerStyle);
      this.$root.$on('setProjectServiceLayerStyle', this.setProjectServiceLayerStyle);
      this.$root.$on('toggleProjectAllLayersLabels', this.toggleProjectAllLayersLabels);
      this.$root.$on('toggleProjectLayerLabels', this.toggleProjectLayerLabels);
      this.$root.$on('selection-action', this.toggleProjectLayerSelection);
      this.$root.$on('toggleProjectLayerDirectionArrows', this.toggleProjectLayerDirectionArrows);
      this.$root.$on('toggleProjectLayerVisible', this.toggleProjectLayerVisible);
      this.$root.$on('toggleProjectLayersVisible', this.toggleProjectLayersVisible);
      this.$root.$on('refreshMvtSource', this.refreshMvtSource);
      this.$root.$on('refreshMvtSources', this.refreshMvtSources);
    },
    refreshLayerSource(id, group = 'layers') {
      const layer = this.getLayerById(id, group);
      if (layer) {
        if (layer.get('isOffscreen')) {
          offscreenLayers[id].mainThreadFrameState = undefined;
          offscreenWorker.postMessage({
            action: 'sourceChanged',
            layerId: id,
            highlitedFeatures: this.highlitedFeatures,
            selectedFeatures: this.selectedFeatures,
            erroredFeatures: this.erroredFeatures,
          });
        }
        layer.changed();
      }
    },
    getRequiredAttributes(id, style) {
      const attributes = new Set();
      const dataSourceName = this.layers[id]?.data_source_name;
      const dataSourceDescAttributeName = this.metadata[dataSourceName]?.attributes_schema.desc_attribute_name;
      if (dataSourceDescAttributeName) {
        attributes.add(dataSourceDescAttributeName);
      }
      const addAdvancedLabelsAttributes = text => {
        const rxp = /{([^}]+)}/g;
        let curMatch;
        while ((curMatch = rxp.exec(text))) {
          attributes.add(curMatch[1]);
        }
      };
      if (style.uniques || style.ranges) {
        const { property, values } = style.uniques || style.ranges;
        Object.keys(values).forEach(key => {
          if (values[key].labels?.attributes_advanced?.text)
            addAdvancedLabelsAttributes(values[key].labels?.attributes_advanced?.text);
          else values[key].labels?.attributes?.forEach(attribute => attributes.add(attribute));
        });
        attributes.add(property);
      }
      if (style?.labels?.attributes_advanced?.text) {
        addAdvancedLabelsAttributes(style.labels.attributes_advanced.text);
      } else if (style?.labels && style.labels.attributes?.length > 0) {
        style.labels.attributes.forEach(attribute => attributes.add(attribute));
      }
      const datasourceKey = Object.keys(this.zdmLayers).find(key => this.zdmLayers[key] === id);
      if (this.lampSubfeaturesLayersIds.includes(id)) {
        attributes.add(this.zdmLampFields[`zdm_data_${datasourceKey}`]);
      }
      if (this.zdmLayersIds.includes(id)) {
        const zdmDisplayName = this.zdmDatasources[datasourceKey]?.display_attribute_name;
        const zdmStreetName = this.zdmDatasources[datasourceKey]?.street_attribute_name;
        const zdmDistrictName = this.zdmDatasources[datasourceKey]?.district_attribute_name;
        if (zdmDisplayName) {
          attributes.add(zdmDisplayName);
        }
        if (zdmStreetName) {
          attributes.add(zdmStreetName);
        }
        if (zdmDistrictName) {
          attributes.add(zdmDistrictName);
        }
      }
      return Array.from(attributes);
    },
    addProjectLayer(layer, config) {
      if (!layer.has_permission) {
        return;
      }
      if (layer.type === 'service_layer' || layer.type === 'raster_layer') {
        this.addProjectServiceLayer(layer, config);
      } else {
        if (layer.supports_mvt) {
          this.addProjectMvtLayer(layer, config);
          setTimeout(() => {
            if (Object.keys(this.mvtLayersRefreshDataSources).includes(layer.data_source_name)) {
              const action = () => {
                const layersList = this.getLayerById('layers').getLayers().getArray();
                const layersListNames = layersList.map(layer => layer.get('id'));
                if (layersListNames.includes(layer.id)) {
                  this.mvtLayersRefreshDataSources[layer.data_source_name]['action'](layer);
                  setTimeout(action, this.mvtLayersRefreshDataSources[layer.data_source_name]['interval']);
                }
              };
              action();
            }
          });
        } else if (layer.geometry_type) {
          this.addProjectVectorLayer(layer, config);
        }
      }
    },
    addProjectLayers(layers) {
      layers = layers.map(layer => {
        if (this.customFeaturesLoaders[layer.role]) {
          layer.loader = this.customFeaturesLoaders[layer.role];
        }
        return layer;
      });
      if (!layers || layers.length === 0) {
        return;
      }
      for (let i = 0; i < layers.length; i++) {
        try {
          this.addProjectLayer(layers[i], {
            ...(layers[i].config || layers[i]),
          });
        } catch (e) {
          console.log(`%cCould not add layer to map. There is probably an error in its configuration.`, 'color: red');
          console.log(`%cLayer name: ${layers[i].name} | Layer ID: ${layers[i].id}`, 'color: red');
          throw e;
        }
      }
    },
    getMinzoomFromStyle(style) {
      const styleMinZooms = [style.minzoom || 1];
      if (style.uniques || style.ranges) {
        const styles = Object.values((style.uniques || style.ranges).values);
        for (const singleStyle of styles) {
          styleMinZooms.push(singleStyle.minzoom || 1);
        }
      }
      return Math.min(...styleMinZooms) - 1;
    },
    getBasemapLayer(checkVisibilityInProject = false) {
      const {
        url,
        parameters: { minZoom, maxZoom },
      } = this.basemapLayer;
      return new TileLayer({
        source: new XYZ({
          crossOrigin: 'anonymous',
          cacheSize: 16,
          url,
          ...(minZoom || minZoom === 0 ? { minZoom } : {}),
          ...(maxZoom || maxZoom === 0 ? { maxZoom } : {}),
        }),
        name: 'basemapLayer',
        visible: checkVisibilityInProject ? this.project?.basemap_visible || false : true,
      });
    },
    getMaxzoomFromStyle(style) {
      const styleMaxZooms = [style.maxzoom || 28];
      if (style.uniques || style.ranges) {
        const styles = Object.values((style.uniques || style.ranges).values);
        for (const singleStyle of styles) {
          styleMaxZooms.push(singleStyle.maxzoom || 28);
        }
      }
      return Math.max(...styleMaxZooms);
    },
    addProjectMvtLayer(
      layer,
      { visible, style, labels_visible, opacity, loader = defaultMvtSourceLoader, isSpecial /*, filters*/ } = {}
    ) {
      const { data_source_name, id, type, group_id, name, geometry_type, direction_arrows_visible } = layer;
      if (visible) {
        this.mvtVisibleLayersCounter++;
      }
      const filters = this.layersFilters?.[id]?.filterExpression || undefined;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const layerProps = {
        className: `ol-layer ol-layer-mvt-${id}`,
        opacity,
        data_source_name,
        id,
        type,
        group_id,
        name,
        visible,
        styleAttributes,
        minZoom: this.getMinzoomFromStyle(style),
        maxZoom: this.getMaxzoomFromStyle(style),
        isSpecial: isSpecial || false,
        style: f => this.getFeatureStyle(f, style, geometry_type, labels_visible, id, direction_arrows_visible),
        renderMode: 'hybrid',
      };
      const isOffscreenCanvasLayer = ['point', 'multipoint'].includes(geometry_type);
      let mvtLayer;
      if (isOffscreenCanvasLayer) {
        offscreenLayers[id] = {
          container: undefined,
          transformContainer: undefined,
          canvas: undefined,
          context: undefined,
          rendering: undefined,
          workerFrameState: undefined,
          mainThreadFrameState: undefined,
        };
        const offscreenLayerProps = { ...layerProps };
        delete offscreenLayerProps.renderMode;
        delete offscreenLayerProps.style;
        prefetchOffscreenLayerData(style).then(iconBitmaps => {
          offscreenLayers[id].rendering = false;
          offscreenWorker.postMessage({
            action: 'initiateLayer',
            layerData: {
              idPropertyName: this.idPropertyName,
              filters,
              iconBitmaps,
              labels_visible,
              style,
              initialLayerMetadata: layer,
              olLayerProperties: {
                ...offscreenLayerProps,
              },
            },
          });
        });
        mvtLayer = new Layer({
          ...offscreenLayerProps,
          className: `${offscreenLayerProps.className} ol-offscreen-layer`,
          isOffscreen: true,
          render: frameState =>
            offscreenCanvasLayerRenderer(frameState, offscreenLayers[id], id, this.zoom, data =>
              offscreenWorker.postMessage(data)
            ),
          source: new Source({}),
        });
      } else {
        mvtLayer = new VectorTileLayer({
          ...layerProps,
          source: defaultMvtLayerSource(layer, {
            loader,
            filters,
            styleAttributes,
          }),
        });
      }
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('layers').getLayers().push(mvtLayer);
    },
    updateMvtSourceLoader(id, source, callback, { filters, styleAttributes } = {}) {
      const layer = this.getLayerById(id, 'layers');
      if (layer && layer.get('isOffscreen')) {
        offscreenWorker.postMessage({
          action: 'updateSourceLoader',
          layerId: id,
          styleAttributes,
          filters,
        });
        return;
      }
      source.setTileLoadFunction(function (tile) {
        callback(tile, id, { filters, styleAttributes });
      });
      this.refreshMvtSource(id);
    },
    addProjectVectorLayer(
      layer,
      {
        visible,
        style,
        labels_visible,
        opacity,
        loader = this.defaultVectorSourceLoader,
        features,
        isSpecial,
        filters,
      } = {}
    ) {
      const { data_source_name, id, type, group_id, name, geometry_type } = layer;
      const vectorLayer = new VectorLayer({
        className: `ol-layer ol-layer-vector-${id}`,
        id,
        name,
        type,
        opacity,
        data_source_name,
        group_id,
        visible,
        isSpecial: isSpecial || false,
        source: new VectorSource({
          format: new GeoJSON(),
          features:
            features && features.features
              ? new GeoJSON().readFeatures(features, {
                  dataProjection: features.crs.properties.name,
                  featureProjection: this.$_config.defaultEpsg,
                })
              : undefined,
          loader:
            features && features.features
              ? undefined
              : function () {
                  loader(this, id, filters);
                },
        }),
        style: f => this.getFeatureStyle(f, style, geometry_type, labels_visible, id),
      });
      this.getLayerById('layers').getLayers().push(vectorLayer);
    },
    async defaultVectorSourceLoader(vectorSource, layerId, { filters } = {}) {
      try {
        const r = await this.getLayerFeatures({ layer_id: layerId, features_filter: filters });
        vectorSource.addFeatures(
          vectorSource.getFormat().readFeatures(r.data.data.features, {
            dataProjection: r.crs.properties.name,
            featureProjection: this.$_config.defaultEpsg,
          })
        );
      } catch (e) {
        console.log(e);
      }
    },
    updateVectorSourceLoader(id, source, callback, { filters } = {}) {
      source.setLoader(function () {
        callback(this, id, { filters });
      });
    },
    getServiceLayer(layer, visible, opacity) {
      const { name, id, url, style } = layer;
      const styleMinZoom = style?.minzoom;
      const styleMaxZoom = style?.maxzoom;
      if (layer.service_type === 'wmts') {
        const { format, version, CRS } = layer.parameters;
        const credentials = getServiceLayerEmbedCredentials(layer.url);
        return new TileLayer({
          className: `ol-layer ol-layer-wmts-${id}`,
          name,
          id,
          visible,
          opacity,
          ...(styleMaxZoom && { maxZoom: styleMaxZoom }),
          ...(styleMinZoom && { minZoom: styleMinZoom }),
          source: new WMTS({
            url,
            format,
            version,
            crossOrigin: '',
            projection: CRS,
            matrixSet: CRS,
            tileGrid: new WMTSTileGrid(layer.parameters.options),
            wrapX: true,
            style: '',
            layer: Array.isArray(layer.service_layers_names)
              ? layer.service_layers_names.join()
              : layer.service_layers_names,
            ...(credentials
              ? {
                  tileLoadFunction: (tile, src) => {
                    // Chrome stopped supporting URLs with embedded credentials, like https://user:pass@host
                    // So regex it and add to the request header
                    serviceLayerWithCredentialsLoader(tile, src, credentials);
                  },
                }
              : {}),
          }),
        });
      } else if (layer.service_type === 'wms') {
        return new TileLayer({
          className: `ol-layer ol-layer-wms-${id}`,
          name,
          id,
          visible,
          opacity,
          ...(styleMaxZoom && { maxZoom: styleMaxZoom }),
          ...(styleMinZoom && { minZoom: styleMinZoom }),
          source: new TileWMS({
            url,
            params: {
              LAYERS: Array.isArray(layer.service_layers_names)
                ? layer.service_layers_names.join()
                : layer.service_layers_names,
              TILED: true,
              SRS: layer.parameters?.CRS || this.$_config.defaultEpsg,
              CRS: layer.parameters?.CRS || this.$_config.defaultEpsg,
              VERSION: layer.parameters.version,
            },
            projection: layer.parameters?.CRS || this.$_config.defaultEpsg,
          }),
        });
      } else if (layer.service_type === 'xyz') {
        const { minZoom: parametrMinZoom, maxZoom: parametrMaxZoom } = layer.parameters;
        return new TileLayer({
          className: `ol-layer ol-layer-xyz-${id}`,
          name,
          id,
          visible,
          opacity,
          ...(styleMaxZoom && { maxZoom: styleMaxZoom }),
          ...(styleMinZoom && { minZoom: styleMinZoom }),
          source: new XYZ({
            url,
            ...(parametrMaxZoom && { maxZoom: parametrMaxZoom }),
            ...(parametrMinZoom && { minZoom: parametrMinZoom }),
            params: {
              LAYERS: Array.isArray(layer.service_layers_names)
                ? layer.service_layers_names.join()
                : layer.service_layers_names,
              TILED: true,
              SRS: `EPSG:${layer.srid}`,
            },
          }),
        });
      } else if (layer.service_type === 'mvt') {
        const { data_source_name, id, type, group_id, name, parameters } = layer;
        const mvtLayer = new VectorTileLayer({
          className: `ol-layer ol-layer-mvt-${id}`,
          opacity,
          data_source_name,
          id,
          type,
          group_id,
          name,
          visible,
          minZoom: this.getMinzoomFromStyle(style),
          maxZoom: this.getMaxzoomFromStyle(style),
          source: databoxMvtLayerSource(layer),
          style: f => this.getFeatureStyle(f, style, parameters.geometry_type, true, id),
          renderMode: 'hybrid',
        });
        return mvtLayer;
      }
    },
    addProjectServiceLayer(layer, { visible, opacity } = {}) {
      if (!layer) {
        return;
      }
      const serviceLayer = this.getServiceLayer(layer, visible, opacity);
      this.getLayerById('layers').getLayers().push(serviceLayer);
    },
    updateVectorLayerFeatures({ layerId, group = 'layers', features }) {
      const source = this.getLayerById(layerId, group).getSource();
      source.clear();
      source.addFeatures(
        new GeoJSON().readFeatures(features, {
          dataProjection: features.crs.properties.name,
          featureProjection: this.$_config.defaultEpsg,
        })
      );
    },
    deleteProjectLayer(layerId) {
      this.removeProjectLayers([layerId]);
      this.layersOnMap = [...this.projectLayers];
    },
    discardProjectLayers() {
      this.removeProjectLayers(this.layersOnMap.map(layer => layer.id));
      this.addProjectLayers(this.projectLayers);
      this.layersOnMap = [...this.projectLayers];
    },
    async filterProjectLayer(layerId, filters) {
      const layer = this.getLayerById(layerId, 'layers');
      if (!layer) {
        return;
      }
      const projectLayer = this.projectLayers.find(layer => layer.id == layerId);
      const isCacheLayer = projectLayer?.cache || false;
      if (isCacheLayer) {
        const payload = {
          layerId,
          filters,
        };
        await this.getCacheLayerIds(payload);
        this.setProjectLayerStyle(layerId);
        return;
      }
      const currentStyle = projectLayer.style;
      const styleAttributes = this.getRequiredAttributes(layerId, currentStyle);
      const source = layer.getSource();
      source instanceof VectorSource
        ? this.updateVectorSourceLoader(layerId, source, this.defaultVectorSourceLoader, { filters })
        : this.updateMvtSourceLoader(layerId, source, defaultMvtSourceLoader, { filters, styleAttributes });
    },
    projectLayersLoaded() {
      if (this.layersOnMap.length === 0) {
        this.addProjectLayers(this.projectLayers);
      } else {
        this.removeAllProjectLayers();
        this.addProjectLayers(this.projectLayers);
      }
      this.setProjectLayersOrder();
      this.toggleProjectLayersVisible(true);
      this.isProjectLayersFetched = true;
      this.layersOnMap = [...this.projectLayers];
    },
    pushProjectLayers(layers) {
      this.addProjectLayers(layers);
      this.setProjectLayersOrder();
      this.map.updateSize();
      this.layersOnMap = [...this.projectLayers];
    },
    removeProjectLayers(layersIds, group = 'layers') {
      const mapLayers = this.getLayerById(group).getLayers().getArray();
      const mapLayersIds = mapLayers.map(l => l.get('id'));
      offscreenWorker.postMessage({
        action: 'deleteLayers',
        layersIds,
      });
      for (let i = layersIds.length - 1; i >= 0; i--) {
        delete offscreenLayers[layersIds[i]];
        const index = mapLayersIds.indexOf(layersIds[i]);
        if (index < 0) {
          return;
        }
        mapLayers.splice(index, 1);
      }
      this.map.updateSize();
    },
    removeAllProjectLayers() {
      const layers = [...this.getLayerById('layers').getLayers().getArray()];
      const layersToDelete = layers.filter(l => !l.get('isSpecial')).map(l => l.get('id'));
      this.removeProjectLayers(layersToDelete);
    },
    setProjectLayersOrder() {
      [...this.projectLayers]
        .filter(layer => layer.has_permission && (layer.geometry_type || layer.service_type))
        .reverse()
        .forEach((layer, index) => {
          this.getLayerById(layer.id, 'layers')?.setZIndex(index);
        });
      this.map.updateSize();
    },
    setProjectLayerOpacity(layerId, value) {
      const layer = this.getLayerById(layerId, 'layers');
      layer.setOpacity(value);
      this.map.updateSize();
    },
    setProjectServiceLayerStyle(layerId, { style } = {}) {
      const layer = this.projectLayers.find(l => l.layer_id == layerId);
      if (!layer) {
        return;
      }
      const layerOl = this.getLayerById(layerId, 'layers');
      const currentStyle = style || layer.style;
      layerOl.setMinZoom(this.getMinzoomFromStyle(currentStyle));
      layerOl.setMaxZoom(this.getMaxzoomFromStyle(currentStyle));
    },
    async setProjectLayerStyle(
      layerId,
      {
        forceLayerData,
        style,
        labels_visible,
        direction_arrows_visible,
        selectedColors = {},
        skipCustomHandler = false,
      } = {}
    ) {
      const layer = forceLayerData || this.projectLayers.find(l => l.layer_id == layerId);
      if (!layer) {
        return;
      }
      const isCacheLayer = layer?.cache || false;
      const layerOl = this.getLayerById(layerId, 'layers');
      const currentStyle = style || layer.style;
      const currentLabelsVisible = labels_visible || layer.labels_visible;
      const currentDirectionArrowsVisible = direction_arrows_visible || layer.direction_arrows_visible;
      const minZoom = this.getMinzoomFromStyle(currentStyle);
      const maxZoom = this.getMaxzoomFromStyle(currentStyle);
      if (layerOl.get('isOffscreen')) {
        let iconBitmaps = await prefetchOffscreenLayerData(currentStyle);
        const styleAttributes = this.getRequiredAttributes(layerId, currentStyle);
        let newStyleAttributes = null;
        if (!isEqual(layerOl.get('styleAttributes'), styleAttributes)) {
          newStyleAttributes = [...styleAttributes];
          layerOl.set('styleAttributes', newStyleAttributes);
        }
        offscreenLayers[layerId].mainThreadFrameState = undefined;
        offscreenLayers[layerId].workerFrameState = undefined;
        layerOl.setMinZoom(minZoom);
        layerOl.setMaxZoom(maxZoom);
        offscreenWorker.postMessage({
          action: 'setLayerStyle',
          layerId,
          zoom: this.zoom,
          minZoom,
          maxZoom,
          iconBitmaps,
          styleAttributes: newStyleAttributes,
          styleData: {
            currentStyle,
            geometry_type: layer.geometry_type,
            currentLabelsVisible,
            layerId,
          },
        });
        layerOl.getSource().changed();
        layerOl.changed();
      } else {
        layerOl.setMinZoom(minZoom);
        layerOl.setMaxZoom(maxZoom);
        layerOl.setStyle(feature => {
          return this.getFeatureStyle(
            feature,
            currentStyle,
            layer.geometry_type,
            currentLabelsVisible,
            layerId,
            currentDirectionArrowsVisible,
            {},
            selectedColors,
            isCacheLayer
          );
        });
        layerOl.changed();
        if (!skipCustomHandler && this.customStyleHandlers[layerId]) {
          this.customStyleHandlers[layerId]();
        }
        // ficzer z ucinaniem atrybutów dotyczy tylko warstw kaflowych, więc przy zwykłych vectorowych warstwach skipujemy
        if (layerOl.getSource() instanceof VectorSource) {
          return;
        }
        // wymagane atrybuty dla nowego stylu
        const styleAttributes = this.getRequiredAttributes(layerId, currentStyle);
        // style mogły się zmienić, ale nie zmieniły się atrybuty wymagane do stylizacji, więc nie musimy nic więcej robić
        if (isEqual(layerOl.get('styleAttributes'), styleAttributes)) {
          return;
        }
        // zmieniły się wymagane atrybuty, więc musimy poprawić loader
        layerOl.set('styleAttributes', styleAttributes);
        const source = layerOl.getSource();
        const filters = this.layersFilters?.[layerId]?.filterExpression || undefined;
        this.updateMvtSourceLoader(layerId, source, defaultMvtSourceLoader, {
          styleAttributes,
          ...(filters ? { filters } : {}),
        });
      }
    },
    toggleProjectAllLayersLabels(visible) {
      const { projectLayers } = this;
      for (let i = 0; i < projectLayers.length; i++) {
        this.toggleProjectLayerLabels(projectLayers[i].layer_id, visible);
      }
    },
    toggleProjectLayerLabels(layerId, value) {
      this.setProjectLayerStyle(layerId, { labels_visible: value });
    },
    toggleProjectLayerDirectionArrows(layerId, value) {
      const layerData = {
        ...this.projectLayers.find(layer => layer.id === layerId),
        direction_arrows_visible: value,
      };
      const layerConfig = {
        ...(layerData.config || layerData),
        ...(this.customFeaturesLoaders[layerData.role] && { loader: this.customFeaturesLoaders[layerData.role] }),
        styleAttributes: this.getRequiredAttributes(layerData.id, layerData.style),
      };
      const layer = this.getLayerById(layerId, 'layers');
      const newSource = defaultMvtLayerSource(layerData, layerConfig);
      layer.setSource(newSource);
      this.setProjectLayerStyle(layerId, { direction_arrows_visible: value });
    },
    toggleProjectLayerSelection(type, { selectionBoxAction, layerId } = {}) {
      if (type && this.activeTool !== 'selection') {
        this.$root.$emit('deactivateAllTools');
      } else if (!type && this.activeTool === 'selection') {
        this.deactivateToolHandler('selection');
      }
      if (type) {
        this.$nextTick(() => {
          if (type === 'single') {
            this.$root.$emit('attachOnClickSelection', this.layerId);
            this.$root.$emit('deleteSidebarGeometry');
            this.map.removeInteraction(this.getInteractionByName('selectionBox'));
          } else if (type === 'multiple') {
            unByKey(this.clickSelectionKey);
            this.$root.$emit('deleteSidebarGeometry');
            this.createSelection(selectionBoxAction, { layerId });
          } else if (type === 'freehand') {
            unByKey(this.clickSelectionKey);
            this.$root.$emit('drawSidebarGeometry', {
              geometryType: 'polygon',
              drawendCallback: e => this.onDefaultDrawend(selectionBoxAction, layerId, e),
              freehand: true,
              isDeactivateDrawingInteraction: false,
              isInitSnapping: false,
              isSelection: true,
            });
            this.map.removeInteraction(this.getInteractionByName('selectionBox'));
          } else if (type === 'polygon') {
            unByKey(this.clickSelectionKey);
            this.$root.$emit('drawSidebarGeometry', {
              geometryType: 'polygon',
              drawendCallback: e => this.onDefaultDrawend(selectionBoxAction, layerId, e),
              freehand: false,
              isDeactivateDrawingInteraction: false,
              isInitSnapping: false,
              isSelection: true,
            });
            this.map.removeInteraction(this.getInteractionByName('selectionBox'));
          } else if (type === 'radius') {
            unByKey(this.clickSelectionKey);
            this.$root.$emit('drawSidebarGeometry', {
              geometryType: 'Circle',
              geometryFunction: (coords, geom, proj) => this.createPolygonFromCircle(coords, geom, proj),
              drawendCallback: e => this.onDefaultDrawend(selectionBoxAction, layerId, e),
              isDeactivateDrawingInteraction: false,
              isInitSnapping: false,
              isSelection: true,
              drawType: 'Circle',
            });
            this.map.removeInteraction(this.getInteractionByName('selectionBox'));
          }
          if (this.tableSelectionType) this.isSelectionActive = true;
          this.activeTool = 'selection';
        });
      } else {
        this.dettachCursorMoveHandler();
        unByKey(this.clickSelectionKey);
        if (this.tableSelectionType) this.isSelectionActive = false;
        this.$root.$emit('deleteSidebarGeometry');
        this.map.removeInteraction(this.getInteractionByName('selectionBox'));
        this.deactivateAllToolsHandler();
        this.isActiveToolNotReplaceable = false;
      }
    },
    toggleProjectLayerVisible(layerId, value) {
      const layer = this.getLayerById(layerId, 'layers');
      layer.setVisible(value);
      this.map.updateSize();
    },
    toggleProjectLayersVisible(value) {
      this.getLayerById('layers').setVisible(value);
    },
    async refreshMvtSource(id, group = 'layers', skipRelatedDatasources = false) {
      if (!skipRelatedDatasources) {
        const relatedDatasources = this.metadata[this.layers[id]?.data_source_name]?.related_datasources || [];
        this.projectLayers
          .filter(layer => relatedDatasources.includes(layer.data_source_name))
          .forEach(layer => {
            this.refreshMvtSource(layer.id, 'layers', true);
          });
      }
      const layer = this.getLayerById(id, group);
      if (!layer) {
        console.log(`Nie znaleziono warstwy o ID: ${id} w grupie ${group}`);
        return;
      }
      await this.clearMvtLayerCache({ layer_id: id });
      if (layer.get('isOffscreen')) {
        offscreenLayers[id].mainThreadFrameState = undefined;
        offscreenLayers[id].workerFrameState = undefined;
        offscreenWorker.postMessage({
          action: 'refreshSource',
          layerId: id,
        });
      }
      const source = layer.getSource();
      source.tileCache?.expireCache({});
      source.tileCache?.clear();
      source.refresh();
      layer.changed();
    },
    /*
      Layers list received from topological layers features adding/editing routings may containt layers that are not present at map.
      Setting isFilterLayers flag to true will filter list to contain only present layers before refreshing.
    */
    refreshMvtSources(layers, isFilterLayers) {
      const layersArray = isFilterLayers ? this.filterPresentLayers(layers) : layers;
      layersArray.forEach(layer => this.refreshMvtSource(layer.id, layer.group));
    },
    filterPresentLayers(layers) {
      return layers.filter(layer => this.getLayerById(layer.id, layer.group));
    },
    isLayerVisibleWithCallback(layerId, callback) {
      const layer = this.getLayerById(layerId, 'layers');
      if (!layer) {
        this.$store.set('snackbar/PUSH_MESSAGE!', {
          message: this.$i18n.t('dialog.layerIsNotVisible'),
        });
        return;
      }
      callback();
    },
    getZdmEditorLayerStyleHandler(layerId, type) {
      const attribute = this.zdmDatasources[type]?.display_attribute_name;
      return () => {
        this.setProjectLayerStyle(layerId, {
          selectedColors: { labelAttributesName: [attribute] },
          skipCustomHandler: true,
        });
      };
    },
    getCustomStyleHandlers() {
      const customStyleHandlers = {};
      ['cabinet', 'cable', 'power_station', 'street_lamp'].forEach(type => {
        const layerId = this.zdmLayers[type];
        customStyleHandlers[layerId] = this.getZdmEditorLayerStyleHandler(layerId, type);
      });
      this.customStyleHandlers = customStyleHandlers;
    },
    defaultWorkerMessageHandler(message) {
      const messageData = message.data;
      if (messageData.action === 'requestRender') {
        this.map.render();
        return;
      }
      const layerId = messageData.layerId;
      if (!layerId) return;
      if (offscreenLayers[layerId].canvas && messageData.action === 'rendered') {
        // offscreenWorker provides a new render frame
        requestAnimationFrame(function () {
          const imageData = messageData.imageData;
          offscreenLayers[layerId].canvas.width = imageData.width;
          offscreenLayers[layerId].canvas.height = imageData.height;
          offscreenLayers[layerId].canvas.style.transform = messageData.transform;
          offscreenLayers[layerId].workerFrameState = messageData.frameState;
          updateContainerTransform(
            offscreenLayers[layerId].transformContainer,
            offscreenLayers[layerId].workerFrameState,
            offscreenLayers[layerId].mainThreadFrameState
          );
          offscreenLayers[layerId].context.drawImage(imageData, 0, 0);
        });
        offscreenLayers[layerId].rendering = false;
      }
    },
    getWorkerFeaturesAtPixel(pixel, layerFilter = this.specialLayerFilter) {
      const layersInFilter = this.map
        .getLayers()
        .getArray()
        .reduce((acc, layer) => {
          if (layer instanceof LayerGroup) {
            return [...acc, ...layer.getLayers().getArray()];
          }
          return [...acc, layer];
        }, [])
        .filter(layerFilter)
        .map(layer => layer.get('id'));
      return new Promise(resolve => {
        offscreenWorker.postMessage({
          action: 'getFeaturesAtPixel',
          pixel,
          layerFilter: layersInFilter,
        });
        offscreenWorker.onmessage = msg => {
          if (msg.data.action === 'requestedFeaturesAtPixel') {
            resolve(msg.data.features);
          }
          this.defaultWorkerMessageHandler(msg);
        };
      });
    },
    attachMoveCursorHandler(cursor, layerFilter = this.specialLayerFilter) {
      this.pointerMoveKey = this.map.on('pointermove', async evt => {
        const workerFeatures = await this.getWorkerFeaturesAtPixel(evt.pixel, layerFilter);
        const hit =
          this.map.hasFeatureAtPixel(evt.pixel, {
            layerFilter,
          }) || workerFeatures.length;
        this.map.getViewport().style.cursor = hit ? cursor : '';
      });
    },
    assignDefaultWorkerListener() {
      offscreenWorker.onmessage = async message => {
        this.defaultWorkerMessageHandler(message);
      };
    },
  },
  created() {
    offscreenWorker.postMessage({
      action: 'initWorker',
      token: this.token,
      zoom: this.zoom,
    });
    this.assignDefaultWorkerListener();
  },
  mounted() {
    if (this.isScadaThreeEnabled) {
      this.$watch(
        vm => {
          return vm.projectLayers;
        },
        nV => {
          const isScadaThreeRelationLayerInProject = nV.find(layer =>
            this.scadaThreeRelationDatasources.includes(layer.data_source_name)
          )
            ? true
            : false;
          if (isScadaThreeRelationLayerInProject && !this.scadaThreeRelationDatasourcesInterval) {
            this.scadaThreeRelationDatasourcesIntervalAction(true);
            this.scadaThreeRelationDatasourcesInterval = setInterval(
              () => {
                this.scadaThreeRelationDatasourcesIntervalAction();
              },
              this.scadaThreeInterval * 60 * 1000
            );
          } else if (!isScadaThreeRelationLayerInProject && this.scadaThreeRelationDatasourcesInterval) {
            clearInterval(this.scadaThreeRelationDatasourcesInterval);
            this.scadaThreeRelationDatasourcesInterval = undefined;
          }
        },
        { deep: true, immediate: true }
      );
    }
    this.getCustomStyleHandlers();
    this.registerProjectLayersListeners();
  },
};
