<template>
  <span class="gs-popover--wrapper">
    <slot
      name="activator"
      :on="toggleable ? onClickListeners : onHoverListeners"
      :onHover_prevent="onReverseHoverListeners"
      :close="closePopover"
      :open="openPopover"
    ></slot>
    <Transition name="fade">
      <div
        v-if="isPopoverVisible"
        @mouseleave="$event => (persistOnHover && !toggleable ? closePopover($event) : undefined)"
        ref="gs-popover"
        class="gs-popover"
      >
        <div ref="gs-popover--arrow" class="gs-popover--arrow gs-popover--arrow__visible"></div>
        <div ref="gs-popover--arrow__ghost" class="gs-popover--arrow"></div>
        <div class="gs-popover--content tw:rounded-base pa-4">
          <slot></slot>
        </div>
      </div>
    </Transition>
  </span>
</template>

<script>
import { autoUpdate, computePosition, flip, shift, offset, arrow } from '@floating-ui/dom';

let cleanupAutoupdate = () => {};
let timeout = null;

export default {
  name: 'PopoverMenu',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    delay: {
      type: Number,
      default: null,
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
    persistOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPopoverVisible: false,
  }),
  watch: {
    isPopoverVisible: {
      handler(isVisible) {
        if (isVisible) {
          this.$nextTick(() => this.updatePopover());
        } else {
          cleanupAutoupdate();
        }
      },
      immediate: true,
    },
  },
  computed: {
    onClickListeners() {
      return { click: () => this.togglePopover() };
    },
    onHoverListeners() {
      return {
        mouseenter: this.openPopover,
        mouseleave: this.closePopover,
      };
    },
    /** Especially useful when used as 'prevent' for child hovering */
    onReverseHoverListeners() {
      return {
        mouseenter: this.closePopover,
        mouseleave: this.openPopover,
      };
    },
  },
  methods: {
    togglePopover() {
      this.isPopoverVisible = !this.isPopoverVisible;
    },
    openPopover() {
      if (!this.delay) return (this.isPopoverVisible = true);
      timeout = setTimeout(() => (this.isPopoverVisible = true), this.delay);
    },
    closePopover(e) {
      clearTimeout(timeout);
      timeout = null;
      if (!this.toggleable && this.persistOnHover) {
        const isTargetPopover = e?.relatedTarget?.classList.contains('gs-popover');
        const isTargetActivator = e?.relatedTarget?.parentElement?.classList.contains('gs-popover--wrapper');
        if (!isTargetPopover && !isTargetActivator) {
          this.isPopoverVisible = false;
        }
      } else this.isPopoverVisible = false;
    },
    updatePopover() {
      const popoverElement = this.$refs['gs-popover'];
      const popoverArrowElement = this.$refs['gs-popover--arrow'];
      const popoverArrowShadowElement = this.$refs['gs-popover--arrow__ghost'];
      const triggerElement = this.$children[0].$el;
      cleanupAutoupdate = autoUpdate(triggerElement, popoverElement, () =>
        computePosition(triggerElement, popoverElement, {
          middleware: [offset(10), shift({ padding: 10 }), flip(), arrow({ element: popoverArrowElement })],
          strategy: 'fixed',
          placement: 'right-start',
          ...this.config,
        }).then(({ x, y, placement, middlewareData }) => {
          /** Popover placement */
          Object.assign(popoverElement.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
          const placementSide = placement.split('-')[0];

          /** Arrow placement */
          const { x: arrowX, y: arrowY } = middlewareData.arrow;
          const staticSide = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
          }[placementSide];
          [popoverArrowElement, popoverArrowShadowElement].forEach(el =>
            Object.assign(el.style, {
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
              [staticSide]: '-4px',
            })
          );
        })
      );
    },
  },
  beforeDestroy() {
    cleanupAutoupdate();
  },
};
</script>

<style lang="scss" scoped>
$arrowSide: 11px;
$arrowDiagonal: calc((#{$arrowSide} * (2 * (1 / 2))) + 3px);

.gs-popover::before {
  content: '';
  top: calc(#{$arrowDiagonal} * -1);
  left: calc(#{$arrowDiagonal} * -1);
  height: calc(100% + calc(#{$arrowDiagonal} * 2));
  width: calc(100% + calc(#{$arrowDiagonal} * 2));
  background-color: transparent;
  position: absolute;
  z-index: -4;
}

.gs-popover--arrow {
  position: absolute;
  width: $arrowSide;
  height: $arrowSide;
  background: #fff;
  transform: rotate(45deg);
}
</style>

<style scoped>
@reference '@/tailwind.css';

.gs-popover {
  @apply tw:w-max tw:fixed tw:z-[4];
}

.gs-popover--content {
  @apply tw:bg-white tw:border tw:border-gray-200 tw:shadow-md;
}

.gs-popover--arrow__visible {
  @apply tw:shadow-md tw:outline tw:outline-gray-200 tw:z-[-4];
}

/* Transition */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
}
.fade-enter,
.fade-leave-to {
  @apply tw:opacity-0;
}

.fade-enter-to,
.fade-leave {
  @apply tw:opacity-100;
}
</style>
